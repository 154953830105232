/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import CropedImage from '../../components/CropedImage';
import Loading from '../../components/Loading';
import { User } from '../users/user.model';
import { getAuthUser, validateToken } from '../../services/app.service';
import { getById, save } from '../users/users.service';
import noImgUser from '../../assets/svg/user.svg';
import { SubmitButton } from '../../components/SubmitButton';

const AppProfile: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const [submiting, setSubmiting] = useState<boolean>(false);
  const [submited, setSubmited] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<[]>([]);

  const user = getAuthUser();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const init = async () => {
    validateToken();
    const userId = user?.id;
    // console.log('userId', userId);
    if (userId) {
      const data = await getById(userId);
      const entries = Object.entries(data);
      entries.forEach((entry) => setValue(entry[0], entry[1]));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const onSubmit = (data: User) => {
    setSubmiting(true);

    if (!data.password) {
      delete data.password;
    }

    save(data)
      .then((ret) => {
        if (ret.data && ret.data.error) {
          setError(true);
          setErrorMsg(ret.data.message);
          setSubmited(false);
          setSubmiting(false);
          return;
        }
        if (ret.affected === 0) {
          setError(true);
          return;
        }
        setError(false);
        setSubmiting(false);
        setSubmited(true);
      })
      .catch((e) => {
        console.log('error', e);
        setError(true);
        setSubmited(false);
        setSubmiting(false);
      });
  };

  if (loading) {
    return (
      <div className="h-screen flex flex-col justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className={`max-w-3xl mx-auto px-2 sm:px-6 lg:px-8 my-20 z-0`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li className="text-primary">Meu Perfil</li>
        </ul>
      </div>
      <div className={`flex justify-between`}>
        <div className={`w-1/2 p-3 relative`}>
          <div className="avatar mt-5 flex flex-col">
            <div className="mb-8 rounded-full w-40 h-40">
              <img
                src={`${process.env.REACT_APP_API_URL}/public/users/${user?.id}.png`}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = noImgUser;
                }}
              />
            </div>
            <CropedImage pathUrl={`/users/upload/${user?.id}`} />
          </div>
        </div>
        <div className={`w-1/2`}>
          <form onSubmit={handleSubmit(onSubmit)} className="mb-3 w-full">
            <input type="hidden" {...register('id')} />
            <div className="form-control">
              <label className="label">
                <span className="label-text">Nome</span>
              </label>
              <input
                type="text"
                placeholder="Nome Completo"
                className="input input-bordered"
                {...register('name', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.name && 'Campo requerido'}
              </span>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Usuário</span>
              </label>
              <input
                type="text"
                placeholder="Nome para o login"
                className="input input-bordered"
                {...register('user', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.user && 'Campo requerido'}
              </span>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Email</span>
              </label>
              <input
                type="text"
                placeholder="Email principal"
                className="input input-bordered"
                {...register('email', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.email && 'Campo requerido'}
              </span>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">CEL</span>
              </label>
              <input
                type="text"
                placeholder="Celular apenas números"
                className="input input-bordered"
                {...register('cel', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.cel && 'Campo requerido'}
              </span>
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">ATUALIZAR SENHA</span>
              </label>
              <input
                type="password"
                placeholder="Digite a nova senha"
                className="input input-bordered"
                {...register('password')}
              />
              <span className="text-xs text-red-500">
                {errors.password && 'Campo requerido'}
              </span>
            </div>

            <div className="flex justify-between mt-3">
              <SubmitButton caption="Salvar" submiting={submiting} />
            </div>
          </form>
          {submited && (
            <div className="alert alert-info">
              <div className="flex-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="w-6 h-6 mx-2 stroke-current"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <label>Registro salvo com sucesso!</label>
              </div>
            </div>
          )}
          {error && (
            <div className="alert alert-error flex flex-col items-start">
              <div className="flex-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="w-6 h-6 mx-2 stroke-current"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                  ></path>
                </svg>
                <label>Error ao salvar registro</label>
              </div>
              <ul className="p-3">
                {errorMsg &&
                  errorMsg.map((err, idx) => <li key={idx}>{err}</li>)}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppProfile;
