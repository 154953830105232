/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
type Types = {
  key: string | number;
  value: string;
};
export const typesPgtos: Types[] = [
  { key: 'convenant', value: 'Convênio' },
  { key: 'cash', value: 'Dinheiro' },
  { key: 'creditcard', value: 'Cartão Crédito' },
  { key: 'debitcard', value: 'Cartão Débito' },
  { key: 'pix', value: 'PIX' },
  { key: 'transfer', value: 'Transferência' },
  { key: 'pix-credit', value: 'Pix/Cartão Crédito' },
  { key: 'pix-debit', value: 'Pix/Cartão Débito' },
  { key: 'pix-cash', value: 'Pix/Dinheiro' },
  { key: 'cash-credit', value: 'Dinheiro/Cartão Crédito' },
  { key: 'cash-debit', value: 'Dinheiro/Cartão Débito' },
  { key: 'debit-credit', value: 'Cartão Débito/Cartão Crédito' },
];

export const getTypePgto = (typePgto: string): string => {
  switch (typePgto) {
    case 'convenant':
      return 'Convênio';
    case 'cash':
      return 'Dinheiro';
    case 'creditcard':
      return 'Cartão Crédito';
    case 'debitcard':
      return 'Cartão Débito';
    case 'pix':
      return 'PIX';
    case 'transfer':
      return 'Transferência';
    case 'pix-credit':
      return 'Pix/Cartão Crédito';
    case 'pix-debit':
      return 'Pix/Cartão Débito';
    case 'pix-cash':
      return 'Pix/Dinheiro';
    case 'cash-credit':
      return 'Dinheiro/Cartão Crédito';
    case 'cash-debit':
      return 'Dinheiro/Cartão Débito';
    case 'debit-credit':
      return 'Cartão Débito/Cartão Crédito';
    default:
      return 'N/D';
  }
};

export const typeStatus: string[] = [
  'ABERTO',
  'AGENDADO',
  'ATENDIDO',
  'CANCELADO',
];

export const themes: Record<string, string>[] = [
  {
    key: 'Azul',
    value: 'blue',
  },
  {
    key: 'Rosa',
    value: 'pink',
  },
  {
    key: 'Amarelo',
    value: 'yellow',
  },
  {
    key: 'Verde',
    value: 'green',
  },
  {
    key: 'Roxo',
    value: 'purple',
  },
  {
    key: 'Vermelho',
    value: 'red',
  },
];

export const parseCurrency = (num: number | string | undefined): string => {
  const rawNumber: number[] = [];
  if (typeof num === 'string') {
    rawNumber[0] = parseFloat(num);
  } else if (typeof num === 'undefined') {
    rawNumber[0] = 0;
  } else {
    rawNumber[0] = num;
  }

  return rawNumber[0].toFixed(2).replace('.', ',');
};

// export const arrayGroupBy = (array: any[], key: any) => {
//   return array.reduce((acc, cur) => {
//     (acc[cur[key]] = acc[cur[key]] || []).push(cur);
//     return acc;
//   }, {});
// };

export const arrayGroupBy = (array: any[], key: string): any[] => {
  const keys = key.split('.');
  const count = keys.length;
  try {
    switch (count) {
      case 1: {
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]]] = acc[cur[keys[0]]] || []).push(cur);
          return acc;
        }, []);
      }
      case 2:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]]] = acc[cur[keys[0]][keys[1]]] || []).push(
            cur
          );
          return acc;
        }, []);
      case 3:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]][keys[2]]] =
            acc[cur[keys[0]][keys[1]][keys[2]]] || []).push(cur);
          return acc;
        }, []);
      case 4:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]][keys[2]][keys[3]]] =
            acc[cur[keys[0]][keys[1]][keys[2]][keys[3]]] || []).push(cur);
          return acc;
        }, []);
      case 5:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]]] =
            acc[cur[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]]] || []).push(
            cur
          );
          return acc;
        }, []);
      default:
        return [];
    }
  } catch {
    return [];
  }
};

export const round = (number: number, decimalPlaces: number) => {
  const factorOfTen = Math.pow(10, decimalPlaces);
  return Math.round(number * factorOfTen) / factorOfTen;
};

// string right
export const rightString = (str: string, length: number): string => {
  return str.substring(str.length - length);
};

// convert to celular number format (xx) xxxx-xxxx
export const phoneMask = (phone: string) => {
  const newPhone = rightString(phone, 10);
  // insert 9 digit into celular number
  const celularNumber = newPhone.substring(0, 2) + '9' + newPhone.substring(2);
  return celularNumber.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
};

export const rawPhone = (_phone: string): string => {
  const phone = _phone.replace(/\D/g, '');
  return phone.slice(0, 2) + phone.slice(3);
};

export const typesSatisfaction: Types[] = [
  { key: 0, value: 'Cancelado' },
  { key: 1, value: 'Nada Satisfeito' },
  { key: 2, value: 'Satisfeito' },
  { key: 3, value: 'Muito Satisfeito' },
];

// nivel de satisfação
export const nivelSatisfaction = (nivel: number): string => {
  switch (nivel) {
    case 0:
      return 'Cancelado';
    case 1:
      return 'Nada Satisfeito';
    case 2:
      return 'Satisfeito';
    case 3:
      return 'Muito Satisfeito';
    default:
      return 'Não avaliado';
  }
};
