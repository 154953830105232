import React from 'react';
import { useState } from 'react';

type AppCadPatientsSearchProps = {
  click: (name: string) => void;
};
const AppCadPatientsSearch: React.FC<AppCadPatientsSearchProps> = (
  props: AppCadPatientsSearchProps
) => {
  const [name, setName] = useState('');
  return (
    <div className={`flex my-3 p-1`}>
      <div className="form-control">
        <div className="relative">
          <input
            type="text"
            placeholder="Busca por nome"
            className="w-full pr-16 input input-gray-500 bg-gray-100 input-sm"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <button
            onClick={() => name && name.length > 2 && props.click(name)}
            className="absolute top-0 right-0 rounded-l-none btn btn-sm btn-gray-500"
          >
            Buscar
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppCadPatientsSearch;
