import React from 'react';
import history from '../../../history';
import Loading from '../../../components/Loading';
import { SendButton } from '../../../components/SendButton';
import { useStepMakeRecord } from '.';
import ShowRecord from '../../../components/ShowRecord';

export const Finist: React.FC = () => {
  const { loading, patient, professional, record, makeRecord } =
    useStepMakeRecord();
  return (
    <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/record')}>Prontuários</a>
          </li>
          <li>Gerar Prontuário</li>
        </ul>
      </div>
      <ul className="w-full steps my-6">
        <li className="step step-primary">Dados</li>
        <li className="step step-primary">Finalizar</li>
      </ul>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col">
          <div className="flex justify-between bg-gray-100 p-1">
            <strong>Profissional:</strong>
            <span>{professional?.name}</span>
          </div>

          <div className="flex justify-between bg-gray-100 p-1">
            <strong>Paciente:</strong>
            <span>{patient?.name}</span>
          </div>
        </div>
      )}

      {record && patient && professional ? (
        <div className="flex justify-between my-4">
          <button
            type="button"
            className="bt btn-sm btn-active text-white rounded"
            onClick={() => history.push('/step-make-record')}
          >
            Voltar
          </button>
          <div className="text-3xl py-4">
            <ShowRecord
              patientId={patient.id}
              professionalId={professional.id}
            />
          </div>
        </div>
      ) : (
        <div className="flex justify-between my-4">
          <button
            type="button"
            className="bt btn-sm btn-active text-white rounded"
            onClick={() => history.push('/step-make-record')}
          >
            Voltar
          </button>
          <SendButton
            click={makeRecord}
            caption="Gerar"
            submiting={false}
            size="btn-sm"
          />
        </div>
      )}
    </div>
  );
};
