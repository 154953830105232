/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useConfirmationAppointment } from '../../../hooks/appointmentsConfirmation.hook';
import history from '../../../history';
import { findById as findAppointment } from '../appointments.service';
import { useLocation } from 'react-router';
import { addTime, getFormatedDate } from '../../../utils/datetimeHandler';
import Loading from '../../../components/Loading';
import { useForm } from 'react-hook-form';

type InitialAppointmentConfirmationProps = {
  setValues: (values: any) => void;
};

export const InitialAppointmentConfirmation: React.FC<InitialAppointmentConfirmationProps> =
  ({ setValues }: InitialAppointmentConfirmationProps) => {
    const hookData = useConfirmationAppointment();
    const [loading, setLoading] = useState(false);
    const location = useLocation<any>();

    const init = async () => {
      setLoading(true);
      if (!location.state.id) return;
      const data = await findAppointment(location.state.id);
      if (data) {
        setValues({
          ...hookData,
          appointment: data,
        });
      }
      setLoading(false);
    };

    useEffect(() => {
      init();
    }, []);

    const {
      handleSubmit,
      register,
      formState: { errors },
    } = useForm();

    const onSubmit = (data: any) => {
      setValues({
        ...hookData,
        convenant: JSON.parse(data.convenant),
      });
      history.push('/appointments-confirmation/analize');
    };

    // console.log('procedureToConvenant', procedureToConvenant);

    return (
      <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <a onClick={() => history.push('/appointments')}>Atendimentos</a>
            </li>
            <li>Confirmar Atendimento</li>
          </ul>
        </div>
        <ul className="w-full steps my-6">
          <li className="step step-primary">Dados</li>
          <li className="step step">Análise</li>
          <li className="step step">Finalizar</li>
        </ul>
        {loading ? (
          <Loading />
        ) : (
          <div className="flex flex-col">
            <div className="flex justify-between bg-gray-200 p-1">
              <strong>Dia:</strong>
              <span>{getFormatedDate(String(hookData.appointment?.date))}</span>
            </div>
            <div className="flex justify-between bg-gray-100 p-1">
              <strong>Ordem:</strong>
              <span>{hookData.appointment?.order}</span>
            </div>
            <div className="flex justify-between bg-gray-200 p-1">
              <strong>Previsão:</strong>
              <span>
                {hookData.appointment &&
                  addTime(
                    hookData.appointment.appointmentBegin,
                    hookData.appointment.procedure.estimatedTime.toString(),
                    hookData.appointment.order - 1
                  )}
              </span>
            </div>
            <div className="flex justify-between bg-gray-100 p-1">
              <strong>Profissional:</strong>
              <span>{hookData.appointment?.professional.name}</span>
            </div>
            <div className="flex justify-between bg-gray-200 p-1">
              <strong>Procedimento:</strong>
              <span>{hookData.appointment?.procedure.name}</span>
            </div>
            <div className="flex justify-between bg-gray-100 p-1">
              <strong>Paciente:</strong>
              <span>{hookData.appointment?.patient?.name}</span>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="mb-3 w-full">
          <div className="flex flex-col justify-start">
            {/* convenant */}
            <div className="form-control">
              <label className="label">
                <span className="label-text">Convênio</span>
              </label>
              <select
                {...register('convenant', { required: true })}
                className="select select-bordered w-full max-w-xs"
              >
                <option value="">Selecione um convênio</option>
                {hookData.appointment &&
                  (
                    hookData.appointment?.procedure as any
                  ).procedureToCovenants.map((el: any) => {
                    if (el) {
                      const {
                        id,
                        convenant: { name },
                        value,
                        commission,
                      } = el;
                      const obj = {
                        id,
                        name,
                        value,
                        commission,
                      };
                      return (
                        <option key={el.id} value={`${JSON.stringify(obj)}`}>
                          {el.convenant.name}
                        </option>
                      );
                    }
                  })}
              </select>
              <span className="text-xs text-red-500">
                {errors.convenant && 'Campo requerido'}
              </span>
            </div>
          </div>
          <div className="flex justify-end my-4">
            <button type="submit" className="bt btn-sm btn-primary rounded">
              Próximo
            </button>
          </div>
        </form>
      </div>
    );
  };
