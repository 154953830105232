/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import {
  BadgeCheckIcon,
  BanIcon,
  CalendarIcon,
  CashIcon,
  DocumentIcon,
  ExclamationCircleIcon,
  PrinterIcon,
  TableIcon,
  XIcon,
} from '@heroicons/react/outline';
import React, { useState } from 'react';
import Loading from '../../components/Loading';
import {
  addTime,
  dayWeekColorCss,
  getFormatedDate,
} from '../../utils/datetimeHandler';
import { Appointment } from './appointments.model';
import history from '../../history';
import { useLocation } from 'react-router';
import ModalRecommendations, { Styles } from 'react-modal';
import ModalProceduresConvenants from 'react-modal';
import {
  findConvenantsByProcedures,
  getById as getByIdPorcedure,
} from '../procedures/procedures.service';
import { getById as getByIdProfessional } from '../professionals/professionals.service';
import { parseCurrency } from '../../utils/app.utils';
import { blank } from './appointments.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import ActionBar from '../../components/ActionBar';
import ShowRecord from '../../components/ShowRecord';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: Unreachable code error
pdfMake.vfs = pdfFonts.pdfMake.vfs;

type TableAppointmentsProps = {
  loading: boolean;
  data: Appointment[];
  cel?: string;
  ret?: string;
  aftetAction?: () => void;
};

export type TablePrice = {
  appointment: Appointment;
  procedure: string;
  convenants?: unknown[];
};

type ButtonActionProps = {
  key?: number;
  icon: React.ReactElement;
  caption: string;
  description?: string;
  onClick?: () => void;
};

const ButtonAction = ({
  key,
  icon,
  caption,
  description,
  onClick,
}: ButtonActionProps) => {
  return (
    <button
      key={key}
      onClick={onClick}
      className="-m-3 flex items-left rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 opacity-50 hover:opacity-100"
    >
      <div className="flex h-10 w-10 shrink-0 items-center justify-center text-gray-900 sm:h-12 sm:w-12">
        {icon}
      </div>
      <div className="ml-4 text-left">
        <p className="text-sm font-bold text-gray-900">{caption}</p>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
    </button>
  );
};

const TableAppointments: React.FC<TableAppointmentsProps> = ({
  loading,
  data,
  cel,
  ret,
  aftetAction,
}: TableAppointmentsProps) => {
  const location = useLocation();

  const btnPriceTable = (appointment: Appointment) => (
    <ButtonAction
      caption="Tabela de Preços"
      description="Preços dos procedimentos"
      icon={<CashIcon />}
      onClick={() => openModalProceduresConvenants(appointment)}
    />
  );
  // React.createElement('button', {
  //   className: 'hover:opacity-70',
  //   onClick: () => openModalProceduresConvenants(appointment),
  //   children: [React.createElement(TableIcon, { className: 'h-6' })],
  //   title: 'Tabela de Preços',
  //   // key: 0,
  // });

  const btnSchenduling = (appointment: Appointment) => (
    <ButtonAction
      caption="Agendamento"
      description="Agendar paciente"
      icon={<CalendarIcon />}
      onClick={() =>
        history.push('/appointments-scheduling', {
          id: appointment.id,
          ret: ret ? ret : location.pathname,
          cel: cel || '',
        })
      }
    />
  );
  // React.createElement('button', {
  //   className: 'hover:opacity-70',
  //   onClick: () =>
  //     history.push('/appointments-scheduling', {
  //       id: appointment.id,
  //       ret: ret ? ret : location.pathname,
  //       cel: cel || '',
  //     }),
  //   children: [React.createElement(CalendarIcon, { className: 'h-6' })],
  //   title: 'Agendamento',
  //   key: 1,
  // });

  const btnReSchenduling = (appointment: Appointment) => (
    <ButtonAction
      caption="Re-agendamento"
      description="Re-agendamento dos procedimentos"
      icon={<CalendarIcon />}
      onClick={() =>
        history.push('/appointments-scheduling', {
          id: appointment.id,
          ret: location.pathname,
        })
      }
    />
  );
  // React.createElement('button', {
  //   className: 'hover:opacity-70',
  //   onClick: () =>
  //     history.push('/appointments-scheduling', {
  //       id: appointment.id,
  //       ret: location.pathname,
  //     }),
  //   children: [React.createElement(CalendarIcon, { className: 'h-6' })],
  //   title: 'Re-agendamento',
  //   key: 2,
  // });
  const btnConvenantPgto = (appointment: Appointment) => (
    <ButtonAction
      caption="Convênio/Pagamento"
      description="Convênio e Pagamento"
      icon={<CashIcon />}
      onClick={() =>
        history.push('/appointments-confirmation', {
          id: appointment.id,
          ret: location.pathname,
        })
      }
    />
  );
  // React.createElement('button', {
  //   className: 'hover:opacity-70',
  //   onClick: () =>
  //     history.push('/appointments-confirmation', {
  //       id: appointment.id,
  //       ret: location.pathname,
  //     }),
  //   children: [React.createElement(CashIcon, { className: 'h-6' })],
  //   title: 'Convênio/Pagamento',
  //   key: 3,
  // });

  const btnRecomendations = (appointment: Appointment) => (
    <ButtonAction
      caption="Recomendações"
      description="Recomendações para o paciente"
      icon={<BadgeCheckIcon />}
      onClick={() => openModalRecommendations(appointment.procedure?.id)}
    />
  );
  // React.createElement('button', {
  //   className: 'hover:opacity-70',
  //   onClick: () => openModalRecommendations(appointment.procedure?.id),
  //   children: [React.createElement(BadgeCheckIcon, { className: 'h-6' })],
  //   title: 'Recomendações',
  //   key: 4,
  // });

  const btnCancel = (appointment: Appointment) => (
    <ButtonAction
      caption="Cancelamento"
      description="Cancelamento de agendamento"
      icon={<BanIcon />}
      onClick={() => history.push('/appointments-cancelation', appointment.id)}
    />
  );
  // React.createElement('button', {
  //   className: 'hover:opacity-70',
  //   onClick: () => history.push('/appointments-cancelation', appointment.id),
  //   children: [React.createElement(BanIcon, { className: 'h-6' })],
  //   title: 'Cancelamento',
  //   key: 5,
  // });

  const btnInfoCancel = (appointment: Appointment) => (
    <ButtonAction
      caption={appointment.cancellation}
      description={appointment.cancellation}
      icon={<ExclamationCircleIcon />}
      onClick={() => null}
    />
  );
  // React.createElement('button', {
  //   className: 'hover:opacity-70',
  //   children: [
  //     React.createElement(ExclamationCircleIcon, { className: 'h-6' }),
  //   ],
  //   title: appointment.cancellation,
  //   key: 6,
  // });

  const btnBlank = (appointment: Appointment) => (
    <ButtonAction
      caption="Desmarcar"
      description="Mudar status para aberto"
      icon={<DocumentIcon />}
      onClick={() => {
        if (!appointment.id) return;
        console.log(appointment);
        blank(appointment.id).then(() => {
          self.location.reload();
        });
      }}
    />
    // <ButtonIconConfirm
    //   key={7}
    //   type="button"
    //   color="DEFAULT"
    //   show={true}
    //   bordered={false}
    //   title="Mudar status para aberto"
    //   onClick={() => {
    //     if (!appointment.id) return;
    //     console.log(appointment);
    //     blank(appointment.id).then(() => {
    //       self.location.reload();
    //     });
    //   }}
    // >
    //   <DocumentIcon className="h-6" />
    // </ButtonIconConfirm>
  );

  const btnPrint = (appointment: Appointment) => (
    <ButtonAction
      caption="Imprimir"
      description="impressão"
      icon={<PrinterIcon />}
      onClick={() => printAppointment(appointment)}
    />
  );
  // React.createElement('button', {
  //   className: 'hover:opacity-70',
  //   children: [React.createElement(PrinterIcon, { className: 'h-6' })],
  //   title: appointment.cancellation,
  //   key: 8,
  //   onClick: () => printAppointment(appointment),
  // });

  const status = (appointment: Appointment) => {
    switch (appointment.status) {
      case 'ABERTO': {
        return {
          // trCss: 'bg-white text-gray-400 hover:bg-gray-200',
          actions: [btnPriceTable(appointment), btnSchenduling(appointment)],
        };
      }
      case 'AGENDADO': {
        return {
          trCss: 'bg-yellow-100 text-yellow-800 hover:bg-gray-200',
          actions: [
            btnPriceTable(appointment),
            btnRecomendations(appointment),
            btnReSchenduling(appointment),
            btnConvenantPgto(appointment),
            btnBlank(appointment),
            btnCancel(appointment),
            btnPrint(appointment),
          ],
        };
      }
      case 'ATENDIDO': {
        return {
          trCss: 'bg-green-100 text-green-800',
          actions: [
            btnReSchenduling(appointment),
            btnBlank(appointment),
            btnCancel(appointment),
            btnPrint(appointment),
          ],
        };
      }
      case 'CANCELADO': {
        return {
          trCss: 'bg-red-100 text-red-800',
          actions: [btnInfoCancel(appointment)],
        };
      }
      default: {
        return {
          trCss: '',
          actions: [],
        };
      }
    }
  };

  const customStyles: Styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: '#f5f5f5',
      zIndex: 1000,
    },
  };

  // modal recomendações
  const [recommendations, setRecommendations] = useState<string | null>(null);
  const [modalRecommendationsIsOpen, setModalRecommendationsIsOpen] =
    useState(false);
  function afterOpenModalRecommendations() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModalRecommendations() {
    setModalRecommendationsIsOpen(false);
  }

  const openModalRecommendations = async (procedure?: string) => {
    if (procedure) {
      const rec = await getByIdPorcedure(procedure);
      if (rec.recommendations) {
        setRecommendations(rec.recommendations.recommendationsDesc);
      } else {
        setRecommendations('Sem Recomendações');
      }
      setModalRecommendationsIsOpen(true);
    }
  };
  // modal procedures convenants
  const [proConv, setProConv] = useState<TablePrice[]>([]);
  const [modalProceduresConvenantsIsOpen, setModalProceduresConvenantsIsOpen] =
    useState(false);
  function afterOpenModalProceduresConvenants() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModalProceduresConvenants() {
    setModalProceduresConvenantsIsOpen(false);
  }

  const openModalProceduresConvenants = async (appointment: Appointment) => {
    const professional = appointment.professional.id;
    const prof = await getByIdProfessional(professional);
    const array: TablePrice[] = [];

    for (const proc of prof.procedures || []) {
      const convs = await findConvenantsByProcedures(proc.id);
      const tbPrice: TablePrice = {
        appointment: appointment,
        procedure: proc.name,
        convenants: convs,
      };
      array.push(tbPrice);
    }
    // console.log('tbprice =>', array);
    setProConv(array);
    setModalProceduresConvenantsIsOpen(true);
  };

  const printAppointment = async (appointment: Appointment) => {
    // const professional = appointment.professional.id;
    // const prof = await getByIdProfessional(professional);
    // const array: TablePrice[] = [];
    // const procedure = await getByIdPorcedure(appointment.procedure.id);
    // const covenant = procedure.procedureToCovenants.find(
    //   (pc) => pc.value === appointment.value
    // );

    // for await (const proc of prof.procedures || []) {
    //   const convs = await findConvenantsByProcedures(proc.id);
    //   const tbPrice: TablePrice = {
    //     appointment: appointment,
    //     procedure: proc.name,
    //     convenants: convs,
    //   };
    //   array.push(tbPrice);
    // }
    // console.log('tbprice =>', array);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Unreachable code error
    pdfMake.fonts = {
      Roboto: {
        normal:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
    };

    const dd: TDocumentDefinitions = {
      defaultStyle: {
        font: 'Roboto',
      },

      pageSize: {
        width: 300,
        height: 'auto',
      },

      pageMargins: [20, 5, 20, 5],

      content: [
        { text: process.env.REACT_APP_TITLE || 'Clínica', bold: true },
        { text: 'Protocolo de Atendimento', bold: true },
        { text: '________________________________________________' },
        { text: appointment.status, alignment: 'center' },
        { text: getFormatedDate(appointment.date), alignment: 'center' },
        { text: '________________________________________________' },
        { text: '\n' },
        { text: 'Paciente: ' + appointment.patient?.name },
        { text: 'Procedimento: ' + appointment.procedure.name },
        { text: 'Profissional: ' + appointment.professional.name },
        // { text: 'Início dos atendimentos: ' + appointment.appointmentBegin },
        // { text: 'Sua vez: ' + appointment.order + 'º atendimento' },
        // { text: 'Sala: ' + appointment.room },
        {
          text: appointment.procedure
            ? 'Previsão: ' +
              addTime(
                appointment.appointmentBegin,
                appointment.procedure.estimatedTime.toString(),
                appointment.order - 1
              )
            : '',
        },
        { text: '________________________________________________' },
        // covenant
        //   ? [
        //     {
        //       text: covenant.convenant.showValue ? 'Valor: ' + parseCurrency(covenant.value) : '',
        //     },
        //   ]
        //   : [
        //       {
        //         text: 'Valores e Convênios:',
        //       },
        //       array.map((pc) => [
        //         { text: pc.procedure },
        //         pc.convenants
        //           ? {
        //               ul: pc.convenants.map((cv: any) =>
        //                 cv.convenant.showValue
        //                   ? cv.convenant.name +
        //                     ' - R$ ' +
        //                     parseCurrency(cv.value)
        //                   : cv.convenant.name
        //               ),
        //             }
        //           : { text: 'Sem convênios' },
        //       ]),
        //     ],
        { text: '________________________________________________' },
        {
          text: 'Orientações:',
        },
        {
          text: appointment.procedure.recommendations
            ? appointment.procedure.recommendations.recommendationsDesc
            : 'Sem Orientações',
        },
      ],
    };
    const doc = pdfMake.createPdf(dd);

    // doc.download();
    doc.print();
    // doc.getBase64((data) => {
    //   self.location.href = 'data:application/pdf;base64,' + data;
    // });
  };

  return (
    <>
      <table className="table-compact w-full">
        <thead className="bg-gray-400 border-b-2 border-solid border-black text-gray-900 text-xs sticky top-0 z-10">
          <tr>
            <th className="text-center">Ord.</th>
            <th className="text-left">Dia</th>
            <th className="text-left">STATUS</th>
            <th className="text-left">Profissional</th>
            <th className="text-left">Procedimento</th>
            <th className="text-left">Paciente</th>
            <th className="text-center">Sala</th>
            <th className="text-left">Previsão</th>
            <th className="text-left">Usuário</th>
            <th className="text-left">Prontuário</th>
            <th className="text-left">Ações</th>
          </tr>
        </thead>
        {loading && (
          <tbody>
            <tr>
              <td colSpan={15}>
                <Loading />
              </td>
            </tr>
          </tbody>
        )}
        {!loading && (
          <tbody className="z-0">
            {data.map((el) => (
              <tr
                key={el.id}
                className={status(el).trCss}
                style={{ color: el.professional.color || '#000000' }}
              >
                <th className="text-center">{el.order}</th>
                <td className={`text-left ${dayWeekColorCss(el.date)}`}>
                  {getFormatedDate(String(el.date))}
                </td>
                <td className="text-left">{el.status}</td>
                <td className="text-left">{el.professional.name}</td>
                <td className="text-left">{el.procedure?.name || 'N/D'}</td>
                <td className="text-left">
                  {el.patient?.name || 'Não agendado'}
                </td>
                <td className="text-center">{el.room}</td>
                <td className="text-left">
                  {el.procedure
                    ? addTime(
                        el.appointmentBegin,
                        el.procedure.estimatedTime.toString(),
                        el.order - 1
                      )
                    : '-'}
                </td>
                <td className="text-left">
                  {el.user?.user.toLocaleUpperCase() || 'N/D'}
                </td>
                <td className="text-left">
                  <ShowRecord
                    patientId={el.patient?.id}
                    professionalId={el.professional.id}
                  />
                </td>
                <td className="flex flex-row justify-end">
                  {/* {status(el).actions}  */}
                  <ActionBar items={status(el).actions} />
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      <ModalRecommendations
        isOpen={modalRecommendationsIsOpen}
        onAfterOpen={afterOpenModalRecommendations}
        onRequestClose={closeModalRecommendations}
        style={customStyles}
        contentLabel="Recomendações"
        ariaHideApp={false}
        appElement={document.getElementById('root') as HTMLElement}
      >
        <h1 className="flex justify-between items-center w-60 mb-5">
          <span>Recomendações</span>
          <button onClick={() => closeModalRecommendations()}>
            <XIcon className="w-5 h-5 hover:bg-gray-300" />
          </button>
        </h1>
        <textarea readOnly={true} className="w-full overflow-hidden h-64 p-1">
          {recommendations}
        </textarea>
      </ModalRecommendations>

      <ModalProceduresConvenants
        isOpen={modalProceduresConvenantsIsOpen}
        onAfterOpen={afterOpenModalProceduresConvenants}
        onRequestClose={close}
        style={customStyles}
        contentLabel="Procedimentos e Convênios"
        ariaHideApp={false}
        appElement={document.getElementById('root') as HTMLElement}
      >
        <h1 className="flex flex-row justify-between items-center mb-5">
          <span>Procedimentos e Convênios</span>
          <button onClick={() => closeModalProceduresConvenants()}>
            <XIcon className="w-5 h-5 hover:bg-gray-300" />
          </button>
        </h1>
        <div style={{ height: '60vh', overflow: 'hidden', overflowY: 'auto' }}>
          <ul>
            {proConv.map((pc, i) => {
              return (
                <li key={i} className="font-bold text-lg border-t-2">
                  {pc.procedure}
                  <ul>
                    {pc.convenants?.map((cv: any, ci) => {
                      return (
                        <li
                          key={ci}
                          className="flex justify-between font-medium text-xs"
                        >
                          <span>
                            {cv.convenant.name}
                            (Prev:{' '}
                            {addTime(
                              pc.appointment?.appointmentBegin, //appointmentBegin
                              cv.procedure.estimatedTime.toString(),
                              pc.appointment.order - 1
                            )}
                            )
                          </span>
                          <span className="font-semibold">
                            {cv.convenant.showValue
                              ? parseCurrency(cv.value)
                              : '-'}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
      </ModalProceduresConvenants>
    </>
  );
};

export default TableAppointments;
