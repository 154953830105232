/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef, useState } from 'react';
import {
  // getAuthUser,
  searchProtocol,
  validateToken,
} from '../../services/app.service';
import { Protocol } from '../../app.model';
import { list as listUsers } from '../users/users.service';
import { User } from '../users/user.model';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import {
  nivelSatisfaction,
  phoneMask,
  typesSatisfaction,
} from '../../utils/app.utils';
import Loading from '../../components/Loading';
import { getFormatedDate, secondsToMinutes } from '../../utils/datetimeHandler';

import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment-timezone';
import AppSlidingPanel from '../../components/SlidingPanel';
import ListRelatMessages from './listMessages';

// @ts-ignore: Unreachable code error
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AppRelatProtocols: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const [data, setData] = useState<Protocol[]>([]);
  const [evaluateds, setEvaluateds] = useState(0);
  // const [summary, setSummary] = useState<Record<string, number>>();
  // const [showSummary, setShowSummary] = useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm();
  // const user = getAuthUser();

  const showProtocol = useRef<Protocol>();

  const init = async () => {
    validateToken();
    listUsers().then(setUsers);
  };

  useEffect(() => {
    init();
  }, []);

  const submit = async (form: any) => {
    setLoading(true);
    const args = {
      interval: `${form.dtIni},${form.dtEnd}`,
      // satisfactions: form.satisfactions.reduce((acc: any, user: any) => {
      //   acc.push(user.value);
      //   return acc;
      // }, []),
      users: form.users.reduce((acc: any, user: any) => {
        acc.push(user.value);
        return acc;
      }, []),
    };
    const data = await searchProtocol(args);
    const formatedFilterSatisfaction: any[] = filterSatisfaction.reduce(
      (acc: any, user: any) => {
        acc.push(user.value);
        return acc;
      },
      []
    );
    setData(
      data
        // .filter((dt) => (evaluateds ? dt.evaluated : true))
        .filter((dt) =>
          filterSatisfaction.length > 0
            ? formatedFilterSatisfaction.includes(dt.satisfaction)
            : true
        )
    );
    setLoading(false);
  };

  const showProtocolPanel = (protocol: Protocol) => {
    showProtocol.current = protocol;
    // setSelectedProtocol(el);
    new Promise((t) => setTimeout(t, 300)).then(() => setShowPanel(true));
  };

  const [filterSatisfaction, setFilterSatisfaction] = useState<any[]>([]);
  // const [selectedProtocol, setSelectedProtocol] = useState({} as Protocol);
  // const ref = useRef<HTMLDivElement>(null);

  // const generatePdf = () => {
  //   // @ts-ignore: Unreachable code error
  //   pdfMake.fonts = {
  //     Roboto: {
  //       normal:
  //         'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
  //       bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
  //       italics:
  //         'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
  //       bolditalics:
  //         'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  //     },
  //   };

  //   const body = [];
  //   body.push([
  //     'Data',
  //     'Ord',
  //     'Status',
  //     'Profissional',
  //     'Procedimento',
  //     'Paciente',
  //     'Celular',
  //     '(+)',
  //     '(-)',
  //     'Valor',
  //     'Comissão',
  //     'Pgto',
  //   ]);
  //   data.forEach((el) => {
  //     body.push([
  //       getFormatedDate(String(el.date)) || '',
  //       el.order,
  //       [{ text: el.status || '', alignment: 'center' }],
  //       el.professional.name || '',
  //       el.procedure.name || '',
  //       el.patient?.name || 'Não agendado',
  //       el.patient?.cel || '',
  //       [{ text: parseCurrency(el.addition) || '0', alignment: 'right' }],
  //       [{ text: parseCurrency(el.discount) || '0', alignment: 'right' }],
  //       [{ text: parseCurrency(el.value) || '0', alignment: 'right' }],
  //       [{ text: parseCurrency(el.commission) || '0', alignment: 'right' }],
  //       getTypePgto(el.typePgto),
  //     ]);
  //   });
  //   const dd: TDocumentDefinitions = {
  //     defaultStyle: {
  //       font: 'Roboto',
  //     },

  //     pageSize: 'A4',

  //     pageOrientation: 'landscape',

  //     pageMargins: [40, 60, 40, 60],
  //     header: function (currentPage, pageCount, pageSize) {
  //       return [
  //         {
  //           text: 'Relatório de Atendimentos',
  //           alignment: currentPage % 2 ? 'left' : 'right',
  //           style: 'header',
  //         },
  //         {
  //           canvas: [
  //             {
  //               type: 'line',
  //               x1: 30,
  //               y1: 0,
  //               x2: pageSize.width - 30,
  //               y2: 0,
  //               lineWidth: 1,
  //             },
  //           ],
  //         },
  //       ];
  //     },
  //     content: [
  //       {
  //         table: {
  //           layout: 'lightHorizontalLines',
  //           widths: [50, 15, 50, '*', '*', '*', 70, 40, 40, 40, 40, 60],
  //           headerRows: 1,
  //           body,
  //         },
  //         layout: {
  //           fillColor: function (rowIndex) {
  //             return rowIndex === 0 ? '#CCCCCC' : null;
  //           },
  //         },
  //         style: {
  //           fontSize: 8,
  //         },
  //       },
  //       { text: '\n' },
  //       {
  //         text: showSummary
  //           ? [
  //               `SUMÁRIO: Valor: ${parseCurrency(
  //                 summary?.value
  //               )} - Comissão: ${parseCurrency(summary?.commission)} \n`,

  //               `Valor - Convênio: ${parseCurrency(
  //                 summary?.v_convenant
  //               )}, Dinheiro: ${parseCurrency(
  //                 summary?.v_cash
  //               )}, Cartão Crédito: ${parseCurrency(
  //                 summary?.v_creditcard
  //               )}, Cartão Débito: ${parseCurrency(summary?.v_debitcard)},
  //               PIX: ${parseCurrency(
  //                 summary?.v_pix
  //               )}, Transferência: ${parseCurrency(summary?.v_transfer)}\n
  //                `,

  //               // `Comissão - Convênio: ${parseCurrency(
  //               //   summary?.c_convenant
  //               // )}, Dinheiro: ${parseCurrency(
  //               //   summary?.c_cash
  //               // )}, Cartão Crédito: ${parseCurrency(
  //               //   summary?.c_creditcart
  //               // )}, Cartão Débito: ${parseCurrency(
  //               //   summary?.c_debitcart
  //               // )},PIX: ${parseCurrency(
  //               //   summary?.c_pix
  //               // )}, Transferência: ${parseCurrency(summary?.v_transfer)}\n`,
  //             ]
  //           : '',
  //         style: {
  //           fontSize: 10,
  //         },
  //       },
  //       summaryCount.length > 0 ? { text: 'Procedimentos:', fontSize: 10 } : '',

  //       (summaryCount.length > 0 &&
  //         summaryCount.map((el) => ({
  //           text: `${el[0] || 'N/D'}: ${el[1].length || '0'}`,
  //           style: {
  //             fontSize: 8,
  //           },
  //         }))) ||
  //         'N/D',
  //     ],

  //     footer: function (currentPage, pageCount, pageSize) {
  //       return [
  //         {
  //           canvas: [
  //             {
  //               type: 'line',
  //               x1: 30,
  //               y1: 0,
  //               x2: pageSize.width - 30,
  //               y2: 0,
  //               lineWidth: 1,
  //             },
  //           ],
  //         },
  //         {
  //           stack: [
  //             {
  //               text: 'Pag. '.concat(
  //                 currentPage.toString(),
  //                 ' de ',
  //                 pageCount.toString(),
  //                 ` - ${new Date().getDate()}/${
  //                   new Date().getMonth() + 1
  //                 }/${new Date().getFullYear()}`
  //               ),
  //               margin: [30, 0, 30, 0],
  //               fontSize: 8,
  //             },
  //             {
  //               text: `Período de ${getFormatedDate(
  //                 getValues('dtIni')
  //               )} à ${getFormatedDate(getValues('dtEnd'))}`,
  //               margin: [30, 0, 30, 0],
  //               fontSize: 8,
  //             },
  //             {
  //               text: `Filtros: ${getValues('status')
  //                 .reduce((acc: string[], cur: any) => {
  //                   acc.push(cur.value);
  //                   return acc;
  //                 }, [])
  //                 .toString()}`,
  //               margin: [30, 0, 30, 0],
  //               fontSize: 8,
  //             },
  //             {
  //               text: `Atendente: ${user?.name}`,
  //               margin: [30, 0, 30, 0],
  //               fontSize: 8,
  //             },
  //           ],
  //         },
  //       ];
  //     },

  //     styles: {
  //       header: {
  //         fontSize: 12,
  //         bold: true,
  //         margin: [30, 30, 30, 0],
  //       },
  //     },
  //   };
  //   const doc = pdfMake.createPdf(dd);

  //   // doc.download();
  //   doc.open();
  //   // doc.getBase64((data) => {
  //   //   self.location.href = 'data:application/pdf;base64,' + data;
  //   // });
  // };

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div style={{ width: '90%', position: 'absolute' }}>
        <div className="text-sm breadcrumbs relative">
          <ul>
            <li>Relatorio de Atendimentos Whatsapp</li>
          </ul>
        </div>
        <div className="my-5 w-full relative z-10">
          <form onSubmit={handleSubmit(submit)}>
            <div className="w-full form-control flex flex-row space-x-1 justify-start">
              <div className="flex flex-col space-y-1">
                <div className="flex justify-between">
                  <label className="w-20 bg-gray-300 text-white py-1 pl-3">
                    Início
                  </label>
                  <input
                    type="date"
                    placeholder="Data"
                    className="w-42 rounded-none input input-gray-500 bg-gray-100 input-sm"
                    {...register('dtIni', { required: true })}
                  />
                </div>
                {errors.dtIni?.type === 'required' && (
                  <div className="text-red-500 text-2xs">
                    Campos Obrigatórios
                  </div>
                )}
                <div className="flex justify-between">
                  <label className="w-20 bg-gray-300 text-white py-1 pl-3">
                    Final
                  </label>
                  <input
                    type="date"
                    placeholder="Data"
                    className="w-42 rounded-none input input-gray-500 bg-gray-100 input-sm"
                    {...register('dtEnd', { required: true })}
                  />
                </div>
                {errors.dtEnd?.type === 'required' && (
                  <div className="text-red-500 text-2xs">
                    Campos Obrigatórios
                  </div>
                )}
              </div>
              <div className="w-full flex space-x-2">
                <Controller
                  render={({ field }) => (
                    <Select
                      className="w-80"
                      {...field}
                      isMulti
                      isClearable
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      openMenuOnFocus={false}
                      isSearchable={false}
                      autoFocus={false}
                      openMenuOnClick={false}
                      hideSelectedOptions={true}
                      placeholder={`Selecione um Usuário`}
                      options={users.map((el) => ({
                        label: el.user,
                        value: el.id,
                      }))}
                    />
                  )}
                  name="users"
                  control={control}
                  defaultValue={[]}
                />
                <Select
                  className="w-80"
                  value={filterSatisfaction}
                  onChange={(values: any) => setFilterSatisfaction(values)}
                  isMulti
                  isClearable
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  openMenuOnFocus={false}
                  isSearchable={false}
                  autoFocus={false}
                  openMenuOnClick={false}
                  hideSelectedOptions={true}
                  placeholder={`Nível de Satisfação`}
                  options={typesSatisfaction.map((el) => ({
                    label: el.value,
                    value: el.key,
                  }))}
                />
                {/* <Controller
                  render={({ field }) => (
                    <Select
                      className="w-80"
                      {...field}
                      isMulti
                      isClearable
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      openMenuOnFocus={false}
                      isSearchable={false}
                      autoFocus={false}
                      openMenuOnClick={false}
                      hideSelectedOptions={true}
                      placeholder={`Nível de Satisfação`}
                      options={typesSatisfaction.map((el) => ({
                        label: el.value,
                        value: el.key,
                      }))}
                    />
                  )}
                  name="satisfactions"
                  control={control}
                  defaultValue={[]}
                /> */}
              </div>
              <div className="form-control w-full">
                <label className="cursor-pointer label">
                  <span className="label-text">Somente Avaliados</span>
                  <input
                    type="checkbox"
                    checked={evaluateds === 1}
                    onChange={(e) => setEvaluateds(e.target.checked ? 1 : 0)}
                    className="toggle toggle-primary"
                  />
                </label>
              </div>
              <button type="submit" className="my-2 btn btn-sm btn-gray-500">
                Buscar
              </button>
            </div>
          </form>
        </div>
        <div className="divider opacity-25"></div>
        <div className="flex space-x-4 my-1 justify-center">
          <div className="flex space-x-3">
            <strong>Total:</strong>
            <span>{data.length}</span>
          </div>
          {data.filter((d) => !d.evaluated).length > 0 && (
            <div className="flex space-x-3">
              <strong>Não avaliados:</strong>
              <span>{data.filter((d) => !d.evaluated).length}</span>
            </div>
          )}
          {data.filter((d) => d.evaluated).length > 0 && (
            <div className="flex space-x-3">
              <strong>Avaliados:</strong>
              <span>{data.filter((d) => d.evaluated).length}</span>
            </div>
          )}
          {data.filter((d) => d.satisfaction === 3 && d.evaluated).length >
            0 && (
            <div className="flex space-x-3">
              <strong>Muito Satisfeito:</strong>
              <span>
                {data.filter((d) => d.satisfaction === 3 && d.evaluated).length}
              </span>
            </div>
          )}
          {data.filter((d) => d.satisfaction === 2).length > 0 && (
            <div className="flex space-x-3">
              <strong>Satisfeito:</strong>
              <span>{data.filter((d) => d.satisfaction === 2).length}</span>
            </div>
          )}
          {data.filter((d) => d.satisfaction === 1).length > 0 && (
            <div className="flex space-x-3">
              <strong>Nada Satisfeito:</strong>
              <span>{data.filter((d) => d.satisfaction === 1).length}</span>
            </div>
          )}
          {/* {data.filter((d) => d.satisfaction === 0).length && (
            <div className="flex space-x-3">
              <strong>Cancelado:</strong>
              <span>{data.filter((d) => d.satisfaction === 0).length}</span>
            </div>
          )} */}
        </div>
        <table className="table-compact w-full z-0">
          <thead>
            <tr className="bg-gray-100">
              <th className="text-left">Dia</th>
              <th className="text-center">Protocol</th>
              <th className="text-left">Satisfação</th>
              <th className="text-left">Celular</th>
              <th className="text-left">Atendente</th>
              {/* <th className="text-right">Início</th>
              <th className="text-right">Final</th> */}
              <th className="text-right">Espera</th>
              <th className="text-right">Tempo</th>
              {/* <th className="text-right">Avaliado</th> */}
              <th className="text-left">Obs</th>
            </tr>
          </thead>

          {!loading && (
            <tbody>
              {data.map((el, index) => (
                <tr
                  key={index}
                  onClick={() => showProtocolPanel(el)}
                  className="cursor-pointer hover:bg-gray-100"
                >
                  <td className="text-left">
                    {moment(el.createdAt)
                      .tz('America/Fortaleza')
                      .format('DD/MM/YYYY')}
                  </td>
                  <th className="text-center">{el.protocol}</th>
                  <td className="text-left">
                    {nivelSatisfaction(el.evaluated ? el.satisfaction : 10)}
                  </td>
                  <td className="text-left">{phoneMask(el.cel)}</td>
                  <td className="text-left">{el.user.name || 'N/D'}</td>
                  {/* <td className="text-right">
                    {moment(Number(el.startedAt)).format()}
                  </td>
                  <td className="text-right">
                    {moment(Number(el.finishedAt)).format()}
                  </td> */}
                  <td className="text-right">
                    {secondsToMinutes(
                      moment(el.createdAt)
                        .add(-3, 'hours')
                        .diff(moment(Number(el.startedAt)), 'seconds')
                    )}
                  </td>
                  <td className="text-right">
                    {secondsToMinutes(
                      moment(Number(el.finishedAt)).diff(
                        moment(el.createdAt).add(-3, 'hours'),
                        'seconds'
                      )
                    )}
                  </td>
                  {/* <td className="text-right">{el.evaluated ? 'SIM' : 'NÃO'}</td> */}
                  <td className="text-left w-1/6">
                    {el.obs || 'Sem Observações'}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>

        {/* {data.length > 0 && (
          <button
            type="button"
            className="my-2 btn btn-sm bg-blue-300 border-blue-300"
            onClick={generatePdf}
          >
            Imprimir
          </button>
        )} */}
      </div>
      <AppSlidingPanel
        isOpen={showPanel}
        position={'right'}
        closePanel={() => setShowPanel(false)}
        size={40}
      >
        <div className="p-4">
          <div className="flex justify-between">
            <strong>Protocolo:</strong>
            <span>{showProtocol?.current?.protocol}</span>
          </div>
          {/* <div className="flex justify-between">
            <strong>Número:</strong>
            <span>{selectedProtocol.cel}</span>
          </div> */}
          <div className="flex flex-col">
            <strong>Mensagens:</strong>
            <ListRelatMessages
              messages={showProtocol?.current?.messages || []}
            />
          </div>
          {/* {selectedProtocol.id ? (
            <div className="flex flex-col">
              <strong>Mensagens:</strong>
              <ListRelatMessages messages={messages.current} />
            </div>
          ) : (
            <div className="flex flex-col h-full justify-center indicator-center">
              Aguarde ...
            </div>
          )} */}
        </div>
      </AppSlidingPanel>
    </div>
  );
};

export default AppRelatProtocols;
