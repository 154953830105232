/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import {
  getFormLocalStorage,
  setFormLocalStorage,
  validateToken,
} from '../../services/app.service';

import { list as listProfessionals } from '../professionals/professionals.service';
import { Professional } from '../professionals/professionals.model';
import { useForm } from 'react-hook-form';
import { IRecord } from './record.model';
import ShowRecord from '../../components/ShowRecord';
import { searchRecord } from './record.service';

const RECORD_PROFESSIONAL = 'RecordProfessional';

const AppRecord: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IRecord[]>([]);
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const { handleSubmit, register, setValue } = useForm();

  const init = async () => {
    validateToken();
    await listProfessionals().then(setProfessionals);

    if (getFormLocalStorage(RECORD_PROFESSIONAL)) {
      setValue('professional', getFormLocalStorage(RECORD_PROFESSIONAL));
    }
  };

  useEffect(() => {
    init();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchRecordsByProfessional = (data: any) => {
    setLoading(true);
    setFormLocalStorage(RECORD_PROFESSIONAL, data.professionalId);
    searchRecord({ ...data })
      .then((data) => {
        setData(data.sort((a, b) => a.number.localeCompare(b.number)));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>Prontuários</li>
        </ul>
      </div>
      <div className="my-5 flex justify-between">
        <form onSubmit={handleSubmit(searchRecordsByProfessional)}>
          <div className="form-control flex flex-row justify-start space-x-2">
            <select
              {...register('professionalId', { required: true })}
              className="select bg-gray-100 select-sm input-sm rounded-r-none pr-36"
            >
              <option value="">Selecione um profissional</option>
              {professionals.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
            </select>
            <input
              type="text"
              className="input bg-gray-100 input-sm"
              {...register('patientName', { required: true })}
              placeholder="Nome do paciente"
            />
            <button
              type="submit"
              className="rounded-l-none btn btn-sm btn-gray-500"
            >
              Buscar
            </button>
          </div>
        </form>
      </div>
      <table className="table w-full z-0">
        <thead>
          <tr>
            <th>#</th>
            <th>Profissional</th>
            <th>CRM</th>
            <th>Paciente</th>
            <th>CEL</th>
            <th>Prontuário</th>
          </tr>
        </thead>

        {!loading && (
          <tbody>
            {data.map((el, index) => (
              <tr key={el.id}>
                <th>{index + 1}</th>
                <td>
                  <div className="w-48 truncate">{el?.professional?.name}</div>
                </td>
                <td>{el?.professional?.crm}</td>
                <td>{el?.patient?.name}</td>
                <td>{el?.patient?.cel}</td>
                <td>
                  <ShowRecord
                    patientId={el.patient?.id}
                    professionalId={el.professional.id}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default AppRecord;
