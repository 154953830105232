import { createContext, useContext } from 'react';
import { Appointment } from '../pages/appointments/appointments.model';

type ConvenantData = {
  id: string;
  name: string;
  value: number;
  commission: number;
  // discount: number;
  // addition: number;
}
export interface IAppointmentsConfirmationContext {
  typePgto: string | null;
  appointment: Appointment | null;
  convenant: ConvenantData | null;
  ret: string | null;
}

const AppointmentsConfirmationContext = createContext<IAppointmentsConfirmationContext>(
  {} as IAppointmentsConfirmationContext
);

const useConfirmationAppointment = (): IAppointmentsConfirmationContext => {
  const context = useContext(AppointmentsConfirmationContext);

  return context;
};

export { AppointmentsConfirmationContext, useConfirmationAppointment };
