/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import history from '../../../history';
import Loading from '../../../components/Loading';
import { useWaitingListAdd } from '../../../hooks/waitingListAdd.hook';
import { Patient } from '../../patients/patients.model';
import { Controller, useForm } from 'react-hook-form';
import { find, save } from '../../patients/patients.service';
import MaskedInput from 'react-text-mask';
import getMask from '../../../utils/masks';
import { CheckIcon } from '@heroicons/react/outline';

type AnalizeWaitingListAddProps = {
  setValues: (values: any) => void;
};

export const AnalizeWaitingListAdd: React.FC<AnalizeWaitingListAddProps> = ({
  setValues,
}: AnalizeWaitingListAddProps) => {
  const hookData = useWaitingListAdd();
  const [loading, setLoading] = useState(true);

  const [patients, setPatients] = useState<Patient[]>([]);

  const [documentType, setDocumentType] = useState<'CPF' | 'RG' | 'RN'>('CPF');

  const [submiting, setSubmiting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<[]>([]);
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue('cel', hookData.cel);
    find({ cel: hookData.cel }).then((data) => {
      if (data) setPatients(data);
      setLoading(false);
    });
  }, []);

  const selectPatient = (patient: string) => {
    setValues({
      ...hookData,
      patient,
    });
    history.push('/waiting-list-add/finist');
  };

  const next = (data: any) => {
    setSubmiting(true);
    const dataCad = data;
    if (!dataCad.document) dataCad.document = null;
    // console.log('dataCad patient cad', dataCad);
    save(dataCad)
      .then((ret) => {
        if (ret.data && ret.data.error) {
          setError(true);
          setErrorMsg(ret.data.message);
          setSubmiting(false);
          return;
        }
        if (ret.affected === 0) {
          setError(true);
          return;
        }
        setError(false);
        setSubmiting(false);
        selectPatient(ret.data.id);
      })
      .catch((e) => {
        console.log('error', e);
        setError(true);
        setSubmiting(false);
      });
  };

  // console.log('hook data => ', hookData);

  return (
    <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/waiting-list')}>Lista de Espera</a>
          </li>
          <li>
            {patients.length > 0
              ? 'Selecione um Paciente'
              : 'Cadastre o paciente'}
          </li>
        </ul>
      </div>
      <ul className="w-full steps my-6">
        <li className="step step-primary">Dados</li>
        <li className="step step-primary">Análise</li>
        <li className="step step">Finalizar</li>
      </ul>
      {loading && <Loading />}
      {!loading && (
        <div>
          {patients && patients.length > 0 && (
            <table className="table w-full z-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Cel</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {patients?.map((el, index) => (
                  <tr key={el.id}>
                    <th>{index + 1}</th>
                    <td>{el.name}</td>
                    <td>{el.cel}</td>
                    <td className="flex flex-row justify-end">
                      <button
                        className="hover:opacity-70"
                        onClick={() => selectPatient(el.id)}
                      >
                        <CheckIcon className="h-6" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {patients && patients.length === 0 && (
            <div className={`w-full`}>
              <form onSubmit={handleSubmit(next)} className="mb-3 w-full">
                <input type="hidden" {...register('id')} />
                {/* name */}
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Nome</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Nome/Descrição"
                    className="input input-bordered"
                    {...register('name', { required: true })}
                  />
                  <span className="text-xs text-red-500">
                    {errors.name && 'Campo requerido'}
                  </span>
                </div>

                {/* document */}
                <div className="form-control">
                  <div className="flex justify-between">
                    <label className="cursor-pointer label">
                      <input
                        type="radio"
                        name="opt"
                        checked={documentType === 'CPF'}
                        className="radio mr-1"
                        value="CPF"
                        onChange={() => setDocumentType('CPF')}
                      />
                      <span className="label-text">CPF</span>
                    </label>
                    <label className="cursor-pointer label">
                      <input
                        type="radio"
                        name="opt"
                        checked={documentType === 'RG'}
                        className="radio mr-1"
                        value="RG"
                        onChange={() => setDocumentType('RG')}
                      />
                      <span className="label-text">RG</span>
                    </label>
                    <label className="cursor-pointer label">
                      <input
                        type="radio"
                        name="opt"
                        checked={documentType === 'RN'}
                        className="radio mr-1"
                        value="RN"
                        onChange={() => setDocumentType('RN')}
                      />
                      <span className="label-text">RN</span>
                    </label>
                  </div>
                  <Controller
                    name="document"
                    control={control}
                    render={({ field }) => (
                      <MaskedInput
                        mask={getMask(documentType.toLowerCase())}
                        type="text"
                        placeholder={documentType}
                        className="input input-bordered"
                        {...field}
                      />
                    )}
                  />

                  <span className="text-xs text-red-500">
                    {errors.document && 'Campo requerido'}
                  </span>
                </div>

                {/* cel */}
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Celular</span>
                  </label>
                  <Controller
                    name="cel"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MaskedInput
                        mask={getMask('celular')}
                        type="text"
                        placeholder="Celular"
                        className="input input-bordered"
                        {...field}
                      />
                    )}
                  />
                  <span className="text-xs text-red-500">
                    {errors.cel && 'Campo requerido'}
                  </span>
                </div>

                {/* cel2 */}
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Celular 2</span>
                  </label>
                  <Controller
                    name="cel2"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <MaskedInput
                        mask={getMask('celular')}
                        type="text"
                        placeholder="Celular"
                        className="input input-bordered"
                        {...field}
                      />
                    )}
                  />
                  <span className="text-xs text-red-500">
                    {errors.cel2 && 'Campo requerido'}
                  </span>
                </div>

                {/* address */}
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Endereço</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Endereço"
                    className="input input-bordered"
                    {...register('address', { required: false })}
                  />
                  <span className="text-xs text-red-500">
                    {errors.address && 'Campo requerido'}
                  </span>
                </div>

                {/* city */}
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Cidade</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Cidade"
                    className="input input-bordered"
                    {...register('city', { required: false })}
                  />
                  <span className="text-xs text-red-500">
                    {errors.city && 'Campo requerido'}
                  </span>
                </div>

                {/* dn */}
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Data de Nascimento</span>
                  </label>
                  <input
                    type="date"
                    placeholder="Data de Nascimento"
                    className="input input-bordered"
                    {...register('dn', { required: true })}
                  />
                  <span className="text-xs text-red-500">
                    {errors.dn && 'Campo requerido'}
                  </span>
                </div>

                {/* sexo */}
                <div className="flex flex-col py-1">
                  <span className="label-text">Sexo</span>
                  <div className="p-6 card bordered bg-gray-100 flex flex-row justify-between">
                    <div className="form-control">
                      <label className="cursor-pointer label">
                        <input
                          type="radio"
                          {...register('sexo', { required: true })}
                          className="radio radio-primary"
                          value="m"
                        />
                        <span className="label-text ml-3">Masculino</span>
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="cursor-pointer label">
                        <input
                          type="radio"
                          {...register('sexo', { required: true })}
                          className="radio radio-primary"
                          value="f"
                        />
                        <span className="label-text ml-3">Feminino</span>
                      </label>
                    </div>
                  </div>
                  <span className="text-xs text-red-500">
                    {errors.sexo && 'Campo requerido'}
                  </span>
                </div>

                <div className="flex justify-end mt-3">
                  <button
                    type="submit"
                    className="bt btn-sm btn-primary rounded"
                    disabled={submiting}
                  >
                    Próximo
                  </button>
                </div>
              </form>
              {error && (
                <div className="alert alert-error flex flex-col items-start">
                  <div className="flex-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      className="w-6 h-6 mx-2 stroke-current"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                      ></path>
                    </svg>
                    <label>Error ao salvar registro</label>
                  </div>
                  <ul className="p-3">
                    {errorMsg &&
                      errorMsg.map((err, idx) => <li key={idx}>{err}</li>)}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <div className="flex justify-between my-4">
        <button
          type="button"
          className="bt btn-sm btn-active text-white rounded"
          onClick={() => history.push('/waiting-list-add')}
        >
          Voltar
        </button>
        {/* <button
          type="button"
          className="bt btn-sm btn-primary rounded"
          onClick={() => history.push('/appointments-make/finist')}
        >
          Próximo
        </button> */}
      </div>
    </div>
  );
};

/* : '',
room: 0,
qtde: 0,
procedure: '', */
