/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { validateToken } from '../../services/app.service';
import { useForm } from 'react-hook-form';
import Loading from '../../components/Loading';

import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Appointment } from '../appointments/appointments.model';
import { Patient } from '../patients/patients.model';
import { like } from '../patients/patients.service';
import { getFormatedDate } from '../../utils/datetimeHandler';
import { find } from '../appointments/appointments.service';

// @ts-ignore: Unreachable code error
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AppRelatPatientAppointments: React.FC = () => {
  const relatTitle = 'Relatório Atendimentos por Paciente';
  const [loading, setLoading] = useState(false);
  const [loadingFind, setLoadingFind] = useState(false);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [patient, setPatient] = useState<Patient>();

  const { handleSubmit, register, setValue } = useForm();
  const { handleSubmit: handleSubmit2, register: register2 } = useForm();

  const init = async () => {
    validateToken();
  };

  useEffect(() => {
    init();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const findPatients = async (data: any) => {
    setLoadingFind(true);
    const found = await like(data.find);
    // console.log('found', found);
    setPatients(found);
    setLoadingFind(false);
  };

  const findAppointments = async (data: any) => {
    setLoading(true);
    const ptt = patients.find((p) => (p.id = data.patient));
    console.log('ptt', ptt);
    setPatient(ptt);
    const appoints = await find({ patient: ptt?.id });
    setAppointments(appoints);
    // const found = await like(data.find);
    setLoading(false);
  };

  const generatePdf = () => {
    // @ts-ignore: Unreachable code error
    pdfMake.fonts = {
      Roboto: {
        normal:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
    };

    const dd: TDocumentDefinitions = {
      defaultStyle: {
        font: 'Roboto',
      },

      pageSize: 'A4',

      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            text: relatTitle,
            alignment: currentPage % 2 ? 'left' : 'right',
            style: 'header',
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 30,
                y1: 0,
                x2: pageSize.width - 30,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
        ];
      },

      content: [
        {
          text: `Paciente: ${patient?.name} (${patient?.cel})`,
          style: {
            fontSize: 18,
            bold: true,
          },
        },
        '\n',
        {
          table: {
            layout: 'lightHorizontalLines',
            widths: [50, 50, 50, '*', '*', 30],
            headerRows: 1,
            body: appointments.reduce(
              (acc: any[], a: Appointment) => {
                const row = [
                  a.order.toString(),
                  getFormatedDate(String(a.date)),
                  a.status,
                  a.professional.name,
                  a.procedure?.name || 'N/D',
                  a.room,
                ];
                acc.push(row);
                return acc;
              },
              [
                [
                  'Ordem',
                  'Data',
                  'Status',
                  'Profissional',
                  'Procedimento',
                  'Sala',
                ],
              ]
            ),
          },
          style: {
            fontSize: 8,
          },
        },
      ],

      footer: function (currentPage, pageCount, pageSize) {
        return [
          {
            canvas: [
              {
                type: 'line',
                x1: 30,
                y1: 0,
                x2: pageSize.width - 30,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
          {
            stack: [
              {
                text: 'Pag. '.concat(
                  currentPage.toString(),
                  ' de ',
                  pageCount.toString(),
                  ` - ${new Date().getDate()}/${
                    new Date().getMonth() + 1
                  }/${new Date().getFullYear()}`
                ),
                margin: [30, 0, 30, 0],
                fontSize: 10,
              },
            ],
          },
        ];
      },

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [30, 30, 30, 0],
        },
      },
    };
    const doc = pdfMake.createPdf(dd);

    // doc.download();
    doc.open();
    // doc.getBase64((data) => {
    //   self.location.href = 'data:application/pdf;base64,' + data;
    // });
  };

  // console.log('status =>', getValues('status'));

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div style={{ width: '90%', position: 'absolute' }}>
        <div className="text-sm breadcrumbs relative">
          <ul>
            <li>{relatTitle}</li>
          </ul>
        </div>
        <div className="my-5 w-full relative z-10">
          {loadingFind ? (
            <div className="pt-20 text-center">
              <Loading />
            </div>
          ) : (
            <>
              {patients.length === 0 ? (
                <form onSubmit={handleSubmit(findPatients)}>
                  <div className=" w-full form-control flex flex-row justify-between">
                    <input
                      className="input input-bordered w-full mr-5"
                      placeholder="Nome ou parte dele"
                      {...register('find', { required: true })}
                    />

                    <button
                      type="submit"
                      className="my-2 btn btn-sm btn-gray-500"
                    >
                      Buscar Pacientes
                    </button>
                  </div>
                </form>
              ) : (
                <form onSubmit={handleSubmit2(findAppointments)}>
                  <div className="flex flex-row justify-between">
                    <div className="flex-auto mr-5">
                      <select
                        className="w-full select select-bordered "
                        placeholder="Paciente"
                        {...register2('patient', { required: true })}
                      >
                        <option>Selecione um paciente</option>
                        {patients.map((patient, pIdx) => (
                          <option key={pIdx} value={patient.id}>
                            {patient.name} ({patient.cel})
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex-2">
                      <button
                        type="button"
                        className="my-2 btn btn-sm btn-gray-500"
                        onClick={() => {
                          setPatients([]);
                          setValue('find', '');
                          setAppointments([]);
                        }}
                      >
                        Limpar
                      </button>
                      &nbsp; &nbsp;
                      <button
                        type="submit"
                        className="my-2 btn btn-sm btn-gray-500"
                      >
                        Buscar Atendiementos
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </>
          )}
        </div>
        <div className="divider opacity-25"></div>
        {loading && (
          <div className="pt-20 text-center">
            <Loading />
          </div>
        )}

        {!loading && (
          <div className="my-4">
            <h1 className="py-3 text-center bg-gray-600 text-white">
              {patient?.name}
            </h1>

            <table className="table-compact w-full z-0">
              <thead>
                <tr className="bg-gray-100">
                  <th className="text-center">Ordem</th>
                  <th className="text-left">Dia</th>
                  <th className="text-center">Status</th>
                  <th className="text-left">Profissional</th>
                  <th className="text-left">Procedimento</th>
                  <th className="text-center">Sala</th>
                </tr>
              </thead>

              <tbody>
                {appointments.map((a: Appointment, aIdx: number) => (
                  <tr key={aIdx}>
                    <th className="text-center">{a.order}</th>
                    <td className="text-left">
                      {getFormatedDate(String(a.date))}
                    </td>
                    <td className="text-center">{a.status}</td>
                    <td className="text-left">{a.professional.name}</td>
                    <td className="text-left">{a.procedure?.name || 'N/D'}</td>
                    <td className="text-center">{a.room}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <button
          type="button"
          className="my-2 btn btn-sm bg-blue-300 border-blue-300"
          onClick={generatePdf}
        >
          Imprimir
        </button>
      </div>
    </div>
  );
};

export default AppRelatPatientAppointments;
