import { useCallback, useEffect, useState } from "react"
import { find as patientsFind } from '../../pages/patients/patients.service';
import { Patient } from "../patients/patients.model";
import MaskedInput from "react-text-mask";
import getMask from "../../utils/masks";
import { ArrowCircleLeftIcon, ArrowCircleRightIcon, ArrowRightIcon, ClipboardCopyIcon } from "@heroicons/react/outline";
import {
  list as listRecommendations,
  getById as getByIdRecommendation,
} from '../recommendations/recommendations.service';
import {
  list as listProfessionals,
  find as findProfessinals,
  getById as getByIdProfessional,
} from '../professionals/professionals.service';
import {
  list as listSpecialty,
  getById as getByIdSpecialty,
} from '../specialties/specialties.service';
import { findConvenantsByProcedures } from '../procedures/procedures.service';
import { Appointment } from "../appointments/appointments.model";
import { Professional } from "../professionals/professionals.model";
import { Recommendation } from "../recommendations/recommendations.model";
import { Specialty } from "../specialties/specialties.model";
import { RiEraserFill } from "react-icons/ri";
import { addTime, getFormatedDate } from "../../utils/datetimeHandler";
import { arrayGroupBy, parseCurrency } from "../../utils/app.utils";
import { findAfterNow } from "../appointments/appointments.service";

export default function Atendimento(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [patients, setPatients] = useState<Patient[]>([]);

  const [cel, setCel] = useState<string>('');
  const [currentPatient, setCurrentPatient] = useState<Patient | null>(null);

  const [specialty, setSpecialty] = useState<string>();
  const [specialties, setSpecialties] = useState<Specialty[]>([]);
  const [professional, setProfessional] = useState<string>();
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const [recommendation, setRecommendation] = useState<string>();

  const [areaTransferencia, setAreaTransferencia] = useState<string>('');

  const findByCel = useCallback((cel: string) => {
    setLoading(true);
    patientsFind({ cel }).then(setPatients).finally(() => setLoading(false));
  }, []);

  const sendRecommendationToTranferencia = async () => {
    if (!recommendation) return;

    const send = await getByIdRecommendation(recommendation);
    areaTransferencia.length > 0 && setAreaTransferencia((state) => state + `\n`);
    setAreaTransferencia((state) => state + `${send.recommendationsDesc} \n`);
  };

  const sendSelectedAppoitmentToTransferencia = async (appointment: Appointment) => {
    if (!appointment) return;
    areaTransferencia.length > 0 && setAreaTransferencia((state) => state + `\n`);
    setAreaTransferencia((state) => state + `Paciente: ${appointment.patient?.name} \n`);
    setAreaTransferencia((state) => state + `Dia: ${getFormatedDate(appointment.date)} \n`);
    setAreaTransferencia(
      (state) =>
        state +
        `Previsão: ${addTime(
          appointment.appointmentBegin,
          appointment.procedure.estimatedTime.toString(),
          appointment.order - 1
        )} \n`
    );
    setAreaTransferencia(
      (state) => state + `Profissional: ${appointment.professional?.name} \n`
    );
  };

  const sendSpeciltyToTransferencia = async () => {
    if (!specialty) return;

    const esp = await getByIdSpecialty(specialty);
    const profs = await findProfessinals({ specialty });
    areaTransferencia.length > 0 && setAreaTransferencia((state) => state + `\n`);
    setAreaTransferencia((state) => state + `Profissionais por especialidade: \n`);
    setAreaTransferencia((state) => state + `*${esp.name}* \n`);

    profs.map((p) => setAreaTransferencia((state) => state + `- *${p.name}* \n`));
    setAreaTransferencia((state) => state + ` \n`);
  };

  const sendTablePriceToTransferencia = async () => {
    if (!professional) return;
    const prof = await getByIdProfessional(professional);
    areaTransferencia.length > 0 && setAreaTransferencia((state) => state + `\n`);
    setAreaTransferencia((state) => state + `Tabela de Preços: \n`);
    setAreaTransferencia((state) => state + `*${prof.name}* \n`);
    setAreaTransferencia((state) => state + `\n`);
    for (const proc of prof.procedures || []) {
      const convs = await findConvenantsByProcedures(proc.id);
      setAreaTransferencia((state) => state + `*${proc.name}:*\n`);
      convs.map((c) =>
        setAreaTransferencia(
          (state) =>
            state +
            `- ${c.convenant.name}: ${
              c.convenant.showValue
                ? 'R$ ' + parseCurrency(c.value) + ';'
                : ' Conforme plano;'
            }\n`
        )
      );
    }
  };

  const sendPgtoConditionsToTransferencia = async () => {
    if (!professional) return;
    const prof = await getByIdProfessional(professional);
    areaTransferencia.length > 0 && setAreaTransferencia((state) => state + `\n`);
    setAreaTransferencia((state) => state + `Condições de Pagamentos: \n`);
    setAreaTransferencia((state) => state + `*${prof.name}* \n`);
    setAreaTransferencia((state) => state + `\n`);
    setAreaTransferencia((state) => state + `${prof.pgtoConditions} \n`);
  };

  const sendNextAppointemntsToTransferencia = async () => {
    if (!professional) return;
    const prof = await getByIdProfessional(professional);
    areaTransferencia.length > 0 && setAreaTransferencia((state) => state + `\n`);
    setAreaTransferencia((state) => state + `Próximos Atendimentos: \n`);
    setAreaTransferencia((state) => state + `*${prof.name}* \n`);
    const data = await (
      await findAfterNow(professional)
    ).filter((el) => el.status === 'ABERTO');
    const group = arrayGroupBy(data, 'date');
    const entries = Object.entries(group);
    entries.map((e) => {
      const [date, appts] = e;
      setAreaTransferencia(
        (state) =>
          state +
          `- *${getFormatedDate(date)}*: ${
            (appts as Array<any>).length
          } vaga(s) \n`
      );
    });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(areaTransferencia);
    setAreaTransferencia('');
  }

   const init = useCallback(async () => {
    await listProfessionals().then(setProfessionals);
    await listSpecialty().then(setSpecialties);
    await listRecommendations().then(setRecommendations);
  },[]);

  useEffect(() => {
    init();
  }, [init]);

  if (!currentPatient) {
  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 space-y-4`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>Whatsapp</li>
        </ul>
      <div className="flex gap-2">
        <MaskedInput
          mask={getMask('celular')}
          type="search"
          placeholder="Celular"
          className="px-3 py-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm max-w-xs"
          value={cel}
          onChange={(e) => setCel(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && findByCel(cel)}
        />
        <button
          onClick={() => findByCel(cel)}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md"
        >
          Buscar
        </button>
      </div>
      </div>
      <div className="grid grid-cols-4 gap-3 mb-1 ">
        {loading ? (
          <div>Carregando...</div>
        ) : (
           <table className="table table-compact">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Telefone</th>
                  <th>Endereço</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {patients.map((patient) => (
                  <tr key={patient.id}>
                    <td>{patient.name}</td>
                    <td>{patient.cel}</td>
                    <td>{patient.address}</td>
                    <td>
                      <button className="hover:bg-slate-200 px-2 py-1 rounded-box" onClick={() => setCurrentPatient(patient)}>
                        <ArrowCircleRightIcon className="w-6 h-6" />
                      </button>
                    </td>
                  </tr>))}
              </tbody>
            </table>

        )}
    </div>
    </div>)
} else{
  return (
      <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 space-y-4 h-full`}>
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <button className="hover:bg-slate-200 px-2 py-1 rounded-box" onClick={() => setCurrentPatient(null)}>
                <ArrowCircleLeftIcon className="w-6 h-6" />
              </button>
            </li>
            <li>{currentPatient.name}</li>
          </ul>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div className="flex flex-col">
            <strong className="my-1 inline-block">Recomendações</strong>
            <div className="flex">
              <select
                value={recommendation}
                onChange={(e) => setRecommendation(e.target.value)}
                className="select bg-gray-100 select-sm rounded-r-none pr-10"
              >
                <option value="">Selecione uma recomendação</option>
                {recommendations.map((el) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </select>
              <button
                onClick={sendRecommendationToTranferencia}
                type="button"
                className="rounded-l-none btn btn-sm btn-gray-500"
              >
                <ArrowRightIcon className="w-4 h-4" />
              </button>
            </div>

            <strong className="my-1 inline-block">
              Profissionais por especialidade
            </strong>
            <div className="flex">
              <select
                value={specialty}
                onChange={(e) => setSpecialty(e.target.value)}
                className="select bg-gray-100 select-sm rounded-r-none pr-10"
              >
                <option value="">Selecione uma especialidade</option>
                {specialties.map((el) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </select>
              <button
                onClick={sendSpeciltyToTransferencia}
                type="button"
                className="rounded-l-none btn btn-sm btn-gray-500"
              >
                <ArrowRightIcon className="w-4 h-4" />
              </button>
            </div>

            <strong className="my-1 inline-block">Profissionais</strong>
            <div className="flex">
              <select
                value={professional}
                onChange={(e) => setProfessional(e.target.value)}
                className="select bg-gray-100 select-sm input-sm"
              >
                <option value="">Selecione profissional</option>
                {professionals.map((el) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-2 flex flex-col space-y-1">
              <button
                onClick={sendTablePriceToTransferencia}
                type="button"
                className="btn btn-sm btn-gray-500 flex space-x-1 items-center"

              >
                <span>Tabela de Preços</span>
                <ArrowRightIcon className="w-4 h-4" />
              </button>
              <button
                onClick={sendNextAppointemntsToTransferencia}
                type="button"
                className="btn btn-sm btn-gray-500 flex space-x-1 items-center"
              >
                <span>Próximos Atendimentos</span>
                <ArrowRightIcon className="w-4 h-4" />
              </button>
              <button
                onClick={sendPgtoConditionsToTransferencia}
                type="button"
                className="btn btn-sm btn-gray-500 flex space-x-1 items-center"
              >
                <span>Condições de Pagamentos</span>
                <ArrowRightIcon className="w-4 h-4" />
              </button>
          </div>
          </div>
          {/* area de transferencia */}
          <div className="col-span-3 px-2">
            <div>Área de transferência</div>
            <textarea className="w-full rounded-box bg-gray-100 p-2" rows={12} value={areaTransferencia} />
             <div className="flex justify-between">
            <button
                type="button"
                className="btn btn-sm btn-gray-500 flex space-x-1 items-center  border-gray-500 border-solid border-2"
                onClick={() => setAreaTransferencia('')}
              >
                <span>Limpar Área de Transferência</span>
                <RiEraserFill className="w-4 h-4" />
              </button>

              <button
                // onClick={sendPgtoConditionsToTransferencia}
                type="button"
                className="btn btn-sm btn-gray-500 flex space-x-1 items-center"
                onClick={copyToClipboard}
              >
                <span>Copiar Área de Transferência</span>
                <ClipboardCopyIcon className="w-4 h-4" />
              </button>
          </div>
          </div>

        </div>
      </div>
      );
}

}
