/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import history from '../../../history';
import { useWaitingListSchenduling } from '../../../hooks/waitingListSchenduling.hook';
import { validateToken } from '../../../services/app.service';
import { Appointment } from '../../appointments/appointments.model';
import { findAfterNowAberto } from '../../appointments/appointments.service';
import { getFormatedDate } from '../../../utils/datetimeHandler';
import { ArrowRightIcon } from '@heroicons/react/outline';
import Loading from '../../../components/Loading';

type AnalizeWaitingListSchendulingProps = {
  setValues: (values: any) => void;
};

export const AnalizeWaitingListSchenduling: React.FC<AnalizeWaitingListSchendulingProps> =
  ({ setValues }: AnalizeWaitingListSchendulingProps) => {
    const hookData = useWaitingListSchenduling();
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState<Appointment[]>([]);

    const init = async () => {
      validateToken();
      setLoading(true);
      if (!hookData.professional?.id) return;
      findAfterNowAberto(hookData.professional?.id).then((data) => {
        setData(data);
        setLoading(false);
      });
    };

    useEffect(() => {
      init();
    }, []);

    const next = (appointment: any) => {
      setValues({
        ...hookData,
        appointment,
      });
      history.push('/waiting-list-scheduling/finist');
    };

    return (
      <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <a onClick={() => history.push('/waiting-list')}>
                Lista de Espera
              </a>
            </li>
            <li>Agendamento de Atendimento</li>
          </ul>
        </div>
        <ul className="w-full steps my-6">
          <li className="step step-primary">Dados</li>
          <li className="step step-primary">Análise</li>
          <li className="step step">Finalizar</li>
        </ul>

        {loading && <Loading />}

        {!loading && (
          <table className="table-compact w-full z-0">
            <thead>
              <tr>
                <th className="text-center">Ord.</th>
                <th className="text-left">Dia</th>
                <th className="text-left">STATUS</th>
                <th className="text-center">Sala</th>
                <th className="text-left"></th>
              </tr>
            </thead>

            <tbody>
              {data.map((el) => (
                <tr key={el.id}>
                  <th className="text-center">{el.order}</th>
                  <td className="text-left">
                    {getFormatedDate(String(el.date))}
                  </td>
                  <td className="text-left">{el.status}</td>
                  <td className="text-center">{el.room}</td>
                  <td className="flex flex-row justify-end">
                    <button onClick={() => next(el)}>
                      <ArrowRightIcon className="h-6" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="flex justify-between my-4">
          <button
            type="button"
            className="bt btn-sm btn-active text-white rounded"
            onClick={() => history.push('/waiting-list-scheduling')}
          >
            Voltar
          </button>
          {/* <button
          type="button"
          className="bt btn-sm btn-primary rounded"
          onClick={() => history.push('/appointments-make/finist')}
        >
          Próximo
        </button> */}
        </div>
      </div>
    );
  };

/* : '',
room: 0,
qtde: 0,
procedure: '', */
