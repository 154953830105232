import React, { useEffect, useState } from 'react';
import { useConfirmationAppointment } from '../../../hooks/appointmentsConfirmation.hook';
import history from '../../../history';
import Loading from '../../../components/Loading';
import { SendButton } from '../../../components/SendButton';
import { typesPgtos } from '../../../utils/app.utils';
import { update } from '../appointments.service';

export const FinistAppointmentConfirmation: React.FC = () => {
  const hookData = useConfirmationAppointment();
  const [submiting, setSubmiting] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const [newValue, setNewValue] = useState(0);

  useEffect(() => {
    if (hookData.appointment != null) {
      const vl =
        hookData.appointment?.value +
        hookData.appointment?.addition -
        hookData.appointment?.discount;

      console.log('new value', vl);
      setNewValue(vl);
    }
    setLoading(false);
  }, [hookData.appointment]);

  const confirmation = () => {
    setSubmiting(true);

    const confirmationData = {
      ...hookData.appointment,
      value: newValue,
      status: 'ATENDIDO',
      typePgto: hookData.typePgto,
    };

    update(confirmationData)
      .then(() => {
        setLoading(false);
        history.push(hookData.ret || '/appointments');
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  // console.log(hookData);

  return (
    <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/appointments')}>Atendimentos</a>
          </li>
          <li>Confirmar Atendimento</li>
        </ul>
      </div>
      <ul className="w-full steps my-6">
        <li className="step step-primary">Dados</li>
        <li className="step step-primary">Análise</li>
        <li className="step step-primary">Finalizar</li>
      </ul>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col">
          <div className="flex justify-between bg-gray-100 p-1">
            <strong>Convênio:</strong>
            <span>{hookData.convenant?.name}</span>
          </div>
          <div className="flex justify-between bg-gray-200 p-1">
            <strong>Valor:</strong>
            <span>{Number(newValue).toFixed(2).replace('.', ',')}</span>
          </div>

          <div className="flex justify-between bg-gray-200 p-1">
            <strong>Acréscimo:</strong>
            <span>
              {Number(hookData.appointment?.addition)
                .toFixed(2)
                .replace('.', ',')}
            </span>
          </div>
          <div className="flex justify-between bg-gray-200 p-1">
            <strong>Desconto:</strong>
            <span>
              {Number(hookData.appointment?.discount)
                .toFixed(2)
                .replace('.', ',')}
            </span>
          </div>
          <div className="flex justify-between bg-gray-100 p-1">
            <strong>Tipo de Pagamento:</strong>
            <span>
              {typesPgtos.filter((el) => el.key === hookData.typePgto)[0].value}
            </span>
          </div>
        </div>
      )}

      <div className="flex justify-between my-4">
        <button
          type="button"
          className="bt btn-sm btn-active text-white rounded"
          onClick={() => history.push('/appointments-confirmation/analize')}
        >
          Voltar
        </button>
        <SendButton
          click={confirmation}
          caption="Confirmar"
          submiting={submiting}
          size="btn-sm"
        />
      </div>
    </div>
  );
};
