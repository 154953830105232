/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import history from '../../history';
import { Convenant } from './covenants.model';
import { validateToken } from '../../services/app.service';
import { getById, save } from './covenants.service';

const AppCadConvenantsForm: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const [submiting, setSubmiting] = useState<boolean>(false);
  const [submited, setSubmited] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<[]>([]);

  const location = useLocation<string>();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const init = async () => {
    validateToken();
    const covenantId = location.state;
    console.log('covenantId', covenantId);
    if (covenantId) {
      const data = await getById(covenantId);
      const entries = Object.entries(data);
      entries.forEach((entry) => setValue(entry[0], entry[1]));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const onSubmit = (data: Convenant) => {
    setSubmiting(true);
    save(data)
      .then((ret) => {
        if (ret.data && ret.data.error) {
          setError(true);
          setErrorMsg(ret.data.message);
          setSubmited(false);
          setSubmiting(false);
          return;
        }
        if (ret.affected === 0) {
          setError(true);
          return;
        }
        setError(false);
        setSubmiting(false);
        setSubmited(true);
        history.goBack();
      })
      .catch((e) => {
        console.log('error', e);
        setError(true);
        setSubmited(false);
        setSubmiting(false);
      });
  };

  if (loading) {
    return (
      <div className="h-screen flex flex-col justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className={`maxW-3xl mx-auto px-2 sm:px-6 lg:px-8 my-20 z-0`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.goBack()}>Cadastro de Convênios</a>
          </li>
          <li className="text-primary">
            {location.state ? 'Editar Convênio' : 'Inserir Convênio'}
          </li>
        </ul>
      </div>
      <div className={`w-full`}>
        <form onSubmit={handleSubmit(onSubmit)} className="mb-3 w-full">
          <input type="hidden" {...register('id')} />
          <div className="form-control">
            <label className="label">
              <span className="label-text">Nome</span>
            </label>
            <input
              type="text"
              placeholder="Nome/Descrição"
              className="input input-bordered"
              {...register('name', { required: true })}
            />
            <span className="text-xs text-red-500">
              {errors.name && 'Campo requerido'}
            </span>
          </div>

          <div className="form-control">
            <label className="cursor-pointer label">
              <span className="label-text">Exibir o valor do convênio</span>
              <input
                type="checkbox"
                {...register('showValue')}
                className="toggle toggle-primary"
              />
            </label>
          </div>

          <div className="flex justify-between mt-3">
            <button
              className="btn bg-gray-500"
              onClick={() => history.push('/cad-covenants')}
            >
              Voltar
            </button>
            <button
              type="submit"
              className={`
              btn btn-primary
              ${submiting ? 'bg-gray-500' : 'bg-primary'}
            `}
              disabled={submiting}
            >
              {submiting && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 textWhite"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              {submiting ? 'Enviando...' : 'Salvar'}
            </button>
          </div>
        </form>
        {submited && (
          <div className="alert alert-info">
            <div className="flex-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="w-6 h-6 mx-2 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <label>Registro salvo com sucesso!</label>
            </div>
          </div>
        )}
        {error && (
          <div className="alert alert-error flex flex-col items-start">
            <div className="flex-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="w-6 h-6 mx-2 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                ></path>
              </svg>
              <label>Error ao salvar registro</label>
            </div>
            <ul className="p-3">
              {errorMsg && errorMsg.map((err, idx) => <li key={idx}>{err}</li>)}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppCadConvenantsForm;
