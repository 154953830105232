import { useContext, createContext, useEffect, useState } from "react";
import socketio, { Socket } from "socket.io-client";
import { User } from "../pages/users/user.model";
import { list } from "../pages/users/users.service";
import { getAuthUser } from "../services/app.service";

export interface IContext {
  socket: Socket;
  currentUser: User | null;
  users: User[];
}

interface IProvider {
  children: React.ReactNode;
}
const Context = createContext<IContext>({} as IContext);

const SocketProvider: React.FC<IProvider> = ({ children }: IProvider) => {
  const [users, setUsers] = useState<User[]>([]);
  const [socket, setSocket] = useState<Socket>();
  useEffect(() => {
    list().then(setUsers);
    const _socket = socketio(`${process.env.REACT_APP_SOCKET}`, { transports: ["websocket"] });
    setSocket(_socket);
    return () => {
      _socket.close();
    };
  }, []);

  const currentUser = getAuthUser();

  if (!socket) return null;

  return (
    <Context.Provider
      value={{
        socket,
        currentUser,
        users
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useSocket = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { useSocket, SocketProvider };
