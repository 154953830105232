/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { WaitingList } from './waitingList.model';
import { validateToken } from '../../services/app.service';
import { list } from './waitingList.service';
import TableWaitingLists from './TableWaitingList';

const AppCadWaitingLists: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<WaitingList[]>([]);
  const init = async () => {
    validateToken();
    const data = await list();
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 z-0`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li className="text-primary">Lista de Espera</li>
        </ul>
      </div>
      <div className="overflow-x-auto">
        <TableWaitingLists data={data} loading={loading} showInsert={true} />
      </div>
    </div>
  );
};

export default AppCadWaitingLists;
