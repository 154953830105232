/* eslint-disable react/jsx-filename-extension */
import {
  BanIcon,
  CheckCircleIcon,
  PencilAltIcon,
  PlusCircleIcon,
} from '@heroicons/react/outline';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import history from '../../history';
import { Professional } from './professionals.model';
import { validateToken } from '../../services/app.service';
import { list } from './professionals.service';

const AppCadProfessionals: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Professional[]>([]);
  const init = async () => {
    validateToken();
    const data = await list();
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 z-0`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li className="text-primary">Cadastro de Profissionais</li>
        </ul>
      </div>
      <div className="overflow-x-auto">
        <table className="table w-full z-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th>ABV</th>
              <th>CRM</th>
              <th>CEL</th>
              <th>Especialidade</th>
              <th>L/Espera</th>
              <th>Ativo</th>
              <th className="flex flex-col items-end">
                <Link to={'/cad-professionals-form'}>
                  <span className="flex items hover:opacity-50">
                    <PlusCircleIcon className="w-5 mr-2" /> Inserir
                  </span>
                </Link>
              </th>
            </tr>
          </thead>

          {!loading && (
            <tbody>
              {data.map((el, index) => (
                <tr key={el.id} style={{ color: el.color || '#000000' }}>
                  <th>{index + 1}</th>
                  <td>
                    <div className="w-48 truncate">{el.name}</div>
                  </td>
                  <td>{el.prefix}</td>
                  <td>{el.crm}</td>
                  <td>{el.cel}</td>
                  <td>
                    <div className="w-28 truncate">{el.specialty.name}</div>
                  </td>
                  <td>{el.onlyWaitingList ? 'Sim' : 'Não'}</td>
                  <td>
                    {el.isActive ? (
                      <CheckCircleIcon className="h-6 text-green-900" />
                    ) : (
                      <BanIcon className="h-6 text-red-900" />
                    )}
                  </td>
                  <td className="flex flex-row justify-end">
                    <button
                      className="hover:opacity-70"
                      onClick={() =>
                        history.push('/cad-professionals-form', el.id)
                      }
                    >
                      <PencilAltIcon className="h-6" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default AppCadProfessionals;
