import { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AppointmentsMakeContext } from '../../../hooks/appointmentsMaking.hook';
import { InitialAppointmentMakeLote } from './initialAppointmentMakeLote';
import { AnalizeAppointmentMakeLote } from './analizeAppointmentMakeLote';
import { FinistAppointmentMakeLote } from './finistAppointmentMakeLote';

const AppAppointmentsMakeLote: React.FC = () => {
  const { path } = useRouteMatch();
  const [data, setData] = useState(() => ({
    date: '',
    appointmentBegin: '',
    room: 1,
    qtde: 1,
    professional: '',
    orderInit: 1,
    loteDate: [],
  }));

  return (
    <AppointmentsMakeContext.Provider value={{ ...data }}>
      <Switch>
        <Route exact path={path}>
          <InitialAppointmentMakeLote setValues={setData} />
        </Route>
        <Route path={`${path}/analize`}>
          <AnalizeAppointmentMakeLote setValues={setData} />
        </Route>
        <Route path={`${path}/finist`}>
          <FinistAppointmentMakeLote setValues={setData} />
        </Route>
      </Switch>
    </AppointmentsMakeContext.Provider>
  );
};

export default AppAppointmentsMakeLote;
