import React, { useEffect, useState } from 'react';
import { useSchedulingAppointment } from '../../../hooks/appointmentsScheduling.hook';
import history from '../../../history';
import { addTime, getFormatedDate } from '../../../utils/datetimeHandler';
import { Patient } from '../../patients/patients.model';
import { getById as getByIdPatient } from '../../patients/patients.service';
import { getById as getByIdProcedure } from '../../procedures/procedures.service';
import Loading from '../../../components/Loading';
import { SendButton } from '../../../components/SendButton';
import { update } from '../appointments.service';
import { Procedure } from '../../procedures/procedures.model';
import { getAuthUser } from '../../../services/app.service';
import { Appointment } from '../appointments.model';
import { find as findAppointments } from '../appointments.service';

export const FinistAppointmentScheduling: React.FC = () => {
  const hookData = useSchedulingAppointment();
  const [submiting, setSubmiting] = useState<boolean>(false);
  const [patient, setPatient] = useState<Patient>();
  const [procedure, setProcedure] = useState<Procedure>();
  const [loading, setLoading] = useState(false);
  const [lastAppointments, setLastAppointments] = useState<Appointment[]>([]);

  const user = getAuthUser();

  const init = async () => {
    setLoading(true);
    console.log('hookData ==> ', hookData);
    if (hookData.patient && hookData.procedure) {
      await getByIdPatient(String(hookData.patient)).then((data) => {
        setPatient(data);
      });
      await getByIdProcedure(String(hookData.procedure)).then((data) => {
        setProcedure(data);
      });
    }

    findAppointments({
      patient: hookData.patient,
      professional: hookData.appointment?.professional,
    }).then((data) => {
      if (data.length === 0) {
        setLastAppointments([]);
        return;
      }
      data.reverse().length = 6;
      setLastAppointments(data.reverse());
    });

    setLoading(false);
  };

  useEffect(() => {
    init();
  }, [hookData]);

  const scheduling = () => {
    setSubmiting(true);
    console.log('hookData finist', hookData);

    update({
      ...hookData.appointment,
      status: 'AGENDADO',
      patient: hookData.patient,
      procedure: hookData.procedure,
      user,
    }).then(() => {
      setSubmiting(true);
      history.push(hookData.ret || '/appointments');
    });
  };

  return (
    <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/appointments')}>Atendimentos</a>
          </li>
          <li>Agendar Atendimento</li>
        </ul>
      </div>
      <ul className="w-full steps my-6">
        <li className="step step-primary">Dados</li>
        <li className="step step-primary">Análise</li>
        <li className="step step-primary">Finalizar</li>
      </ul>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col">
          <div className="flex justify-between bg-gray-200 p-1">
            <strong>Dia:</strong>
            <span>{getFormatedDate(String(hookData.appointment?.date))}</span>
          </div>
          <div className="flex justify-between bg-gray-100 p-1">
            <strong>Ordem:</strong>
            <span>{hookData.appointment?.order}</span>
          </div>
          <div className="flex justify-between bg-gray-200 p-1">
            <strong>Previsão:</strong>
            <span>
              {hookData.appointment &&
                procedure &&
                addTime(
                  hookData.appointment.appointmentBegin,
                  procedure.estimatedTime.toString(),
                  hookData.appointment.order - 1
                )}
            </span>
          </div>
          <div className="flex justify-between bg-gray-100 p-1">
            <strong>Profissional:</strong>
            <span>{hookData.appointment?.professional.name}</span>
          </div>
          <div className="flex justify-between bg-gray-200 p-1">
            <strong>Procedimento:</strong>
            <span>{procedure?.name}</span>
          </div>
          <div className="flex justify-between bg-gray-100 p-1">
            <strong>Paciente:</strong>
            <span>{patient?.name}</span>
          </div>
          <div className="flex justify-between bg-gray-200 p-1">
            <strong>Celular:</strong>
            <span>{patient?.cel}</span>
          </div>
        </div>
      )}

      <div className="flex justify-between bg-gray-200 p-1 mt-3 text-sm">
        <strong>Histórico de Atendimentos:</strong>
      </div>

      {lastAppointments.map((appointment) => (
        <div key={appointment.id} className="grid grid-cols-12 text-xs">
          <span className="col-span-2">
            {getFormatedDate(appointment.date)}
          </span>
          <span className="col-span-4">{appointment.procedure.name}</span>
          <span className="col-span-6">{appointment.professional.name}</span>
        </div>
      ))}
      {lastAppointments && lastAppointments.length === 0 && (
        <div className="text-center">Sem Histórico</div>
      )}

      <div className="flex justify-between my-4">
        <button
          type="button"
          className="bt btn-sm btn-active text-white rounded"
          onClick={() => history.push('/appointments-scheduling/analize')}
        >
          Voltar
        </button>
        <SendButton
          click={scheduling}
          caption="Marcar"
          submiting={submiting}
          size="btn-sm"
        />
      </div>
    </div>
  );
};
