import { Dialog, Transition } from '@headlessui/react';
import { ThumbUpIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useState } from 'react';
import { convertDate_dd_mm_yyyy_hh_mm_ss } from '../../utils/functions';
import { incluirMessageTextWhatsapp } from './appointments.service';
import ShortUniqueId from 'short-unique-id';

interface ModalConfigSendMessageProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export default function ModalConfigSendMessage(
  props: ModalConfigSendMessageProps
): JSX.Element {
  function closeModal() {
    props.setIsOpen(false);
  }

  const [telefone, setTelefone] = useState('');
  const teste = async () => {
    const uid = new ShortUniqueId({ length: 10 });
    const dt_hr = new Date(Date.now() + 10000);
    const it = {
      codigoExterno: uid.rnd(),
      tipo: 'Texto',
      classificacao: 'Teste',
      destinatario: {
        nome: 'Teste',
        ddi: 55,
        telefone,
      },
      mensagemWhatsapp: 'Teste de envio de mensagem',
      // adicionar 10 segundos
      dataHoraEnviar: convertDate_dd_mm_yyyy_hh_mm_ss(dt_hr),
    }
    await incluirMessageTextWhatsapp(it);
  }

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
                >
                  <span className="text-lg font-bold leading-6 font-display">
                    Configurações de Envio de Mensagem
                  </span>
                  <button onClick={closeModal} className="hover:text-gray-400">
                    <XIcon className="w-5 h-5" />
                  </button>
                </Dialog.Title>
                <div className="flex flex-col font-display py-4 px-6">
                  <fieldset className="mt-4 mb-6 flex justify-between items-center gap-2">
                    <legend>Testar</legend>
                    <input
                      type="text"
                      value={telefone}
                      placeholder='Telefone com DDD 88999999999'
                      onChange={(e) => setTelefone(e.target.value)}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary2 focus:border-primary2 sm:text-sm"
                    />
                    <button
                      title="Testar"
                      type="button"
                      onClick={teste}
                      className="flex items-center space-x-2 px-2 py-1 rounded-box bg-primary text-default-white hover:bg-primary1 hover:text-gray-100"
                    >
                      <ThumbUpIcon className="w-4 h-4" /> Testar
                    </button>
                  </fieldset>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
