import { Tab } from '@headlessui/react';
import {
  ChatIcon,
  MailIcon,
  MailOpenIcon,
  TrashIcon,
  XIcon,
} from '@heroicons/react/outline';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactModal from 'react-modal';
import { Message } from '../../app.model';
import ButtonConfirmBasic from '../../components/ButtonConfirmBasic';
import { useSocket } from '../../hooks/socket.context';
import { getFormatedDateTime } from '../../utils/datetimeHandler';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    overflow: 'hidden',
  },
};

const Talk: React.FC = () => {
  const { socket, currentUser, users } = useSocket();
  const [messages, setMesssages] = useState<Message[]>([]);
  const [writeModalIsOpen, setWriteModalIsOpen] = useState(false);

  const openWriteModal = () => {
    setWriteModalIsOpen(true);
  };

  const closeWriteModal = () => {
    setWriteModalIsOpen(false);
  };

  useEffect(() => {
    const payload = {
      userId: currentUser?.id,
    };
    socket.emit('talks-by-user', payload);
    socket.on('talks', (data) => {
      const talks = data.payload.talks
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((t: any) => t.userId === currentUser?.id)
        .sort(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (a: any, b: any) =>
            new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
        );
      setMesssages(talks);
    });
  }, [socket]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitMessage = (form: any) => {
    if (!currentUser) return;
    const payload = {
      talk: {
        message: form.message,
        readed: false,
        senderId: currentUser.id,
        userId: form.sender,
      },
    };
    socket.emit('send-talk', payload);
    resetWriteMessage();
    closeWriteModal();
  };

  const readerMessage = (talkId: string) => {
    const payload = {
      talkId,
    };
    socket.emit('reader-talk', payload);
  };

  const unreaderMessage = (talkId: string) => {
    const payload = {
      talkId,
    };
    socket.emit('unreader-talk', payload);
  };

  const deleteMessage = (talkId: string) => {
    const payload = {
      talkId,
    };
    socket.emit('delete-talk', payload);
  };

  const {
    register: registerWriteMessage,
    formState: { errors: errorsWriteMessage },
    handleSubmit: handlerSubmitWriteMessage,
    reset: resetWriteMessage,
  } = useForm();

  return (
    <div className={`max-w-4xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 z-0`}>
      <div className="text-sm breadcrumbs flex justify-between">
        <ul>
          <li className="text-primary">Recados</li>
        </ul>
        <button
          type="button"
          onClick={openWriteModal}
          className="rounded btn btn-xs btn-gray-500 flex space-x-2 items-center"
        >
          <ChatIcon className="w-4 h-4" /> <span>Escrever Recado</span>
        </button>
      </div>
      <div
        style={{ height: '75vh' }}
        className="flex border-b-2 border-solid border-gray-800"
      >
        <Tab.Group vertical={true}>
          <Tab.List
            aria-modal
            className="w-1/3 flex flex-col p-1 space-y-1 bg-gray-100 overflow-hidden overflow-y-auto"
          >
            {messages.length === 0 && (
              <div className="flex flex-col justify-center items-center h-full">
                Caixa Vazia!
              </div>
            )}
            {messages.map((item) => (
              <Tab key={item._id} as={Fragment}>
                {({ selected }) => (
                  <button
                    type="button"
                    className={`flex items-center p-2 hover:opacity-70 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${
                      selected ? 'bg-gray-500' : ''
                    }`}
                  >
                    <div
                      className={`flex items-center justify-center flex-shrink-0 w-10 h-10 ${
                        selected
                          ? 'text-gray-200'
                          : item.readed
                          ? 'text-gray-300'
                          : 'text-gray-800'
                      } sm:h-12 sm:w-12`}
                    >
                      {!item.readed ? <MailIcon /> : <MailOpenIcon />}
                    </div>
                    <div className="ml-4 text-left">
                      <p
                        className={`text-sm ${
                          selected
                            ? 'text-gray-200'
                            : item.readed
                            ? 'text-gray-300'
                            : 'text-gray-800'
                        } font-bold`}
                      >
                        {users.find((u) => u.id === item.senderId)?.name ||
                          'N/D'}
                      </p>
                      <p
                        className={`text-sm ${
                          selected
                            ? 'text-gray-200'
                            : item.readed
                            ? 'text-gray-300'
                            : 'text-gray-800'
                        }`}
                      >
                        {getFormatedDateTime(String(item.dateTime))}
                      </p>
                    </div>
                  </button>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className={`w-2/3 bg-yellow-100`}>
            {messages.length === 0 && (
              <div className="flex flex-col justify-center items-center h-full">
                Sem Mensagens
              </div>
            )}
            {messages.map((item) => (
              <Tab.Panel
                key={item._id}
                className="flex flex-col justify-between"
                style={{ height: '74.7vh' }}
              >
                <div className="overflow-hidden overflow-y-auto w-full p-1">
                  {item.message}
                </div>
                <div className="w-full bg-gray-100 flex justify-between items-center py-4 px-2">
                  {item.readed ? (
                    <button
                      onClick={() => item._id && unreaderMessage(item._id)}
                      className="rounded btn btn-xs bg-yellow-500 flex space-x-2 items-center"
                    >
                      <MailIcon className="w-4 h-4" />{' '}
                      <span>Marcar como não lido</span>
                    </button>
                  ) : (
                    <button
                      onClick={() => item._id && readerMessage(item._id)}
                      className="rounded btn btn-xs bg-blue-500 flex space-x-2 items-center"
                    >
                      <MailOpenIcon className="w-4 h-4" />{' '}
                      <span>Marcar como lido</span>
                    </button>
                  )}
                  <ButtonConfirmBasic
                    type="button"
                    onClick={() => item._id && deleteMessage(item._id)}
                    className="rounded btn btn-xs btn-error flex space-x-2 items-center"
                  >
                    <TrashIcon className="w-4 h-4" />{' '}
                    <span>Excluir Recado</span>
                  </ButtonConfirmBasic>
                </div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
      <ReactModal
        isOpen={writeModalIsOpen}
        onRequestClose={closeWriteModal}
        style={customStyles}
        ariaHideApp={false}
        overlayClassName="z-50"
      >
        <div style={{ width: '50vw' }}>
          <div className="flex justify-between flex-row items-center border-b-2 border-solid">
            <h1 className="text-sm">Escrever Recado</h1>
            <button onClick={closeWriteModal}>
              <XIcon className="h-4 w-4 my-3 text-gray-300 hover:text-gray-600" />
            </button>
          </div>

          <form className="flex flex-col space-y-3">
            {/* user */}
            <div className="form-control">
              <label className="label">
                <span className="label-text">Para:</span>
              </label>
              <select
                {...registerWriteMessage('sender', { required: true })}
                className="select select-bordered w-full maxW-xs"
              >
                <option value="">Selecione ...</option>
                {users.map((opt) => (
                  <option key={opt.id} value={opt.id}>
                    {opt.name}
                  </option>
                ))}
              </select>
              <span className="text-xs text-red-500">
                {errorsWriteMessage.sender && 'Campo requerido'}
              </span>
            </div>
            {/* objeto */}
            <div className="form-control">
              <label className="label">
                <span className="label-text">Recado:</span>
              </label>
              <textarea
                className="input input-bordered h-28"
                {...registerWriteMessage('message', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errorsWriteMessage.message && 'Campo requerido'}
              </span>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={handlerSubmitWriteMessage(submitMessage)}
                className="rounded btn btn-xs btn-gray-500 flex space-x-2 items-center"
              >
                <ChatIcon className="w-4 h-4" /> <span>Enviar Recado</span>
              </button>
            </div>
          </form>
        </div>
      </ReactModal>
    </div>
  );
};

export default Talk;
