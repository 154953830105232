/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { getAuthUser, validateToken } from '../../services/app.service';
import { relatAppoinments } from '../appointments/appointments.service';
import { Appointment } from '../appointments/appointments.model';
import { list as listProfessionals } from '../professionals/professionals.service';
import { Professional } from '../professionals/professionals.model';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { arrayGroupBy, typeStatus } from '../../utils/app.utils';
import Loading from '../../components/Loading';
import { getFormatedDate } from '../../utils/datetimeHandler';
import { getTypePgto, parseCurrency } from '../../utils/app.utils';

import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import pdfFonts from 'pdfmake/build/vfs_fonts';
// @ts-ignore: Unreachable code error
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AppRelatAppointmens: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Appointment[]>([]);
  const [summary, setSummary] = useState<Record<string, number>>();
  const [summaryCount, setSummaryCount] = useState<any[]>([]);
  const [showSummary, setShowSummary] = useState(0);
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const { handleSubmit, register, control, getValues } = useForm();
  const user = getAuthUser();

  const init = async () => {
    validateToken();
    listProfessionals().then((data) => setProfessionals(data));
  };

  useEffect(() => {
    init();
  }, []);

  const newSummary = {
    value: 0,
    commission: 0,
    v_convenant: 0,
    c_convenant: 0,
    v_cash: 0,
    c_cash: 0,
    v_creditcard: 0,
    v_debitcard: 0,
    c_creditcard: 0,
    c_debitcard: 0,
    v_pix: 0,
    c_pix: 0,
    v_transfer: 0,
    c_transfer: 0,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchDateProfessional = (data: any) => {
    setLoading(true);
    data.status = data.status.reduce((a: any[], c: any) => {
      a.push(c.value);
      return a;
    }, []);
    data.professional = data.professional.reduce((a: any[], c: any) => {
      a.push(c.value);
      return a;
    }, []);

    // console.log(data);

    if (!data.professional) {
      setLoading(false);
      setData([]);
      return;
    }

    setSummary(newSummary);

    relatAppoinments(data).then((ret) => {
      setData(ret);

      const countProcedures = arrayGroupBy(ret, 'procedure.name');

      // console.log('countProcedures', countProcedures);
      // setSummaryCount(countProcedures);
      setSummaryCount(Object.entries(countProcedures));

      ret.forEach((el) => {
        newSummary.value += el.value;
        newSummary.commission += el.commission;

        switch (el.typePgto) {
          case 'convenant':
            newSummary.v_convenant += el.value;
            newSummary.c_convenant += el.commission;
            break;
          case 'cash':
            newSummary.v_cash += el.value;
            newSummary.c_cash += el.commission;
            break;
          case 'creditcard':
            newSummary.v_creditcard += el.value;
            newSummary.c_creditcard += el.commission;
            break;
          case 'debitcard':
            newSummary.v_debitcard += el.value;
            newSummary.c_debitcard += el.commission;
            break;
          case 'pix':
            newSummary.v_pix += el.value;
            newSummary.c_pix += el.commission;
            break;
          case 'transfer':
            newSummary.v_transfer += el.value;
            newSummary.c_transfer += el.commission;
            break;
        }

        setSummary(newSummary);
      });
      setLoading(false);
    });
  };

  const generatePdf = () => {
    // @ts-ignore: Unreachable code error
    pdfMake.fonts = {
      Roboto: {
        normal:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
    };

    const body = [];
    body.push([
      'Data',
      'Ord',
      'Status',
      'Profissional',
      'Procedimento',
      'Paciente',
      'Celular',
      '(+)',
      '(-)',
      'Valor',
      'Comissão',
      'Pgto',
    ]);
    data.forEach((el) => {
      body.push([
        getFormatedDate(String(el.date)) || '',
        el.order,
        [{ text: el.status || '', alignment: 'center' }],
        el.professional.name || '',
        el.procedure.name || '',
        el.patient?.name || 'Não agendado',
        el.patient?.cel || '',
        [{ text: parseCurrency(el.addition) || '0', alignment: 'right' }],
        [{ text: parseCurrency(el.discount) || '0', alignment: 'right' }],
        [{ text: parseCurrency(el.value) || '0', alignment: 'right' }],
        [{ text: parseCurrency(el.commission) || '0', alignment: 'right' }],
        getTypePgto(el.typePgto),
      ]);
    });
    const dd: TDocumentDefinitions = {
      defaultStyle: {
        font: 'Roboto',
      },

      pageSize: 'A4',

      pageOrientation: 'landscape',

      pageMargins: [40, 60, 40, 60],
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            text: 'Relatório de Atendimentos',
            alignment: currentPage % 2 ? 'left' : 'right',
            style: 'header',
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 30,
                y1: 0,
                x2: pageSize.width - 30,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
        ];
      },
      content: [
        {
          table: {
            layout: 'lightHorizontalLines',
            widths: [50, 15, 50, '*', '*', '*', 70, 40, 40, 40, 40, 60],
            headerRows: 1,
            body,
          },
          layout: {
            fillColor: function (rowIndex) {
              return rowIndex === 0 ? '#CCCCCC' : null;
            },
          },
          style: {
            fontSize: 8,
          },
        },
        { text: '\n' },
        {
          text: showSummary
            ? [
                `SUMÁRIO: Valor: ${parseCurrency(
                  summary?.value
                )} - Comissão: ${parseCurrency(summary?.commission)} \n`,

                `Valor - Convênio: ${parseCurrency(
                  summary?.v_convenant
                )}, Dinheiro: ${parseCurrency(
                  summary?.v_cash
                )}, Cartão Crédito: ${parseCurrency(
                  summary?.v_creditcard
                )}, Cartão Débito: ${parseCurrency(summary?.v_debitcard)},
                PIX: ${parseCurrency(
                  summary?.v_pix
                )}, Transferência: ${parseCurrency(summary?.v_transfer)}\n
                 `,

                // `Comissão - Convênio: ${parseCurrency(
                //   summary?.c_convenant
                // )}, Dinheiro: ${parseCurrency(
                //   summary?.c_cash
                // )}, Cartão Crédito: ${parseCurrency(
                //   summary?.c_creditcart
                // )}, Cartão Débito: ${parseCurrency(
                //   summary?.c_debitcart
                // )},PIX: ${parseCurrency(
                //   summary?.c_pix
                // )}, Transferência: ${parseCurrency(summary?.v_transfer)}\n`,
              ]
            : '',
          style: {
            fontSize: 10,
          },
        },
        summaryCount.length > 0 ? { text: 'Procedimentos:', fontSize: 10 } : '',

        (summaryCount.length > 0 &&
          summaryCount.map((el) => ({
            text: `${el[0] || 'N/D'}: ${el[1].length || '0'}`,
            style: {
              fontSize: 8,
            },
          }))) ||
          'N/D',
      ],

      footer: function (currentPage, pageCount, pageSize) {
        return [
          {
            canvas: [
              {
                type: 'line',
                x1: 30,
                y1: 0,
                x2: pageSize.width - 30,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
          {
            stack: [
              {
                text: 'Pag. '.concat(
                  currentPage.toString(),
                  ' de ',
                  pageCount.toString(),
                  ` - ${new Date().getDate()}/${
                    new Date().getMonth() + 1
                  }/${new Date().getFullYear()}`
                ),
                margin: [30, 0, 30, 0],
                fontSize: 8,
              },
              {
                text: `Período de ${getFormatedDate(
                  getValues('dtIni')
                )} à ${getFormatedDate(getValues('dtEnd'))}`,
                margin: [30, 0, 30, 0],
                fontSize: 8,
              },
              {
                text: `Filtros: ${getValues('status')
                  .reduce((acc: string[], cur: any) => {
                    acc.push(cur.value);
                    return acc;
                  }, [])
                  .toString()}`,
                margin: [30, 0, 30, 0],
                fontSize: 8,
              },
              {
                text: `Atendente: ${user?.name}`,
                margin: [30, 0, 30, 0],
                fontSize: 8,
              },
            ],
          },
        ];
      },

      styles: {
        header: {
          fontSize: 12,
          bold: true,
          margin: [30, 30, 30, 0],
        },
      },
    };
    const doc = pdfMake.createPdf(dd);

    // doc.download();
    doc.open();
    // doc.getBase64((data) => {
    //   self.location.href = 'data:application/pdf;base64,' + data;
    // });
  };

  // console.log('summaryCount', summaryCount);

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div style={{ width: '90%', position: 'absolute' }}>
        <div className="text-sm breadcrumbs relative">
          <ul>
            <li>Relatorio de Atendimentos</li>
          </ul>
        </div>
        <div className="my-5 w-full relative z-10">
          <form onSubmit={handleSubmit(searchDateProfessional)}>
            <div className=" w-full form-control flex flex-row justify-start">
              <div className="mr-3">
                <div className="flex justify-between">
                  <label className="w-20 bg-gray-300 text-white py-1 pl-3">
                    Início
                  </label>
                  <input
                    type="date"
                    placeholder="Data"
                    className="w-42 rounded-none input input-gray-500 bg-gray-100 input-sm"
                    {...register('dtIni', { required: true })}
                  />
                </div>
                <div className="flex justify-between">
                  <label className="w-20 bg-gray-300 text-white py-1 pl-3">
                    Final
                  </label>
                  <input
                    type="date"
                    placeholder="Data"
                    className="w-42 rounded-none input input-gray-500 bg-gray-100 input-sm"
                    {...register('dtEnd', { required: true })}
                  />
                </div>
              </div>
              <div className="w-full flex">
                <Controller
                  render={({ field }) => (
                    <Select
                      className="w-80"
                      {...field}
                      isMulti
                      isClearable
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      openMenuOnFocus={false}
                      isSearchable={false}
                      autoFocus={false}
                      openMenuOnClick={false}
                      hideSelectedOptions={true}
                      placeholder={`Selecione um profissional`}
                      options={professionals.map((el) => ({
                        label: el.name,
                        value: el.id,
                      }))}
                    />
                  )}
                  name="professional"
                  control={control}
                  defaultValue={[]}
                />
                <Controller
                  render={({ field }) => (
                    <Select
                      className="w-80"
                      {...field}
                      isMulti
                      isClearable
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      openMenuOnFocus={false}
                      isSearchable={false}
                      autoFocus={false}
                      openMenuOnClick={false}
                      hideSelectedOptions={true}
                      placeholder={`Selecione um Status`}
                      options={typeStatus.map((el) => ({
                        label: el,
                        value: el,
                      }))}
                    />
                  )}
                  name="status"
                  control={control}
                  defaultValue={[]}
                />
              </div>
              <div className="form-control w-full">
                <label className="cursor-pointer label">
                  <span className="label-text">Exibir Sumário</span>
                  <input
                    type="checkbox"
                    value={1}
                    onChange={(e) => setShowSummary(e.target.checked ? 1 : 0)}
                    className="toggle toggle-primary"
                  />
                </label>
              </div>
              <button type="submit" className="my-2 btn btn-sm btn-gray-500">
                Buscar
              </button>
            </div>
          </form>
        </div>
        <div className="divider opacity-25"></div>
        <table className="table-compact w-full z-0">
          <thead>
            <tr className="bg-gray-100">
              <th className="text-left">Dia</th>
              <th className="text-center">Ord</th>
              <th className="text-left">STATUS</th>
              <th className="text-left">Profissional</th>
              <th className="text-left">Procedimento</th>
              <th className="text-left">Paciente</th>
              <th className="text-left">Celular</th>
              <th className="text-right">(+)</th>
              <th className="text-right">(-)</th>
              <th className="text-right">Valor</th>
              <th className="text-right">Comissão</th>
              <th className="text-left">Tipo Pgto</th>
            </tr>
          </thead>
          {loading && (
            <tbody>
              <tr>
                <td colSpan={9}>
                  <Loading />
                </td>
              </tr>
            </tbody>
          )}
          {!loading && (
            <tbody>
              {data.map((el, index) => (
                <tr key={index}>
                  <td className="text-left">
                    {getFormatedDate(String(el.date))}
                  </td>
                  <th className="text-center">{el.order}</th>
                  <td className="text-left">{el.status}</td>
                  <td className="text-left">{el.professional.name}</td>
                  <td className="text-left">{el.procedure.name}</td>
                  <td className="text-left">
                    {el.patient?.name || 'Não agendado'}
                  </td>
                  <td className="text-left">{el.patient?.cel || ''}</td>
                  <td className="text-right">{parseCurrency(el.addition)}</td>
                  <td className="text-right">{parseCurrency(el.discount)}</td>
                  <td className="text-right">{parseCurrency(el.value)}</td>
                  <td className="text-right">{parseCurrency(el.commission)}</td>
                  <td className="text-left">{getTypePgto(el.typePgto)}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>

        {showSummary === 1 && (
          <div>
            <div className="bg-gray-100 p-1">
              <span className="mr-3">
                <strong>Sumário:</strong>
              </span>
              <span className="mr-3">
                Valor: <strong>{parseCurrency(summary?.value)}</strong>
              </span>
              <span className="text-lg mr-3">
                Comissão: <strong>{parseCurrency(summary?.commission)}</strong>
              </span>
            </div>
            <div className="flex items-center">
              <span className="text-xs mr-2 text-gray-400">Valor:</span>
              <span className="text-xs mr-2 text-gray-400">
                Convênio: <strong>{parseCurrency(summary?.v_convenant)}</strong>
              </span>
              <span className="text-xs mr-2 text-gray-400">
                Dinheiro: <strong>{parseCurrency(summary?.v_cash)}</strong>
              </span>
              <span className="text-xs mr-2 text-gray-400">
                Cartão Crédito:{' '}
                <strong>{parseCurrency(summary?.v_creditcard)}</strong>
              </span>
              <span className="text-xs mr-2 text-gray-400">
                Cartão Débito:{' '}
                <strong>{parseCurrency(summary?.v_debitcard)}</strong>
              </span>
              <span className="text-xs mr-2 text-gray-400">
                PIX: <strong>{parseCurrency(summary?.v_pix)}</strong>
              </span>
              <span className="text-xs mr-2 text-gray-400">
                Transferência:{' '}
                <strong>{parseCurrency(summary?.v_transfer)}</strong>
              </span>
            </div>
            {/* <div className="flex items-center">
              <span className="text-xs mr-2 text-gray-400">Comissao:</span>
              <span className="text-xs mr-2 text-gray-400">
                Convênio: <strong>{parseCurrency(summary?.c_convenant)}</strong>
              </span>
              <span className="text-xs mr-2 text-gray-400">
                Dinheiro: <strong>{parseCurrency(summary?.c_cash)}</strong>
              </span>
              <span className="text-xs mr-2 text-gray-400">
                Cartão: <strong>{parseCurrency(summary?.c_card)}</strong>
              </span>
              <span className="text-xs mr-2 text-gray-400">
                PIX: <strong>{parseCurrency(summary?.c_pix)}</strong>
              </span>
              <span className="text-xs mr-2 text-gray-400">
                Transferência:{' '}
                <strong>{parseCurrency(summary?.c_transfer)}</strong>
              </span>
            </div> */}
          </div>
        )}
        {summaryCount.length > 0 && (
          <div className="text-2xs">
            <div className="font-bold">Procedimentos:</div>
            <ul className="">
              {summaryCount.map((el: any, idx: number) => (
                <div key={idx} className="flex text-gray-900">
                  <span className="mr-3">{el[0] || 'N/D'}:</span>
                  <span className="mr-3">{el[1].length || 'N/D'}</span>
                </div>
              ))}
            </ul>
          </div>
        )}
        {data.length > 0 && (
          <button
            type="button"
            className="my-2 btn btn-sm bg-blue-300 border-blue-300"
            onClick={generatePdf}
          >
            Imprimir
          </button>
        )}
      </div>
    </div>
  );
};

export default AppRelatAppointmens;
