import { Popover, Transition } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/outline';
import React, { Fragment, useEffect, useState } from 'react';

interface Props {
  items: React.ReactNodeArray;
  // onAction: () => void;
}

export default function ActionBar({ items }: Props): JSX.Element {
  const [columns, setColumns] = useState<string>('grid-cols-1');
  const [maxSize, setMaxSize] = useState<string>('max-w-sm');

  useEffect(() => {
    if (items.length > 6) {
      setColumns('grid-cols-3');
      setMaxSize('max-w-lg');
    }

    if (items.length > 3) {
      setColumns('grid-cols-2');
      setMaxSize('max-w-md');
    }

    if (items.length <= 3) {
      setColumns('grid-cols-1');
      setMaxSize('max-w-sm');
    }
  }, []);

  return (
    <div className="w-full max-w-sm px-4">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${
                  open
                    ? 'bg-gray-600 text-white'
                    : 'text-opacity-90 text-primary bg-white hover:bg-gray-600'
                }
                group inline-flex items-center rounded-full bg-orange-700 px-2 py-2 text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <DotsHorizontalIcon className="w-5 h-5" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={`absolute right-0 z-10 mt-3 w-screen ${maxSize} px-4`}>
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div
                    onClick={() => close()}
                    className={`relative grid gap-8 ${columns} bg-white p-7`}
                  >
                    {items.map((item) => item)}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
