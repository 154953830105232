/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useConfirmationAppointment } from '../../../hooks/appointmentsConfirmation.hook';
import history from '../../../history';
import { useForm } from 'react-hook-form';
import { round, typesPgtos } from '../../../utils/app.utils';

type AnalizeAppointmentConfirmationProps = {
  setValues: (values: any) => void;
};

export const AnalizeAppointmentConfirmation: React.FC<AnalizeAppointmentConfirmationProps> =
  ({ setValues }: AnalizeAppointmentConfirmationProps) => {
    const hookData = useConfirmationAppointment();
    // const [loading, setLoading] = useState(false);

    // const [submiting, setSubmiting] = useState<boolean>(false);
    // const [error, setError] = useState<boolean>(false);
    // const [errorMsg, setErrorMsg] = useState<[]>([]);
    const {
      handleSubmit,
      register,
      formState: { errors },
    } = useForm();

    useEffect(() => {
      setValues({
        ...hookData,
        appointment: {
          ...hookData.appointment,
          value: hookData.convenant?.value,
          commission: hookData.convenant?.commission,
        },
      });
    }, []);

    const onSubmit = (data: any) => {
      if (hookData.convenant) {
        const commis =
          (hookData.convenant?.commission / 100) * hookData.convenant?.value;
        setValues({
          ...hookData,
          typePgto: data.typePgto,
          appointment: {
            ...hookData.appointment,
            discount: parseFloat(data.discount.replace(',', '.')),
            addition: parseFloat(data.addition.replace(',', '.')),
            value: hookData.convenant?.value,
            commission: round(commis, 2),
          },
        });
        history.push('/appointments-confirmation/finist');
      }
    };

    // console.log(hookData);
    // setLoading(false);

    return (
      <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <a onClick={() => history.push('/appointments')}>Atendimentos</a>
            </li>
            <li>Confirmar Atendimento</li>
          </ul>
        </div>
        <ul className="w-full steps my-6">
          <li className="step step-primary">Dados</li>
          <li className="step step-primary">Análise</li>
          <li className="step step">Finalizar</li>
        </ul>

        <div>
          <div className="flex justify-between bg-gray-100 p-1">
            <strong>Convênio:</strong>
            <span>{hookData.convenant?.name}</span>
          </div>
          <div className="flex justify-between bg-gray-200 p-1">
            <strong>Valor:</strong>
            <span>
              {Number(hookData.appointment?.value).toFixed(2).replace('.', ',')}
            </span>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="mb-3 w-full">
            <div className="flex justify-start">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Forma de Pagamento</span>
                </label>
                <select
                  {...register('typePgto', { required: true })}
                  className="select select-bordered w-full max-w-xs"
                >
                  <option value="">Selecione forma de pagamento</option>
                  {typesPgtos.map((el: any) => {
                    return (
                      <option key={el.key} value={el.key}>
                        {el.value}
                      </option>
                    );
                  })}
                </select>
                <span className="text-xs text-red-500">
                  {errors.typePgto && 'Campo requerido'}
                </span>
              </div>
              <div className="flex flex-row justify-between">
                {/* discount */}
                <div className="form-control mx-1">
                  <label className="label">
                    <span className="label-text">Desconto (R$)</span>
                  </label>
                  <input
                    {...register('discount', { required: true })}
                    defaultValue={0}
                    className="input input-bordered w-full max-w-xs"
                  />
                  <span className="text-xs text-red-500">
                    {errors.discount && 'Campo requerido'}
                  </span>
                </div>
                {/* addition */}
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Acréscimo (R$)</span>
                  </label>
                  <input
                    {...register('addition', { required: true })}
                    defaultValue={0}
                    className="input input-bordered w-full max-w-xs"
                  />
                  <span className="text-xs text-red-500">
                    {errors.addition && 'Campo requerido'}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-end my-4">
              <button type="submit" className="bt btn-sm btn-primary rounded">
                Próximo
              </button>
            </div>
          </form>
        </div>

        <div className="flex justify-between my-4">
          <button
            type="button"
            className="bt btn-sm btn-active text-white rounded"
            onClick={() => history.push('/appointments-confirmation')}
          >
            Voltar
          </button>
        </div>
      </div>
    );
  };
