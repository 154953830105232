/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import AppNavbar from '../components/AppNavbar';
import AppCadUsers from './users/AppCadUsers';
import AppDashboard from './dashboard/AppDashboard';
import AppAppointment from './appointments';
import history from '../history';
import AppCadUsersForm from './users/AppCadUsersForm';
import AppProfile from './profile/AppProfile';
import AppCadSpecialties from './specialties/AppCadSpecialties';
import AppCadSpecialtiesForm from './specialties/AppCadSpecialtiesForm';
import AppCadProfessionals from './professionals/AppCadProfessionals';
import AppCadProfessionalsForm from './professionals/AppCadProfessionalsForm';
import AppInstitutional from './institutional';
import AppCadConvenants from './covenants/AppCadConvenants';
import AppCadConvenantsForm from './covenants/AppCadConvenantsForm';
import AppCadProcedures from './procedures/AppCadProcedures';
import AppCadProceduresForm from './procedures/AppCadProceduresForm';
import AppCadPatients from './patients/AppCadPatients';
import AppCadPatientsForm from './patients/AppCadPatientsForm';
import AppAppointmentsMake from './appointments/AppAppointmentsMake';
import AppAppointmentsScheduling from './appointments/AppAppointmentsScheduling';
import AppAppointmentsConfirmation from './appointments/AppAppointmentsConfirmation';
import AppAppointmentsCancelations from './appointments/AppAppointmensCancelations';
import AppAppointmensSchendulingConfirmation from './appointments/AppAppointmensSchendulingConfirmation';
import AppCadWaitingLists from './waitingList/AppWaitingList';
import WaitingListAdd from './waitingList/WaitingListAdd';
import WaitingListSchenduling from './waitingList/WaitingListSchenduling';
import AppWaitingListsRemove from './waitingList/AppWaitingListRemove';
import AppRelatAppointmens from './relats/AppRelatAppointmens';
import AppCadRecommendations from './recommendations/AppCadRecommendations';
import AppCadRecommendationsForm from './recommendations/AppCadRecommendationsForm';
import AppCadBanners from './banners/AppCadBanners';
import AppCadBannersForm from './banners/AppCadBannersForm';
import AppRelatAppointmensProfessinalsGroup from './relats/AppRelatAppointmensProfessionalsGroup';
import AppRelatProfessionalsProcedures from './relats/AppRelatProfessionalsProcedures';
import AppRelatPatientAppointments from './relats/AppRelatPatientAppointments';
import AppAppointmensDelete from './appointments/AppAppointmensDelete';
import AppRelatProfessionalsWaitingList from './relats/AppRelatProfessionalsWaitingList';
import AppRelatAppoitmentsProfessional from './relats/AppRelatAppoitmentsProfessional';
import AppAppointmentsMakeLote from './appointments/AppAppointmentsMakeLote';
import AppAppointmensTransfer from './appointments/AppAppointmensTransfer';
import AppAppointmensReorder from './appointments/AppAppointmensReorder';
import { SocketProvider } from '../hooks/socket.context';
import Talk from './talks';
import Chats from './whatsapp';
import AppCadPresetChats from './presetChats/AppCadPresetChats';
import AppCadPresetChatsForm from './presetChats/AppCadPresetChatsForm';
import AppRelatProtocols from './relats/AppRelatProtocol';
import AppRecord from './records';
import { AppStepMakeRecord } from './records/StepMakeRecord';
import AppAppointmentsMap from './appointments/AppAppointmentsMap';
import AppRelatAppointmensProfessionalsCalcGroup from './relats/AppRelatAppointmensProfessionalsCalcGroup';
import AppRelatProtocolsUser from './relats/AppRelatProtocolUser';
import AppRelatAvalicaoPresencial from './relats/AppRelatAvalicaoPresencial';
import Atendimento from './atendimento';
import AppAppointmensWhatsappConfirmConfirm from './appointments/AppAppointmensWhatsappConfir';

const App: React.FC = () => {
  return (
    <SocketProvider>
      <div className={`overflow-hidden overflow-y-auto w-full`}>
        <Router history={history}>
          <AppNavbar />
          <Switch>
            {/* users */}
            <Route path="/cad-users">
              <AppCadUsers />
            </Route>
            <Route path="/cad-users-form">
              <AppCadUsersForm />
            </Route>
            {/* banners */}
            <Route path="/site-banners">
              <AppCadBanners />
            </Route>
            <Route path="/site-banners-form">
              <AppCadBannersForm />
            </Route>
            {/* specialties */}
            <Route path="/cad-specialties">
              <AppCadSpecialties />
            </Route>
            <Route path="/cad-specialties-form">
              <AppCadSpecialtiesForm />
            </Route>
            {/* professionals */}
            <Route path="/cad-professionals">
              <AppCadProfessionals />
            </Route>
            <Route path="/cad-professionals-form">
              <AppCadProfessionalsForm />
            </Route>
            {/* covenants */}
            <Route path="/cad-covenants">
              <AppCadConvenants />
            </Route>
            <Route path="/cad-covenants-form">
              <AppCadConvenantsForm />
            </Route>
            {/* recommendations */}
            <Route path="/cad-recommendations">
              <AppCadRecommendations />
            </Route>
            <Route path="/cad-recommendations-form">
              <AppCadRecommendationsForm />
            </Route>
            {/* procedures */}
            <Route path="/cad-procedures">
              <AppCadProcedures />
            </Route>
            <Route path="/cad-procedures-form">
              <AppCadProceduresForm />
            </Route>
            {/* patients */}
            <Route path="/patients">
              <AppCadPatients />
            </Route>
            <Route path="/patients-form">
              <AppCadPatientsForm />
            </Route>
            {/* profile */}
            <Route path="/profile">
              <AppProfile />
            </Route>
            {/* record */}
            <Route path="/record">
              <AppRecord />
            </Route>
            <Route path="/step-make-record">
              <AppStepMakeRecord />
            </Route>
            {/* institutional */}
            <Route path="/cad-institutional">
              <AppInstitutional />
            </Route>
            {/* appointments */}
            <Route path="/appointments">
              <AppAppointment />
            </Route>
            <Route path="/appointments-make">
              <AppAppointmentsMake />
            </Route>
            <Route path="/appointments-make-lote">
              <AppAppointmentsMakeLote />
            </Route>
            <Route path="/appointments-scheduling">
              <AppAppointmentsScheduling />
            </Route>
            <Route path="/appointments-confirmation">
              <AppAppointmentsConfirmation />
            </Route>
            <Route path="/appointments-cancelation">
              <AppAppointmentsCancelations />
            </Route>
            <Route path="/appointments-delete">
              <AppAppointmensDelete />
            </Route>
            <Route path="/appointments-transfer">
              <AppAppointmensTransfer />
            </Route>
             <Route path="/appointments-whatsapp-confirm">
              <AppAppointmensWhatsappConfirmConfirm />
            </Route>
            <Route path="/appointments-reorder">
              <AppAppointmensReorder />
            </Route>
            <Route path="/appointments-schenduling-confirmation">
              <AppAppointmensSchendulingConfirmation />
            </Route>
            <Route path="/appointments-map">
              <AppAppointmentsMap />
            </Route>
            {/* waiting list */}
            <Route path="/waiting-list">
              <AppCadWaitingLists />
            </Route>
            <Route path="/waiting-list-add">
              <WaitingListAdd />
            </Route>
            <Route path="/waiting-list-scheduling">
              <WaitingListSchenduling />
            </Route>
            <Route path="/waiting-list-remove">
              <AppWaitingListsRemove />
            </Route>
            {/* relats */}
            <Route path="/relat-appointments">
              <AppRelatAppointmens />
            </Route>
            <Route path="/relat-protocols">
              <AppRelatProtocols />
            </Route>
            <Route path="/relat-appointments-professionals-group">
              <AppRelatAppointmensProfessinalsGroup />
            </Route>
            <Route path="/relat-appointments-professionals-calc">
              <AppRelatAppointmensProfessionalsCalcGroup />
            </Route>
            <Route path="/relat-professionals-procedures">
              <AppRelatProfessionalsProcedures />
            </Route>
            <Route path="/relat-patient-appointments">
              <AppRelatPatientAppointments />
            </Route>
            <Route path="/relat-professional-waitingList">
              <AppRelatProfessionalsWaitingList />
            </Route>
            <Route path="/relat-appointments-professional">
              <AppRelatAppoitmentsProfessional />
            </Route>
            <Route path="/relat-analise-whatsapp">
              <AppRelatProtocolsUser />
            </Route>
            <Route path="/relat-avaliacao-presencial">
              <AppRelatAvalicaoPresencial />
            </Route>
            {/* Services */}
            <Route path="/talks">
              <Talk />
            </Route>
            <Route path="/whatsapp">
              <Atendimento />
              {/* <Chats /> */}
            </Route>
            <Route path="/cad-presetChats">
              <AppCadPresetChats />
            </Route>
            <Route path="/cad-presetChats-form">
              <AppCadPresetChatsForm />
            </Route>
            {/* dashboard */}
            <Route path="/">
              <AppDashboard />
            </Route>
          </Switch>
        </Router>
      </div>
    </SocketProvider>
  );
};

export default App;
