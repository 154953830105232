import { createContext, useContext } from 'react';

export interface IWaitingListAddContext {
  cel: string | null;
  patient: string | null;
  professional: string | null;
}

const WaitingListAddContext = createContext<IWaitingListAddContext>(
  {} as IWaitingListAddContext
);

const useWaitingListAdd = (): IWaitingListAddContext => {
  const context = useContext(WaitingListAddContext);

  return context;
};

export { WaitingListAddContext, useWaitingListAdd };
