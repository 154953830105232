/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import history from '../../../history';
import { Controller, useForm } from 'react-hook-form';
import { validateToken } from '../../../services/app.service';
import { useWaitingListAdd } from '../../../hooks/waitingListAdd.hook';
import MaskedInput from 'react-text-mask';
import getMask from '../../../utils/masks';

type InitialWaitingListAddProps = {
  setValues: (values: any) => void;
};

export const InitialWaitingListAdd: React.FC<InitialWaitingListAddProps> = ({
  setValues,
}: InitialWaitingListAddProps) => {
  const hookData = useWaitingListAdd();

  const init = async () => {
    validateToken();
    setValue('cel', hookData.cel);
  };

  useEffect(() => {
    init();
  }, []);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const next = (data: any) => {
    console.log(data);
    setValues(data);
    history.push('/waiting-list-add/analize');
  };

  return (
    <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/waiting-list')}>
              Lista de Espera
            </a>
          </li>
          <li>Adicionar na Lista de Espera</li>
        </ul>
      </div>
      <ul className="w-full steps my-6">
        <li className="step step-primary">Dados</li>
        <li className="step step">Análise</li>
        <li className="step step">Finalizar</li>
      </ul>
      <form onSubmit={handleSubmit(next)} className="mb-3 w-full">
        {/* cel */}
        <div className="form-control">
              <label className="label">
                <span className="label-text">Celular</span>
              </label>
              <Controller
                name="cel"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <MaskedInput
                    mask={getMask('celular')}
                    type="text"
                    placeholder="Celular"
                    className="input input-bordered"
                    {...field}
                  />
                )}
              />
              <span className="text-xs text-red-500">
                {errors.cel && 'Campo requerido'}
              </span>
            </div>
        <div className="flex justify-end my-4">
          <button type="submit" className="bt btn-sm btn-primary rounded">
            Próximo
          </button>
        </div>
      </form>
    </div>
  );
};
