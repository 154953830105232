/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { validateToken } from '../../services/app.service';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Loading from '../../components/Loading';

import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Professional } from '../professionals/professionals.model';
import { list as listProfessionals } from '../professionals/professionals.service';
import { Appointment } from '../appointments/appointments.model';
import { arrayGroupBy, typeStatus } from '../../utils/app.utils';
import { relatAppoinments } from '../appointments/appointments.service';
import { addTime, getFormatedDate } from '../../utils/datetimeHandler';

// @ts-ignore: Unreachable code error
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AppRelatAppoitmentsProfessional: React.FC = () => {
  const relatTitle = 'Relatório de Atendimentos por Profissional';
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Appointment[]>([]);
  const [dataSummary, setDataSummary] = useState<any[]>([]);
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const { handleSubmit, register, getValues, control } = useForm();
  const [period, setPeriod] = useState('');

  const init = async () => {
    listProfessionals().then((data) => setProfessionals(data));
    validateToken();
  };

  useEffect(() => {
    init();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const findAppointmentsProfessional = async (data: any) => {
    setLoading(true);
    data.status = data.status.reduce((a: any[], c: any) => {
      a.push(c.value);
      return a;
    }, []);
    const prof = [];

    prof.push(data.professional);

    data.professional = prof;

    data.dtIni = data.date;
    data.dtEnd = data.date;

    setPeriod(getFormatedDate(data.date));

    delete data.date;

    relatAppoinments(data).then((ret) => {
      setData(ret);
      const group = arrayGroupBy(ret, 'procedure.name');
      setDataSummary(Object.entries(group));
    });
    setLoading(false);
  };

  const generatePdf = () => {
    // @ts-ignore: Unreachable code error
    pdfMake.fonts = {
      Roboto: {
        normal:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
    };

    const dd: TDocumentDefinitions = {
      defaultStyle: {
        font: 'Roboto',
      },

      pageSize: 'A4',

      pageOrientation: 'landscape',

      pageMargins: [40, 60, 40, 60],
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            text: relatTitle,
            alignment: currentPage % 2 ? 'left' : 'right',
            style: 'header',
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 30,
                y1: 0,
                x2: pageSize.width - 30,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
        ];
      },

      content: [
        {
          table: {
            layout: 'lightHorizontalLines',
            widths: [
              'auto',
              'auto',
              'auto',
              'auto',
              '*',
              70,
              'auto',
              'auto',
              110,
              150,
            ],
            headerRows: 1,
            body: data.reduce(
              (acc: any[], a: Appointment) => {
                const row = [
                  a.order || 'N/D',
                  a.status,
                  a.procedure
                    ? addTime(
                        a.appointmentBegin,
                        a.procedure.estimatedTime.toString(),
                        a.order - 1
                      )
                    : 'N/D',
                  a.procedure?.name || 'N/D',
                  a.patient?.name || 'N/D',
                  a.patient?.cel || 'N/D',
                  getFormatedDate(a.patient?.dn),
                  a.patient?.document || 'N/D',
                  a.patient?.email || 'N/D',
                  ''.concat(
                    a.patient?.address || 'N',
                    '/',
                    a.patient?.city || 'D'
                  ),
                ];
                acc.push(row);
                return acc;
              },
              [
                [
                  'Ord',
                  'Status',
                  'Horário',
                  'Procedimento',
                  'Paciente',
                  'Celular',
                  'DN',
                  'Documento',
                  'E-mail',
                  'Endereço',
                ],
              ]
            ),
          },
          style: {
            fontSize: 7,
          },
        },
        '\n',
        {
          text: 'Sumário:',
          style: {
            fontSize: 8,
            bold: true,
          },
        },
        dataSummary.map((el) => ({
          text: `${el[0] || 'N/D'}: ${el[1].length || '0'}`,
          style: {
            fontSize: 8,
          },
        })) || 'N/D',
      ],

      footer: function (currentPage, pageCount, pageSize) {
        return [
          {
            canvas: [
              {
                type: 'line',
                x1: 30,
                y1: 0,
                x2: pageSize.width - 30,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
          {
            stack: [
              {
                text: 'Pag. '.concat(
                  currentPage.toString(),
                  ' de ',
                  pageCount.toString(),
                  ` - ${new Date().getDate()}/${
                    new Date().getMonth() + 1
                  }/${new Date().getFullYear()}`
                ),
                margin: [30, 0, 30, 0],
                fontSize: 7,
              },

              {
                text: `Status: ${getValues('status')
                  .reduce((acc: string[], cur: any) => {
                    acc.push(cur.label);
                    return acc;
                  }, [])
                  .toString()}`,
                margin: [30, 0, 30, 0],
                fontSize: 7,
              },
              {
                text: `Profissional: ${data[0].professional?.name || 'N/D'}`,
                margin: [30, 0, 30, 0],
                fontSize: 7,
              },
              {
                text: `Dia: ${period || 'N/D'}`,
                margin: [30, 0, 30, 0],
                fontSize: 7,
              },
            ],
          },
        ];
      },

      styles: {
        header: {
          fontSize: 12,
          bold: true,
          margin: [30, 30, 30, 0],
        },
      },
    };
    const doc = pdfMake.createPdf(dd);

    // doc.download();
    doc.open();
    // doc.getBase64((data) => {
    //   self.location.href = 'data:application/pdf;base64,' + data;
    // });
  };

  // console.log('sumary =>', dataSummary);

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div style={{ width: '90%', position: 'absolute' }}>
        <div className="text-sm breadcrumbs relative">
          <ul>
            <li>{relatTitle}</li>
          </ul>
        </div>
        <div className="my-5 w-full relative z-10">
          <form onSubmit={handleSubmit(findAppointmentsProfessional)}>
            <div className=" w-full form-control flex flex-row justify-start">
              {/* data */}
              <div className="flex justify-between mr-4">
                <label className="w-20 flex items-center bg-gray-300 text-white py-1 pl-3">
                  Dia
                </label>
                <input
                  type="date"
                  placeholder="Data"
                  className="w-42 h-full rounded-none input input-gray-500 bg-gray-100 input-sm"
                  {...register('date', { required: true })}
                />
              </div>
              {/* professionals */}
              <div className="flex justify- items-center">
                <div className="form-control mr-4">
                  <select
                    {...register('professional', { required: true })}
                    className="select select-bordered w-3xl"
                  >
                    <option value={''}>Selecione um profissional</option>
                    {professionals.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* status */}
              <div className="w-full flex my-1">
                <Controller
                  render={({ field }) => (
                    <Select
                      className="w-80"
                      {...field}
                      isMulti
                      isClearable
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      openMenuOnFocus={false}
                      isSearchable={false}
                      autoFocus={false}
                      openMenuOnClick={false}
                      hideSelectedOptions={true}
                      placeholder={`Selecione um/mais Status`}
                      options={typeStatus.map((el) => ({
                        label: el,
                        value: el,
                      }))}
                    />
                  )}
                  name="status"
                  control={control}
                  defaultValue={[]}
                  rules={{ required: true }}
                />
              </div>

              <button type="submit" className="my-2 btn btn-sm btn-gray-500">
                Buscar
              </button>
            </div>
          </form>
        </div>
        <div className="divider opacity-25"></div>

        <table className="table table-compact w-full z-0 table-zebra">
          <thead>
            <tr className="bg-gray-100">
              <th className="text-center">Ordem</th>
              <th className="text-center">Horário</th>
              <th className="text-center">Procedimento</th>
              <th className="text-left">Paciente</th>
              <th className="text-left">Celular</th>
              <th className="text-left">DN</th>
              <th className="text-left">Documento</th>
              <th className="text-left">E-mail</th>
              <th className="text-left">Endereço</th>
            </tr>
          </thead>

          {loading && (
            <tbody>
              <tr>
                <td colSpan={50}>
                  <Loading />
                </td>
              </tr>
            </tbody>
          )}

          {!loading && (
            <tbody>
              {data.map((dt: Appointment, gIdx: number) => (
                <tr key={gIdx}>
                  <td className="text-center">{dt.order}</td>
                  <td className="text-center">
                    {dt.procedure
                      ? addTime(
                          dt.appointmentBegin,
                          dt.procedure.estimatedTime.toString(),
                          dt.order - 1
                        )
                      : 'N/D'}
                  </td>
                  <td className="text-left">{dt.procedure?.name}</td>
                  <td className="text-left">{dt.patient?.name}</td>
                  <td className="text-left">{dt.patient?.cel}</td>
                  <td className="text-left">
                    {getFormatedDate(dt.patient?.dn)}
                  </td>
                  <td className="text-left">{dt.patient?.document}</td>
                  <td className="text-left">{dt.patient?.email}</td>
                  <td className="text-left">
                    {dt.patient?.address} / {dt.patient?.city}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <div className="p-4 bg-gray-100 flex flex-col text-xs">
          <div>Sumário: </div>
          {dataSummary.map((el, idx) => {
            return (
              <div key={idx} className="flex text-gray-900">
                <span className="mr-3">{el[0] || 'N/D'}:</span>
                <span className="mr-3">{el[1].length || 'N/D'}</span>
              </div>
            );
          }) || 'N/D'}
        </div>
        <button
          type="button"
          className="my-2 btn btn-sm bg-blue-300 border-blue-300"
          onClick={generatePdf}
        >
          Imprimir
        </button>
      </div>
    </div>
  );
};

export default AppRelatAppoitmentsProfessional;
