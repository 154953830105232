/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import history from '../../../history';
import { useForm } from 'react-hook-form';
import Loading from '../../../components/Loading';
import { useStepMakeRecord } from '.';
import FindPatient from '../../../components/FindPatient';

export const Initial: React.FC = () => {
  const {
    loading,
    professionals,
    professional,
    setProfessional,
    patient,
    setPatient,
    clearRecord,
  } = useStepMakeRecord();
  const [patientName, setPatientName] = React.useState('');
  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (professional) {
      setValue('professional', professional.id);
    }
    if (patient) {
      setValue('patient', patient.id);
      setPatientName(patient.name);
    }
  }, [professional]);

  const onSubmit = (data: any) => {
    console.log(data);
    const { professional, patient } = data;
    setProfessional(professional);
    setPatient(patient);
    clearRecord();
    history.push('/step-make-record/finist');
  };

  return (
    <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 z-0`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/record')}>Prontuários</a>
          </li>
          <li>Gerar Prontuário</li>
        </ul>
      </div>
      <ul className="w-full steps my-6">
        <li className="step step-primary">Dados</li>
        <li className="step step">Finalizar</li>
      </ul>
      {loading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="mb-3 w-full">
          <input type="hidden" {...register('patient')} />
          <div className="flex space-x-4 justify-between">
            {/* name */}
            <div className="form-control">
              <label className="label">
                <span className="label-text">Nome</span>
              </label>
              <div className="flex">
                <input
                  readOnly
                  value={patientName}
                  className="input input-bordered"
                />
                <FindPatient
                  size="btn-md"
                  setSelected={(patient) => {
                    setValue('patient', patient.id);
                    setPatientName(patient.name);
                  }}
                />
              </div>
            </div>
            {/* professionals */}
            <div className="flex justify-start">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Profissional</span>
                </label>
                <select
                  {...register('professional', { required: true })}
                  className="select select-bordered w-full max-w-xs"
                >
                  <option value={''}>Selecione um Profisional</option>
                  {professionals.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  ))}
                </select>
                <span className="text-xs text-red-500">
                  {errors.professional && 'Campo requerido'}
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-end my-4">
            <button type="submit" className="bt btn-sm btn-primary rounded">
              Próximo
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

/* : '',
room: 0,
qtde: 0,
procedure: '', */
