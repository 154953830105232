/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import history from '../../history';
import { Professional } from './professionals.model';
import { validateToken } from '../../services/app.service';
import { getById, save } from './professionals.service';
import { Specialty } from '../specialties/specialties.model';
import { list as listSpecialities } from '../specialties/specialties.service';
import { SubmitButton } from '../../components/SubmitButton';
import CropedImage from '../../components/CropedImage';
import noImgUser from '../../assets/svg/user.svg';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { PencilAltIcon } from '@heroicons/react/outline';
import { Procedure } from '../procedures/procedures.model';

const AppCadProfessionalsForm: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const [submiting, setSubmiting] = useState<boolean>(false);
  const [submited, setSubmited] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<[]>([]);

  const [specialties, setSpecialties] = useState<Specialty[]>([]);
  const [procedures, setProcedures] = useState<Procedure[]>([]);

  const location = useLocation<string>();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const init = async () => {
    validateToken();
    await listSpecialities().then((list) => setSpecialties(list));
    const professionalId = location.state;
    // console.log('professionalId', professionalId);
    if (professionalId) {
      const data = await getById(professionalId);
      const entries = Object.entries(data);
      entries.forEach((entry) => {
        switch (entry[0]) {
          case 'specialty': {
            setValue(entry[0], entry[1]['id']);
            break;
          }

          case 'procedures': {
            setProcedures(entry[1]);
            break;
          }

          default: {
            setValue(entry[0], entry[1]);
          }
        }
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const onSubmit = (data: Professional) => {
    setSubmiting(true);
    delete data.procedures;
    save(data)
      .then((ret) => {
        console.log('professionals response', data);
        if (ret.data && ret.data.error) {
          setError(true);
          setErrorMsg(ret.data.message);
          setSubmited(false);
          setSubmiting(false);
          return;
        }
        if (ret.affected === 0) {
          setError(true);
          return;
        }
        setError(false);
        setSubmiting(false);
        setSubmited(true);
        if (!data.id) history.goBack();
      })
      .catch((e) => {
        console.log('error', e);
        setError(true);
        setSubmited(false);
        setSubmiting(false);
      });
  };

  if (loading) {
    return (
      <div className="h-screen flex flex-col justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className={`max-w-3xl mx-auto px-2 sm:px-6 lg:px-8 my-20 z-0`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/cad-professionals')}>
              Cadastro de Profissionais
            </a>
          </li>
          <li className="text-primary">
            {location.state ? 'Editar Profissional' : 'Inserir Profissional'}
          </li>
        </ul>
      </div>
      <div className={`w-full flex flex-col`}>
        <Tabs>
          <TabList>
            <Tab>Dados Básicos</Tab>
            <Tab>Procedimentos</Tab>
          </TabList>

          <TabPanel>
            <div className="flex justify-center">
              {location.state && (
                <div className={`w-1/2 p-3 relative`}>
                  <div className="avatar mt-5 flex flex-col">
                    <div className="mb-8 rounded-full w-40 h-40">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/public/professionals/${location.state}.png`}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src = noImgUser;
                        }}
                      />
                    </div>
                    <CropedImage
                      pathUrl={`/professionals/upload/${location.state}`}
                    />
                  </div>
                </div>
              )}
              <form onSubmit={handleSubmit(onSubmit)} className="mb-3 w-1/2">
                <input type="hidden" {...register('id')} />
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Nome</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Nome Completo"
                    className="input input-bordered"
                    {...register('name', { required: true })}
                  />
                  <span className="text-xs text-red-500">
                    {errors.name && 'Campo requerido'}
                  </span>
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Abreviatura</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Abreviatura"
                    className="input input-bordered"
                    // style={{ textTransform: 'uppercase' }}
                    maxLength={3}
                    onKeyUp={(e) => {
                      e.currentTarget.value =
                        e.currentTarget.value.toUpperCase();
                    }}
                    {...register('prefix', { required: false })}
                  />
                  <span className="text-xs text-red-500">
                    {errors.prefix && 'Campo requerido'}
                  </span>
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Registro Conselho</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Registro Conselho"
                    className="input input-bordered"
                    {...register('crm', { required: true })}
                  />
                  <span className="text-xs text-red-500">
                    {errors.crm && 'Campo requerido'}
                  </span>
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">CEL</span>
                  </label>
                  <input
                    type="text"
                    placeholder="CEL"
                    className="input input-bordered"
                    {...register('cel', { required: true })}
                  />
                  <span className="text-xs text-red-500">
                    {errors.cel && 'Campo requerido'}
                  </span>
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Especialidade</span>
                  </label>
                  <select
                    {...register('specialty', { required: true })}
                    className="select select-bordered w-full max-w-xs"
                  >
                    {specialties.map((esp) => (
                      <option key={esp.id} value={esp.id}>
                        {esp.name}
                      </option>
                    ))}
                  </select>
                  <span className="text-xs text-red-500">
                    {errors.specialty && 'Campo requerido'}
                  </span>
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">DESTAQUE</span>
                  </label>
                  <input
                    type="color"
                    placeholder="Cor de Destaque"
                    className="input input-bordered w-full"
                    {...register('color', { required: false })}
                  />
                  <span className="text-xs text-red-500">
                    {errors.color && 'Campo requerido'}
                  </span>
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Senha</span>
                  </label>
                  <input
                    type="password"
                    placeholder="Senha"
                    className="input input-bordered"
                    {...register('password', { required: false })}
                  />
                  <span className="text-xs text-red-500">
                    {errors.password && 'Campo requerido'}
                  </span>
                </div>

                <div className="form-control">
                  <label className="cursor-pointer label">
                    <span className="label-text">Ativo</span>
                    <input
                      type="checkbox"
                      {...register('isActive')}
                      className="toggle toggle-primary"
                      defaultChecked={false}
                    />
                  </label>
                </div>

                <div className="form-control">
                  <label className="cursor-pointer label">
                    <span className="label-text">Somente Lista de Espera</span>
                    <input
                      type="checkbox"
                      {...register('onlyWaitingList')}
                      className="toggle toggle-primary"
                      defaultChecked={false}
                    />
                  </label>
                </div>

                <div className="form-control">
                  <label className="cursor-pointer label">
                    <span className="label-text">Condições de Pagamento</span>
                  </label>
                  <textarea
                    rows={5}
                    {...register('pgtoConditions')}
                    className="textarea textarea-bordered"
                  />
                </div>

                <div className="flex justify-between mt-3">
                  <button
                    className="btn bg-gray-500"
                    onClick={() => history.goBack()}
                  >
                    Voltar
                  </button>
                  <SubmitButton caption="Salvar" submiting={submiting} />
                </div>
              </form>
            </div>
          </TabPanel>

          <TabPanel>
            <table className="table w-full z-0">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Tempo Estimado</th>
                  <th></th>
                </tr>
              </thead>
              {loading && (
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      <Loading />
                    </td>
                  </tr>
                </tbody>
              )}
              {!loading && (
                <tbody>
                  {procedures.map((el) => (
                    <tr key={el.id}>
                      <td>{el.name}</td>
                      <td>{Number(el.estimatedTime).toFixed(0)}</td>
                      <td className="flex flex-row justify-end">
                        <button
                          className="hover:opacity-70"
                          onClick={() =>
                            history.push('/cad-procedures-form', {
                              id: el.id,
                              return: location.pathname + location.search,
                            })
                          }
                        >
                          <PencilAltIcon className="h-6" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </TabPanel>
        </Tabs>

        {submited && (
          <div className="alert alert-info">
            <div className="flex-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="w-6 h-6 mx-2 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <label>Registro salvo com sucesso!</label>
            </div>
          </div>
        )}
        {error && (
          <div className="alert alert-error flex flex-col items-start">
            <div className="flex-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="w-6 h-6 mx-2 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                ></path>
              </svg>
              <label>Error ao salvar registro</label>
            </div>
            <ul className="p-3">
              {errorMsg && errorMsg.map((err, idx) => <li key={idx}>{err}</li>)}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppCadProfessionalsForm;
