import { CheckCircleIcon, SearchIcon, XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import ModalPatients, { Styles } from 'react-modal';
import AppCadPatientsSearch from '../pages/patients/AppCadPatientsSearch';
import { Patient } from '../pages/patients/patients.model';
import { search as searchPatients } from '../pages/patients/patients.service';
import Loading from './Loading';
const customStyles: Styles = {
  content: {
    top: '60%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    height: '50vh',
    width: '50vw',
    zIndex: 999998,
  },
};

interface SearchPatientProps {
  size: 'btn-lg' | 'btn-md' | 'btn-sm' | 'btn-xs';
  setSelected: (patientId: string) => void;
}

const SearchPatient: React.FC<SearchPatientProps> = ({
  size,
  setSelected,
}: SearchPatientProps) => {
  const [loading, setLoading] = useState(false);
  const [loadingPatient, setLoadingPatient] = useState(false);
  const [data, setData] = useState<Patient[]>([]);

  const searchPatient = async () => {
    setLoading(true);
    setModalPatientsIsOpen(true);
  };
  const [modalPatientsIsOpen, setModalPatientsIsOpen] = useState(false);
  function afterCloseModalPatients() {
    setLoading(false);
  }

  function closeModalPatients() {
    setModalPatientsIsOpen(false);
  }

  const search = async (name: string) => {
    setLoadingPatient(true);
    const data = await searchPatients(name);
    setData(data);
    setLoadingPatient(false);
  };
  useEffect(() => {
    setData([]);
  }, []);

  return (
    <div>
      <button
        type="button"
        className={`btn ${size} ${loading ? 'loading' : ''}`}
        onClick={searchPatient}
      >
        {!loading && <SearchIcon className="w-4 h-4" />}
      </button>
      <ModalPatients
        isOpen={modalPatientsIsOpen}
        onAfterClose={afterCloseModalPatients}
        onRequestClose={closeModalPatients}
        style={customStyles}
        contentLabel="Loacalizar Paciente"
        ariaHideApp={false}
        appElement={document.getElementById('root') as HTMLElement}
      >
        <h1 className="flex justify-between items-center w-full mb-5 z-50">
          <AppCadPatientsSearch click={(param) => search(param)} />
          <button onClick={() => closeModalPatients()}>
            <XIcon className="w-5 h-5 hover:bg-gray-300" />
          </button>
        </h1>

        {data.length > 0 && (
          <table className="table w-full z-0 table-compact">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>Cel</th>
                <th></th>
              </tr>
            </thead>
            {loadingPatient && (
              <tbody>
                <tr>
                  <td colSpan={4}>
                    <Loading />
                  </td>
                </tr>
              </tbody>
            )}
            {!loadingPatient && (
              <tbody>
                {data.map((el, index) => (
                  <tr key={el.id}>
                    <th>{index + 1}</th>
                    <td>{el.name}</td>
                    <td>{el.cel}</td>
                    <td className="flex flex-row justify-end">
                      <button
                        className="hover:opacity-70"
                        onClick={() => {
                          setSelected(el.cel);
                          closeModalPatients();
                        }}
                      >
                        <CheckCircleIcon className="h-6" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        )}
      </ModalPatients>
    </div>
  );
};

export default SearchPatient;
