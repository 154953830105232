/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { validateToken } from '../../services/app.service';
import { relatAppoinmentsProfessinalsGroup } from '../appointments/appointments.service';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { typeStatus } from '../../utils/app.utils';
import Loading from '../../components/Loading';
import {
  getFormatedDate,
  getFormatedDateWeek,
} from '../../utils/datetimeHandler';

import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import pdfFonts from 'pdfmake/build/vfs_fonts';
// @ts-ignore: Unreachable code error
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import ReactExport from 'react-export-excel';
import { list } from '../specialties/specialties.service';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const AppRelatAppointmensProfessionalsGroup: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [dataExcel, setDataExcel] = useState<any[]>([]);
  const [data2Excel, setData2Excel] = useState<any[]>([]);
  const { handleSubmit, register, control, getValues } = useForm();

  const init = async () => {
    validateToken();
    const specialties = await list();
    setData2Excel(specialties);
  };

  useEffect(() => {
    init();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchAppointmentsProfessionalsGroup = (data: any) => {
    setLoading(true);
    data.status = data.status.reduce((a: any[], c: any) => {
      a.push(c.value);
      return a;
    }, []);

    relatAppoinmentsProfessinalsGroup(data).then((ret) => {
      setDataExcel(ret);
      const days = ret.reduce((acc: string[], cur: any) => {
        if (!acc.includes(cur.date)) {
          acc.push(cur.date);
        }

        return acc;
      }, []);

      // console.log('days', days);

      const groups: { day: any; group: any[] }[] = [];
      days.forEach((day) => {
        groups.push({
          day,
          group: ret.filter((el) => el.date === day),
        });
      });

      console.log('groups', groups);
      setData(groups);
      setLoading(false);
    });
  };

  const generatePdf = () => {
    // @ts-ignore: Unreachable code error
    pdfMake.fonts = {
      Roboto: {
        normal:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
    };

    const dd: TDocumentDefinitions = {
      defaultStyle: {
        font: 'Roboto',
      },

      pageSize: 'A4',

      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            text: 'Relatório de Atendimentos Agrupado por Profissional',
            alignment: currentPage % 2 ? 'left' : 'right',
            style: 'header',
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 30,
                y1: 0,
                x2: pageSize.width - 30,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
        ];
      },
      content: data.map((dt) => ({
        stack: [
          {
            text: `Dia: ${getFormatedDate(
              String(dt.day)
            )} (${getFormatedDateWeek(String(dt.day))})`,
            style: {
              fontSize: 18,
              bold: true,
            },
          },
          {
            ol: dt.group.map((g: any) => ({
              text: `${g.professional_name} - ${g.specialty_name} (${g.count})`,
              style: {
                fontSize: 13,
                bold: false,
              },
            })),
          },
          `\n`,
        ],
      })),

      footer: function (currentPage, pageCount, pageSize) {
        return [
          {
            canvas: [
              {
                type: 'line',
                x1: 30,
                y1: 0,
                x2: pageSize.width - 30,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
          {
            stack: [
              {
                text: 'Pag. '.concat(
                  currentPage.toString(),
                  ' de ',
                  pageCount.toString(),
                  ` - ${new Date().getDate()}/${
                    new Date().getMonth() + 1
                  }/${new Date().getFullYear()}`
                ),
                margin: [30, 0, 30, 0],
                fontSize: 10,
              },
              {
                text: `Período de ${getFormatedDate(
                  getValues('dateIni')
                )} à ${getFormatedDate(getValues('dateEnd'))}`,
                margin: [30, 0, 30, 0],
                fontSize: 10,
              },
              {
                text: `Filtro: ${getValues('status')
                  .reduce((acc: string[], cur: any) => {
                    acc.push(cur.value);
                    return acc;
                  }, [])
                  .toString()}`,
                margin: [30, 0, 30, 0],
                fontSize: 10,
              },
            ],
          },
        ];
      },

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [30, 30, 30, 0],
        },
      },
    };
    const doc = pdfMake.createPdf(dd);

    // doc.download();
    doc.open();
    // doc.getBase64((data) => {
    //   self.location.href = 'data:application/pdf;base64,' + data;
    // });
  };

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div style={{ width: '90%', position: 'absolute' }}>
        <div className="text-sm breadcrumbs relative">
          <ul>
            <li>Relatório de Atendimentos Agrupado por Profissional</li>
          </ul>
        </div>
        <div className="my-5 w-full relative z-10">
          <form onSubmit={handleSubmit(searchAppointmentsProfessionalsGroup)}>
            <div className=" w-full form-control flex flex-row justify-start">
              <div className="mr-3">
                <div className="flex justify-between">
                  <label className="w-20 bg-gray-300 text-white py-1 pl-3">
                    Início
                  </label>
                  <input
                    type="date"
                    placeholder="Data"
                    className="w-42 rounded-none input input-gray-500 bg-gray-100 input-sm"
                    {...register('dateIni', { required: true })}
                  />
                </div>
                <div className="flex justify-between">
                  <label className="w-20 bg-gray-300 text-white py-1 pl-3">
                    Final
                  </label>
                  <input
                    type="date"
                    placeholder="Data"
                    className="w-42 rounded-none input input-gray-500 bg-gray-100 input-sm"
                    {...register('dateEnd', { required: true })}
                  />
                </div>
              </div>
              {/* status */}
              <div className="w-full flex">
                <Controller
                  render={({ field }) => (
                    <Select
                      className="w-80"
                      {...field}
                      isMulti
                      isClearable
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      openMenuOnFocus={false}
                      isSearchable={false}
                      autoFocus={false}
                      openMenuOnClick={false}
                      hideSelectedOptions={true}
                      placeholder={`Selecione um Status`}
                      options={typeStatus.map((el) => ({
                        label: el,
                        value: el,
                      }))}
                    />
                  )}
                  name="status"
                  control={control}
                  defaultValue={[]}
                  rules={{ required: true }}
                />
              </div>

              <button type="submit" className="my-2 btn btn-sm btn-gray-500">
                Buscar
              </button>
            </div>
          </form>
        </div>
        <div className="divider opacity-25"></div>
        {loading && (
          <div className="pt-20 text-center">
            <Loading />
          </div>
        )}

        {!loading &&
          data.map((dt, dtIdx) => (
            <div key={dtIdx} className="my-4">
              <h1 className="py-3 text-center bg-gray-600 text-white">
                Dia: {getFormatedDate(String(dt.day))} (
                {getFormatedDateWeek(String(dt.day))})
              </h1>
              <table className="table-compact w-full z-0">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="text-left">Profissional</th>
                    <th className="text-left">Especialidade</th>
                    <th className="text-center">Qtde Atendimentos</th>
                  </tr>
                </thead>

                <tbody>
                  {dt.group.map((g: any, gIdx: number) => (
                    <tr className="border-dotted border-b-2" key={gIdx}>
                      <td className="text-left">{g.professional_name}</td>
                      <td className="text-left">{g.specialty_name}</td>
                      <td className="text-center">{g.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        {data.length > 0 && (
          <div className="flex space-x-3">
            <button
              type="button"
              className="my-2 btn btn-sm bg-blue-300 border-blue-300"
              onClick={generatePdf}
            >
              Imprimir
            </button>
            <ExcelFile
              element={
                <button
                  type="button"
                  className="my-2 btn btn-sm bg-blue-300 border-blue-300"
                >
                  Excel Download
                </button>
              }
            >
              <ExcelSheet
                data={dataExcel.reduce((acc: any[], cur: any) => {
                  if (cur.professional_isActive) {
                    acc.push(cur);
                  }
                  return acc;
                }, [])}
                name="Profissionais"
              >
                <ExcelColumn
                  label="DIA"
                  value={(col: any) => getFormatedDate(String(col.date))}
                />
                <ExcelColumn label="PROFISSIONAL" value="professional_name" />
                <ExcelColumn label="ESPECIALIDADE" value="specialty_name" />
              </ExcelSheet>
              <ExcelSheet data={data2Excel} name="ESPECIALIDADES">
                <ExcelColumn label="NOME" value={'name'} />
                <ExcelColumn label="DEFINIÇÃO" value="definition" />
              </ExcelSheet>
            </ExcelFile>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppRelatAppointmensProfessionalsGroup;
