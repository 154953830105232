import {
  getFormLocalStorage,
  setFormLocalStorage,
} from '../../services/app.service';
import { useAppointment } from '.';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Appointment } from './appointments.model';
import TableAppointments from './TableAppointments';
import Modal, { Styles } from 'react-modal';
import { Img } from 'react-image';
import Loading from '../../components/Loading';

const APPOINTMENTS_DAY = 'AppointmentsDay';
const customStyles: Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#f5f5f5',
    zIndex: 500,
    padding: 0,
  },
};

const AppManagerAppointments: React.FC = () => {
  const { loading, data, professionalGroup, searchDateProfessional } =
    useAppointment();
  const { handleSubmit, register, setValue } = useForm();

  const [modalData, setModalData] = useState<Appointment[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const SubmitSearchDateProfessional = (data: {
    day: string;
    professional?: string;
  }) => {
    setFormLocalStorage(APPOINTMENTS_DAY, data.day);
    searchDateProfessional(data.day, '');
  };

  useEffect(() => {
    const day = getFormLocalStorage(APPOINTMENTS_DAY);
    setValue('day', day);

    if (day) {
      searchDateProfessional(day, '');
    }
  }, []);

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>Atendimentos</li>
        </ul>
      </div>
      <div className={`flex my-3 p-1`}>
        <form onSubmit={handleSubmit(SubmitSearchDateProfessional)}>
          <div className="form-control">
            <div className="relative flex">
              <input
                type="date"
                placeholder="Data"
                className="w-full rounded-r-none input input-gray-500 bg-gray-100 input-sm"
                {...register('day', { required: false })}
              />

              <button
                type="submit"
                className="rounded-l-none btn btn-sm btn-gray-500"
              >
                Buscar
              </button>
            </div>
          </div>
        </form>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="grid grid-cols-4 gap-3 mb-1 ">
          {professionalGroup &&
            professionalGroup.group &&
            professionalGroup.group
              .sort((a, b) => a.prof.localeCompare(b.prof))
              .map((g, i: number) => (
                <button
                  key={i}
                  className="p-4 hover:bg-gray-100 z-0 rounded-btn"
                  onClick={() => {
                    setModalData(
                      data.filter(
                        (d: Appointment) => d.professional.id === g.profId
                      )
                    );
                    setModalOpen(true);
                  }}
                >
                  <div className="flex">
                    <Img
                      width={200}
                      height={200}
                      className="rounded-full"
                      // crossorigin="anonymous"
                      src={[
                        `${process.env.REACT_APP_API_URL}/public/professionals/${g.profId}.png`,
                        `/no-image-100x100.png`,
                      ]}
                      container={(children) => {
                        return (
                          <div
                            style={{
                              width: '200px',
                              height: '200px',
                              margin: '0 auto',
                            }}
                          >
                            {children}
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="badge badge-secondary z-0">
                    {g.count} {g.count > 1 ? 'Agendamentos' : 'Agendamento'}
                  </div>
                  <div className="grid p-1 m-1 rounded bg-base-300 place-items-center">
                    {g.prof}
                  </div>
                </button>
              ))}
        </div>
      )}
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customStyles}
        contentLabel="Agendamentos"
        ariaHideApp={false}
        appElement={document.getElementById('root') as HTMLElement}
      >
        <div
          style={{
            height: '70vh',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <TableAppointments data={modalData} loading={false} />
        </div>
        <div className="flex justify-end my-2 mx-1">
          <button
            className="btn btn-sm btn-gray-500"
            onClick={() => setModalOpen(false)}
          >
            Fechar
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AppManagerAppointments;
