/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import history from '../../history';
import { validateToken } from '../../services/app.service';
import {
  addConvenant,
  deleteConvenant,
  getById,
  save,
} from './procedures.service';
import { SubmitButton } from '../../components/SubmitButton';
import { list as ProfessinalList } from '../professionals/professionals.service';
import { Professional } from '../professionals/professionals.model';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { XIcon } from '@heroicons/react/outline';
import { Convenant } from '../covenants/covenants.model';
import Modal from 'react-modal';
import CurrencyInput from 'react-currency-input-field';
import { list as listConvenants } from '../covenants/covenants.service';
import { list as listRecommendations } from '../recommendations/recommendations.service';
import { Recommendation } from '../recommendations/recommendations.model';

const AppCadProceduresForm: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const [submiting, setSubmiting] = useState<boolean>(false);
  const [submited, setSubmited] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<[]>([]);

  const [professionals, setProfessionals] = useState<Professional[]>();
  const [recommendations, setRecommendations] = useState<Recommendation[]>();

  const location = useLocation<any>();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    handleSubmit: handleSubmit2,
    register: register2,
    formState: { errors: errors2 },
  } = useForm();

  const init = async () => {
    validateToken();
    await listConvenants().then((list) => setConvenantsList(list));
    const procedureId = location.state?.id || null;
    const profs = await ProfessinalList();
    setProfessionals(profs);
    const recommendations = await listRecommendations();
    setRecommendations(recommendations);
    // console.log('location.state', location.state);
    if (procedureId) {
      const data = await getById(procedureId);
      if (data.recommendations) {
        setValue('recommendationsId', data.recommendations.id);
      }
      const entries = Object.entries(data);
      entries.forEach((entry) => {
        const field = entry[0];
        const value = entry[1];
        switch (field) {
          case 'procedureToCovenants': {
            setConvenants(value);
            break;
          }

          default: {
            setValue(
              field,
              typeof value === 'number' && field !== 'estimatedTime'
                ? value.toFixed(2).replace('.', ',')
                : value
            );
          }
        }
      });

      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const submit = (data: any) => {
    setSubmiting(true);
    data.estimatedTime = Number(
      data.estimatedTime.toString().replace(',', '.')
    );
    delete data._v;
    data.professional = data.professionalId;
    data.recommendations = data.recommendationsId;

    delete data.recommendationsId;

    if (!data.id) {
      delete data.id;
    }

    // console.log('Data recommendations => ', data);

    save(data)
      .then((ret) => {
        if (ret.data.id) {
          setError(false);
          setSubmiting(false);
          setSubmited(false);
          setErrorMsg([]);
          history.push('/cad-procedures');
          return;
        }
        // console.log('ret ===>', ret);S
        if (ret.data.affected && ret.data.affected !== 1) {
          setError(true);
          setErrorMsg(ret.error.message);
          setSubmited(false);
          setSubmiting(false);
          return;
        } else {
          setError(false);
          setSubmiting(false);
          setSubmited(true);
          setErrorMsg([]);
          return;
        }
      })
      .catch((e) => {
        console.log('error', e);
        setError(true);
        setSubmited(false);
        setSubmiting(false);
      });
  };

  const [submitingConvenant, setSubmitingConvenant] = useState<boolean>(false);
  const [covenantsList, setConvenantsList] = useState<Convenant[]>([]);
  const [covenants, setConvenants] = useState<any[]>([]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const onSubmitConvenant = async (data: any) => {
    setSubmitingConvenant(true);
    const { convenant, value, commission } = data;
    const id = location.state?.id || null;
    console.log('data add convenant ===>', data);
    const ret = await addConvenant(convenant, id, value, commission);
    console.log('ret', ret);
    setSubmitingConvenant(false);
    setModalIsOpen(false);
    init();
    // self.location.reload();
  };

  const delConvenant = (covenant: string) => {
    deleteConvenant(covenant).then(() => init());
  };

  if (loading) {
    return (
      <div className="h-screen flex flex-col justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className={`max-w-3xl mx-auto px-2 sm:px-6 lg:px-8 my-20 z-0`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/cad-procedures')}>
              Cadastro de Procedimentos
            </a>
          </li>
          <li className="text-primary">
            {location.state ? 'Editar Procedimento' : 'Inserir Procedimento'}
          </li>
        </ul>
      </div>
      <div className={`w-full`}>
        <Tabs>
          <TabList>
            <Tab>Dados Básicos</Tab>
            <Tab>Convênios</Tab>
          </TabList>
          <TabPanel>
            <form onSubmit={handleSubmit(submit)} className="mb-3 w-full">
              <input type="hidden" {...register('id', { required: false })} />

              <div className="form-control">
                <label className="label">
                  <span className="label-text">Nome</span>
                </label>
                <input
                  type="text"
                  placeholder="Nome Completo"
                  className="input input-bordered"
                  {...register('name', { required: true })}
                />
                <span className="text-xs text-red-500">
                  {errors.name && 'Campo requerido'}
                </span>
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">Profissional</span>
                </label>
                <select
                  {...register('professionalId', { required: true })}
                  className="select select-bordered w-full max-w-xs"
                >
                  <option value="">Selecione um profissional</option>
                  {professionals &&
                    professionals.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.name}
                      </option>
                    ))}
                </select>
                <span className="text-xs text-red-500">
                  {errors.professionalId && 'Campo requerido'}
                </span>
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">Tempo Estimado (min)</span>
                </label>
                <input
                  type="value"
                  placeholder="Tempo do procedimento (minutos)"
                  className="input input-bordered"
                  {...register('estimatedTime', { required: true })}
                />
                <span className="text-xs text-red-500">
                  {errors.estimatedTime && 'Campo requerido'}
                </span>
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">Recomendações</span>
                </label>
                <select
                  {...register('recommendationsId', { required: false })}
                  className="select select-bordered w-full max-w-xs"
                >
                  <option value="">Selecione uma recomendação</option>
                  {recommendations &&
                    recommendations.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.name}
                      </option>
                    ))}
                </select>
                <span className="text-xs text-red-500">
                  {errors.recommendationsId && 'Campo requerido'}
                </span>
              </div>

              <div className="flex justify-between mt-3">
                <button
                  className="btn bg-gray-500"
                  onClick={() =>
                    location.state?.return
                      ? history.push(location.state?.return)
                      : history.goBack()
                  }
                >
                  Voltar
                </button>
                <SubmitButton caption="Salvar" submiting={submiting} />
              </div>
            </form>
            {submited && (
              <div className="alert alert-info">
                <div className="flex-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="w-6 h-6 mx-2 stroke-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <label>Registro salvo com sucesso!</label>
                </div>
              </div>
            )}
            {error && (
              <div className="alert alert-error flex flex-col items-start">
                <div className="flex-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="w-6 h-6 mx-2 stroke-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    ></path>
                  </svg>
                  <label>Error ao salvar registro</label>
                </div>
                <ul className="p-3">
                  {errorMsg &&
                    errorMsg.map((err, idx) => <li key={idx}>{err}</li>)}
                </ul>
              </div>
            )}
          </TabPanel>
          <TabPanel>
            <div className="text-right my-2">
              <button
                className="btn btn-xs btn-primary"
                onClick={() => setModalIsOpen(true)}
              >
                Inserir Convênio
              </button>
            </div>
            <table className="table w-full z-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Convênio</th>
                  <th>Valor</th>
                  <th>Comissão (%)</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {covenants.map((el, index) => (
                  <tr key={el.procedureToConvenantId}>
                    <th>{index + 1}</th>
                    <td>{el.convenant.name}</td>
                    <td>{Number(el.value).toFixed(2).replace('.', ',')}</td>
                    <td>{Number(el.commission).toFixed(2)}</td>
                    <td className="flex flex-row justify-end">
                      <button
                        className="hover:opacity-70"
                        onClick={() => delConvenant(el.procedureToConvenantId)}
                      >
                        <XIcon className="h-6" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Convênios"
        ariaHideApp={false}
        appElement={document.getElementById('root') as HTMLElement}
      >
        <h1 className="flex justify-between items-center w-60 mb-5">
          <span>Inserir Convênio</span>
          <button onClick={() => setModalIsOpen(false)}>
            <XIcon className="w-5 h-5 hover:bg-gray-300" />
          </button>
        </h1>
        <form onSubmit={handleSubmit2(onSubmitConvenant)} className="w-full">
          <div className="form-control mb-2">
            <select
              {...register2('convenant', { required: true })}
              className="select select-bordered w-full"
            >
              {covenantsList.map((esp) => (
                <option key={esp.id} value={esp.id}>
                  {esp.name}
                </option>
              ))}
            </select>
            <span className="text-xs text-red-500">
              {errors2.convenant && 'Campo requerido'}
            </span>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Valor</span>
            </label>
            <CurrencyInput
              disableGroupSeparators={true}
              decimalSeparator=","
              groupSeparator="."
              placeholder="Valor"
              className="input input-bordered"
              {...register2('value', { required: true })}
            />
            <span className="text-xs text-red-500">
              {errors2.value && 'Campo requerido'}
            </span>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Comissão (%)</span>
            </label>
            <CurrencyInput
              disableGroupSeparators={true}
              decimalSeparator=","
              groupSeparator="."
              placeholder="Valor da comissão"
              className="input input-bordered"
              {...register2('commission', { required: true })}
            />
            <span className="text-xs text-red-500">
              {errors2.commission && 'Campo requerido'}
            </span>
          </div>
          <div className="my-2 text-right">
            <SubmitButton caption="Inserir" submiting={submitingConvenant} />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default AppCadProceduresForm;
