/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Loading from '../../components/Loading';
import { SubmitButton } from '../../components/SubmitButton';
import {
  getInstitutional,
  setInstitutional,
  validateToken,
} from '../../services/app.service';
import { themes } from '../../utils/app.utils';
import { Institutional } from './institutional.model';

const AppInstitutional: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const [submiting, setSubmiting] = useState<boolean>(false);
  const [submited, setSubmited] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<[]>([]);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const init = async () => {
    validateToken();
    try {
      const data = await getInstitutional();
      const entries = Object.entries(data);
      entries.forEach((entry) => setValue(entry[0], entry[1]));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const onSubmit = (data: Institutional) => {
    setSubmiting(true);

    setInstitutional(data)
      .then((ret) => {
        if (ret.data && ret.data.error) {
          setError(true);
          setErrorMsg(ret.data.message);
          setSubmited(false);
          setSubmiting(false);
          return;
        }
        if (ret.affected === 0) {
          setError(true);
          return;
        }
        setError(false);
        setSubmiting(false);
        setSubmited(true);
      })
      .catch((e) => {
        console.log('error', e);
        setError(true);
        setSubmited(false);
        setSubmiting(false);
      });
  };

  if (loading) {
    return (
      <div className="h-screen flex flex-col justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className={`max-w-3xl mx-auto px-2 sm:px-6 lg:px-8 my-20 z-0`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li className="text-primary">Institucional</li>
        </ul>
      </div>
      <Tabs>
        <TabList>
          <Tab>Dados Básicos</Tab>
          <Tab>Endereço</Tab>
        </TabList>

        <form onSubmit={handleSubmit(onSubmit)} className="mb-3 w-full">
          <TabPanel>
            <input type="hidden" {...register('id')} />
            <div className="form-control">
              <label className="label">
                <span className="label-text">Nome</span>
              </label>
              <input
                type="text"
                placeholder="Nome da Instituição"
                className="input input-bordered"
                {...register('name', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.name && 'Campo requerido'}
              </span>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">CNPJ</span>
              </label>
              <input
                type="text"
                placeholder="CNPJ"
                className="input input-bordered"
                {...register('cnpj', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.cnpj && 'Campo requerido'}
              </span>
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">CEL</span>
              </label>
              <input
                type="text"
                placeholder="Celular apenas números"
                className="input input-bordered"
                {...register('cel', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.cel && 'Campo requerido'}
              </span>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">TEL</span>
              </label>
              <input
                type="text"
                placeholder="Telefone apenas números"
                className="input input-bordered"
                {...register('tel', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.tel && 'Campo requerido'}
              </span>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">TEMA</span>
              </label>
              <select
                {...register('theme', { required: true })}
                className="select select-bordered w-full max-w-xs"
              >
                {themes.map((theme) => (
                  <option key={theme.key} value={theme.value}>
                    {theme.key}
                  </option>
                ))}
              </select>
              <span className="text-xs text-red-500">
                {errors.theme && 'Campo requerido'}
              </span>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Missão</span>
              </label>
              <textarea
                rows={5}
                placeholder="Missão da Instituição"
                className="input input-bordered"
                {...register('mission', { required: true })}
              ></textarea>
              <span className="text-xs text-red-500">
                {errors.mission && 'Campo requerido'}
              </span>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Visão</span>
              </label>
              <textarea
                rows={5}
                placeholder="Visão da Instituição"
                className="input input-bordered"
                {...register('vision', { required: true })}
              ></textarea>
              <span className="text-xs text-red-500">
                {errors.vision && 'Campo requerido'}
              </span>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Valores</span>
              </label>
              <textarea
                rows={5}
                placeholder="Valores da Instituição"
                className="input input-bordered"
                {...register('values', { required: true })}
              ></textarea>
              <span className="text-xs text-red-500">
                {errors.values && 'Campo requerido'}
              </span>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Endereço</span>
              </label>
              <input
                type="text"
                placeholder="Endereço da Instituição"
                className="input input-bordered"
                {...register('address', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.address && 'Campo requerido'}
              </span>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Cidade</span>
              </label>
              <input
                type="text"
                placeholder="Cidade da Instituição"
                className="input input-bordered"
                {...register('city', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.city && 'Campo requerido'}
              </span>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">UF</span>
              </label>
              <input
                type="text"
                placeholder="UF da Instituição"
                className="input input-bordered"
                {...register('uf', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.uf && 'Campo requerido'}
              </span>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Email 1</span>
              </label>
              <input
                type="text"
                placeholder="Email 1"
                className="input input-bordered"
                {...register('email', { required: false })}
              />
              <span className="text-xs text-red-500">
                {errors.email && 'Campo requerido'}
              </span>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Email 2</span>
              </label>
              <input
                type="text"
                placeholder="Email 2"
                className="input input-bordered"
                {...register('email2', { required: false })}
              />
              <span className="text-xs text-red-500">
                {errors.email2 && 'Campo requerido'}
              </span>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Email 3</span>
              </label>
              <input
                type="text"
                placeholder="Email 3"
                className="input input-bordered"
                {...register('email3', { required: false })}
              />
              <span className="text-xs text-red-500">
                {errors.email3 && 'Campo requerido'}
              </span>
            </div>
          </TabPanel>

          <div className="flex justify-end mt-3">
            <SubmitButton caption="Salvar" submiting={submiting} />
          </div>
        </form>
      </Tabs>
      <div className={`w-full`}>
        {submited && (
          <div className="alert alert-info">
            <div className="flex-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="w-6 h-6 mx-2 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <label>Registro salvo com sucesso!</label>
            </div>
          </div>
        )}
        {error && (
          <div className="alert alert-error flex flex-col items-start">
            <div className="flex-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="w-6 h-6 mx-2 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                ></path>
              </svg>
              <label>Error ao salvar registro</label>
            </div>
            <ul className="p-3">
              {errorMsg && errorMsg.map((err, idx) => <li key={idx}>{err}</li>)}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppInstitutional;
