import React, { useEffect, useState } from 'react';
import { SendButton } from '../../../components/SendButton';
import history from '../../../history';
import { useWaitingListSchenduling } from '../../../hooks/waitingListSchenduling.hook';
import { validateToken } from '../../../services/app.service';
import { getFormatedDate } from '../../../utils/datetimeHandler';
import { save } from '../../appointments/appointments.service';
import { Procedure } from '../../procedures/procedures.model';
import { del } from '../waitingList.service';
import { find as findProcedures } from '../../procedures/procedures.service';

export const FinistWaitingListSchenduling: React.FC = () => {
  const hookData = useWaitingListSchenduling();
  const [submiting, setSubmiting] = useState<boolean>(false);
  const [procedures, setProcedures] = useState<Procedure[]>([]);
  const [procedure, setProcedure] = useState<string>();

  const init = async () => {
    validateToken();
    findProcedures({ professional: hookData.professional }).then(setProcedures);
  };

  useEffect(() => {
    init();
  }, [hookData]);

  const submit = async () => {
    if (!hookData.professional || !hookData.patient || !hookData.appointment || !procedure)
      return;
    setSubmiting(true);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataSend: any = {
      ...hookData.appointment,
      procedure,
      patient: hookData.patient.id,
      status: 'AGENDADO',
    };

    await save(dataSend).then(() => {
      del(hookData.waitingListId);
      setSubmiting(false);
      history.push('/waiting-list');
    });
  };

  // console.log('hook data => ', hookData);

  return (
    <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/waiting-list')}>Lista de Espera</a>
          </li>
          <li>Concluir Agendamento</li>
        </ul>
      </div>
      <ul className="w-full steps my-6">
        <li className="step step-primary">Dados</li>
        <li className="step step-primary">Análise</li>
        <li className="step step-primary">Finalizar</li>
      </ul>

      <div className="flex flex-col">
        <div className="bg-gray-100 p-2 flex justify-between">
          <strong>Paciente:</strong> <span>{hookData.patient?.name}</span>
        </div>
        <div className="bg-gray-200 p-2 flex justify-between">
          <strong>Celular:</strong> <span>{hookData.patient?.cel}</span>
        </div>
        <div className="bg-gray-100 p-2 flex justify-between">
          <strong>Profissional:</strong>{' '}
          <span>{hookData.professional?.name}</span>
        </div>
        <div className="bg-gray-200 p-2 flex justify-between">
          <strong>Dia:</strong>{' '}
          <span>{getFormatedDate(String(hookData.appointment?.date))}</span>
        </div>
        <div className="bg-gray-100 p-2 flex justify-between">
          <strong>Ordem:</strong> <span>{hookData.appointment?.order}</span>
        </div>

        {/* procedures */}
        <div className="flex justify-start">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Procedimentos</span>
            </label>
            <select
              onChange={(e) => setProcedure(e.target.value)}
              className="select select-bordered w-full max-w-xs"
            >
              <option value={''}>Selecione um procedimento</option>
              {procedures.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.name} - ({el.professional.name})
                </option>
              ))}
            </select>
            {/* <span className="text-xs text-red-500">
                  {errors.procedure && 'Campo requerido'}
                </span> */}
          </div>
        </div>

        {/* <div className="flex justify-end my-4">
          <button type="submit" className="bt btn-sm btn-primary rounded">
            Próximo
          </button>
        </div> */}
      </div>

      <div className="flex justify-between my-4">
        <button
          type="button"
          className="bt btn-sm btn-active text-white rounded"
          onClick={() => history.push('/waiting-list-scheduling/analize')}
        >
          Voltar
        </button>
        <SendButton
          click={submit}
          caption="Agendar"
          submiting={submiting}
          size="btn-sm"
        />
      </div>
    </div>
  );
};
