/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef, useState } from 'react';
import {
  getFormLocalStorage,
  setFormLocalStorage,
  validateToken,
} from '../../services/app.service';
import history from '../../history';
import Loading from '../../components/Loading';
import { useForm } from 'react-hook-form';
import { list } from '../professionals/professionals.service';
import { Professional } from '../professionals/professionals.model';
import { del, findStatus } from './appointments.service';
import Table from '../../components/SelectedTable';

const APPOINTMENTS_DELETE_DAY = 'AppointmentsDeleteDay';
const APPOINTMENTS_DELETE_PROFESSIONAL = 'AppointmentsDeleteProfessional';

const AppAppointmensDelete: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [professionals, setProfessionals] = useState<Professional[]>([]);

  const [data, setData] = useState<any[]>([]);
  const selected = useRef<any[]>([]);

  const init = async () => {
    validateToken();
    list().then((data) => {
      setProfessionals(data);
      if (getFormLocalStorage(APPOINTMENTS_DELETE_DAY)) {
        setValue('day', getFormLocalStorage(APPOINTMENTS_DELETE_DAY));
      }
      if (getFormLocalStorage(APPOINTMENTS_DELETE_PROFESSIONAL)) {
        setValue(
          'professional',
          getFormLocalStorage(APPOINTMENTS_DELETE_PROFESSIONAL)
        );
      }
    });
  };

  useEffect(() => {
    init();
  }, []);

  const {
    handleSubmit,
    register,
    setValue,
    // formState: { errors },
  } = useForm();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submit = async (dataForm: any) => {
    setLoading(true);
    const date = dataForm.day;
    const professional = dataForm.professional;

    const appoints = await findStatus({
      date,
      professional,
      status: ['ABERTO', 'CANCELADO'],
    });
    // console.log('appoints', appoints);
    setData(appoints);
    setLoading(false);
    setFormLocalStorage(APPOINTMENTS_DELETE_DAY, date);
    setFormLocalStorage(APPOINTMENTS_DELETE_PROFESSIONAL, professional);
  };

  const removeSelecteds = async () => {
    if (selected.current.length === 0) return;
    console.log(selected.current);
    const deleted = selected.current;
    for await (const el of deleted) {
      del(el.id);
    }
    self.location.reload();
  };

  return (
    <div
      className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 overflow-hidden`}
    >
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/appointments')}>Atendimentos</a>
          </li>
          <li>Exclusão de Atendimentos</li>
        </ul>
      </div>
      <div className={`flex my-3 p-1`}>
        <form onSubmit={handleSubmit(submit)}>
          <div className="form-control">
            <div className="relative flex">
              <input
                type="date"
                placeholder="Data"
                className="w-full rounded-r-none input input-gray-500 bg-gray-100 input-sm"
                {...register('day', { required: false })}
              />
              <select
                {...register('professional', { required: true })}
                className="select bg-gray-100 select-sm input-sm rounded-l-none pr-36"
              >
                <option value="">Selecione um Profissional</option>
                {professionals.map((el) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </select>
              <button
                type="submit"
                className="absolute top-0 right-0 rounded-l-none btn btn-sm btn-gray-500"
              >
                Buscar
              </button>
            </div>
          </div>
        </form>
      </div>
      {loading && <Loading />}
      {!loading && (
        <div className="">
          <Table
            columns={[
              {
                Header: 'Ordem',
                accessor: 'order',
              },
              {
                Header: 'Status',
                accessor: 'status',
              },
              {
                Header: 'Procedimento',
                accessor: 'procedure.name',
              },
              {
                Header: 'Paciente',
                accessor: 'patient.name',
              },
              {
                Header: 'Sala',
                accessor: 'room',
              },
            ]}
            data={data}
            callback={(data: any) => {
              selected.current = data.selected;
            }}
          />
          <button
            type="submit"
            className="rounded-xl btn btn-sm btn-gray-500"
            onClick={removeSelecteds}
          >
            Remover Selecionados
          </button>
        </div>
      )}
    </div>
  );
};

export default AppAppointmensDelete;
