/* eslint-disable react/jsx-filename-extension */
import {
  PencilAltIcon,
  PlusCircleIcon,
} from '@heroicons/react/outline';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import history from '../../history';
import { Specialty } from './specialties.model';
import { validateToken } from '../../services/app.service';
import { list } from './specialties.service';

const AppCadSpecialties: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Specialty[]>([]);
  const init = async () => {
    validateToken();
    const data = await list();
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 z-0`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li className="text-primary">Cadastro de Especialidades</li>
        </ul>
      </div>
      <div className="overflow-x-auto">
        <table className="table w-full z-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th className="flex flex-col items-end">
                <Link to={'/cad-specialties-form'}>
                  <span className="flex items hover:opacity-50">
                    <PlusCircleIcon className="w-5 mr-2" /> Inserir
                  </span>
                </Link>
              </th>
            </tr>
          </thead>
          {loading && (
            <tbody>
              <tr>
                <td colSpan={3}>
                  <Loading />
                </td>
              </tr>
            </tbody>
          )}
          {!loading && (
            <tbody>
              {data.map((el, index) => (
                <tr key={el.id}>
                  <th>{index + 1}</th>
                  <td>{el.name}</td>
                  <td className="flex flex-row justify-end">
                    <button
                      className="hover:opacity-70"
                      onClick={() => history.push('/cad-specialties-form', el.id)}
                    >
                      <PencilAltIcon className="h-6" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default AppCadSpecialties;
