/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '../../axios';
import { WaitingList } from './waitingList.model';

export async function list(): Promise<WaitingList[]> {
  const url = '/waitingList';
  try {
    const ret = await api.get<WaitingList[]>(url);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function getById(id: string): Promise<WaitingList> {
  const url = '/waitingList/' + id;
  try {
    const ret = await api.get<WaitingList>(url);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function del(id: string): Promise<any> {
  const url = '/waitingList/' + id;
  try {
    const ret = await api.delete<any>(url);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}


export async function findWhereIn(ids: string[]): Promise<WaitingList[]> {
  const url = '/waitingList/find-where-in';
  try {
    const ret = await api.post<WaitingList[]>(url, { ids });
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function save(data: WaitingList): Promise<any> {
  const url = '/waitingList';
  if (data.id) {
    return api
      .put<WaitingList>(url, data)
      .then((value) => value)
      .catch((e: Error) => e.message);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...post } = data;
    return api
      .post<WaitingList>(url, post)
      .then((value) => value)
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          return error.response;
        } else if (error.request) {
          return error.request;
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      });
  }
}
