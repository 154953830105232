/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import {
  getFormLocalStorage,
  setFormLocalStorage,
  validateToken,
} from '../../services/app.service';
import { findAfterNow, findAfterNowSpecialty } from './appointments.service';
import { Appointment } from './appointments.model';
import TableAppointments from './TableAppointments';
import { list as listProfessionals } from '../professionals/professionals.service';
import { list as listSpecialty } from '../specialties/specialties.service';
import { Professional } from '../professionals/professionals.model';
import { useForm } from 'react-hook-form';
import { Specialty } from '../specialties/specialties.model';
import { useLocation } from 'react-router-dom';

const APPOINTMENTS_SCHEN_CONF_SPECIALTY = 'AppointmentsSchenConfSpecialty';
const APPOINTMENTS_SCHEN_CONF_PROFESSIONAL =
  'AppointmentsSchenConfProfessional';

type LocationType = {
  cel?: string;
  ret?: string;
};

const AppAppointmensSchendulingConfirmation: React.FC = () => {
  const location = useLocation<LocationType>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Appointment[]>([]);
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const [specialties, setSpecialties] = useState<Specialty[]>([]);
  const { handleSubmit, register, setValue } = useForm();
  const {
    handleSubmit: handleSubmit2,
    register: register2,
    setValue: setValue2,
  } = useForm();

  const init = async () => {
    validateToken();
    await listProfessionals().then(setProfessionals);
    await listSpecialty().then(setSpecialties);
    if (getFormLocalStorage(APPOINTMENTS_SCHEN_CONF_SPECIALTY)) {
      setValue2(
        'specialty',
        getFormLocalStorage(APPOINTMENTS_SCHEN_CONF_SPECIALTY)
      );
    }
    if (getFormLocalStorage(APPOINTMENTS_SCHEN_CONF_PROFESSIONAL)) {
      setValue(
        'professional',
        getFormLocalStorage(APPOINTMENTS_SCHEN_CONF_PROFESSIONAL)
      );
    }
  };

  useEffect(() => {
    init();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchDateProfessional = (data: any) => {
    setLoading(true);
    setFormLocalStorage(
      APPOINTMENTS_SCHEN_CONF_PROFESSIONAL,
      data.professional
    );
    if (!data.professional) {
      setLoading(false);
      setData([]);
      return;
    }

    findAfterNow(data.professional).then((data) => {
      setData(data);
      setLoading(false);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchDateSpecialties = (data: any) => {
    setLoading(true);
    setFormLocalStorage(APPOINTMENTS_SCHEN_CONF_SPECIALTY, data.specialty);
    if (!data.specialty) {
      setLoading(false);
      setData([]);
      return;
    }

    findAfterNowSpecialty(data.specialty).then((data) => {
      setData(data);
      setLoading(false);
    });
  };

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>Agendamentos e Confirmação</li>
        </ul>
      </div>
      <div className="my-5 flex justify-between">
        <form onSubmit={handleSubmit(searchDateProfessional)}>
          <div className="form-control flex flex-row justify-start">
            <select
              {...register('professional', { required: true })}
              className="select bg-gray-100 select-sm input-sm rounded-r-none pr-36"
            >
              <option value="">Selecione um profissional</option>
              {professionals.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
            </select>
            <button
              type="submit"
              className="rounded-l-none btn btn-sm btn-gray-500"
            >
              Buscar
            </button>
          </div>
        </form>
        <form onSubmit={handleSubmit2(searchDateSpecialties)}>
          <div className="form-control flex flex-row justify-start">
            <select
              {...register2('specialty', { required: true })}
              className="select bg-gray-100 select-sm input-sm rounded-r-none pr-36"
            >
              <option value="">Selecione uma especialidade</option>
              {specialties.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
            </select>
            <button
              type="submit"
              className="rounded-l-none btn btn-sm btn-gray-500"
            >
              Buscar
            </button>
          </div>
        </form>
      </div>
      <TableAppointments data={data} loading={loading} cel={location.state?.cel} ret={location.state?.ret} />
    </div>
  );
};

export default AppAppointmensSchendulingConfirmation;
