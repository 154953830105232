/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { find } from './appointments.service';
import { Appointment } from './appointments.model';
import AppManagerAppointments from './AppManagerAppointments';
import { validateToken } from '../../services/app.service';

interface IContext {
  loading: boolean;
  data: Appointment[];
  professionalGroup: IProfessionalGoup;
  searchDateProfessional: (date: string, professional: string) => void;
}

interface IGroup {
  prof: string;
  count: number;
  profId: string;
}

interface IProfessionalGoup {
  inner: string[];
  group: IGroup[];
}
const Context = createContext<IContext>({} as IContext);

const Provider = ({ children }: any) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Appointment[]>([]);
  const [professionalGroup, setProfessionalGroup] =
    useState<IProfessionalGoup>({} as IProfessionalGoup);

  const searchDateProfessional = (date: string, professional: string) => {
    setLoading(true);
    if (!date) {
      setLoading(false);
      return;
    }
    const send = {};
    if (date) Object.assign(send, { date });
    if (professional) Object.assign(send, { professional });

    find({ ...send }).then((data) => {
      const order = data;
      const group = order.reverse().reduce(
        (acc: IProfessionalGoup, cur: Appointment) => {
          const prof = cur.professional.id;
          if (!acc.inner.includes(prof)) {
            acc.inner.push(prof);
            acc.group.push({
              prof: cur.professional.name,
              count: cur.order,
              profId: cur.professional.id,
            });
          }
          return acc;
        },
        { inner: [], group: [] }
      );
      setProfessionalGroup(group);
      setData(data.reverse());
      setLoading(false);
    });
  };

  return (
    <Context.Provider
      value={{ loading, data, professionalGroup, searchDateProfessional }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAppointment = (): IContext => {
  const context = useContext(Context);

  return context;
};

const AppAppointments: React.FC = () => {
  const init = async () => {
    validateToken();
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <Provider>
      <AppManagerAppointments />
    </Provider>
  );
};

export default AppAppointments;
