/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import {
  getFormLocalStorage,
  setFormLocalStorage,
  validateToken,
} from '../../services/app.service';
import history from '../../history';
import Loading from '../../components/Loading';
import { useForm } from 'react-hook-form';
import { list } from '../professionals/professionals.service';
import { Professional } from '../professionals/professionals.model';
import { findStatus, update } from './appointments.service';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { addTime } from '../../utils/datetimeHandler';

const APPOINTMENTS_DAY = 'AppointmentsReorderDay';
const APPOINTMENTS_PROFESSIONAL = 'AppointmentsReorderProfessional';

const AppAppointmensReorder: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [professionals, setProfessionals] = useState<Professional[]>([]);

  const [data, setData] = useState<any[]>([]);

  const init = async () => {
    validateToken();
    list().then((data) => {
      setProfessionals(data);
      if (getFormLocalStorage(APPOINTMENTS_DAY)) {
        setValue('day', getFormLocalStorage(APPOINTMENTS_DAY));
      }
      if (getFormLocalStorage(APPOINTMENTS_PROFESSIONAL)) {
        setValue(
          'professional',
          getFormLocalStorage(APPOINTMENTS_PROFESSIONAL)
        );
      }
    });
  };

  useEffect(() => {
    init();
  }, []);

  const {
    handleSubmit,
    register,
    setValue,
    // formState: { errors },
  } = useForm();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submit = async (dataForm: any) => {
    setLoading(true);
    const date = dataForm.day;
    const professional = dataForm.professional;

    const appoints = await findStatus({
      date,
      professional,
      status: ['ABERTO', 'AGENDADO', 'ATENDIDO', 'CANCELADO'],
    });
    // console.log('appoints', appoints);
    setData(appoints);
    setLoading(false);
    setFormLocalStorage(APPOINTMENTS_DAY, date);
    setFormLocalStorage(APPOINTMENTS_PROFESSIONAL, professional);
  };

  const reorderAll = async () => {
    if (data.length === 0) return;
    for (let index = 0; index < data.length; index++) {
      data[index]['order'] = index + 1;
      await update(data[index]);
    }
    self.location.reload();
  };

  const reorder = (
    list: Iterable<unknown> | ArrayLike<unknown>,
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 2;

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',
    color: 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: '100%',
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(data, result.source.index, result.destination.index);

    setData(items);
  };

  return (
    <div
      className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 overflow-hidden`}
    >
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/appointments')}>Atendimentos</a>
          </li>
          <li>Reordenação de Atendimentos</li>
        </ul>
      </div>
      <div className={`flex my-3 p-1`}>
        <form onSubmit={handleSubmit(submit)}>
          <div className="form-control">
            <div className="relative flex">
              <input
                type="date"
                placeholder="Data"
                className="w-full rounded-r-none input input-gray-500 bg-gray-100 input-sm"
                {...register('day', { required: false })}
              />
              <select
                {...register('professional', { required: true })}
                className="select bg-gray-100 select-sm input-sm rounded-l-none pr-36"
              >
                <option value="">Selecione um Profissional</option>
                {professionals.map((el) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </select>
              <button
                type="submit"
                className="absolute top-0 right-0 rounded-l-none btn btn-sm btn-gray-500"
              >
                Buscar
              </button>
            </div>
          </div>
        </form>
      </div>
      {loading && <Loading />}
      {!loading && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {data.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div className="flex space-x-2">
                          <span className="w-6 text-center bg-green-300 p-1">
                            {index + 1}
                          </span>
                          <span>-</span>
                          <span className="w-24">{item.status}</span>
                          <span>Previsão: </span>
                          <span className="w-14 text-center">
                            {item.procedure
                              ? addTime(
                                  item.appointmentBegin,
                                  item.procedure.estimatedTime.toString(),
                                  item.order - 1
                                )
                              : 'N/D'}
                          </span>
                          <span>Sala: </span>
                          <span className="w-6 text-center">{item.room}</span>
                          <span>Procedimento: </span>
                          <span className="w-28 text-left truncate overflow-ellipsis">
                            {item.procedure?.name || 'N/D'}
                          </span>
                          <span className="w-6 text-center bg-red-300 p-1">
                            {item.order}
                          </span>
                          <span>Paciente: </span>
                          <span className="truncate overflow-ellipsis">
                            {item.patient?.name || 'N/D'}
                          </span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {data.length > 0 && (
        <div className="flex space-x-2 items-center mt-3">
          <button
            type="submit"
            className="rounded-xl btn btn-sm btn-gray-500"
            onClick={reorderAll}
          >
            Reordenar
          </button>
        </div>
      )}
    </div>
  );
};

export default AppAppointmensReorder;
