/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef, useState } from 'react';
import {
  getFormLocalStorage,
  setFormLocalStorage,
  validateToken,
} from '../../services/app.service';
import history from '../../history';
import Loading from '../../components/Loading';
import { useForm } from 'react-hook-form';
import { list } from '../professionals/professionals.service';
import { Professional } from '../professionals/professionals.model';
import { findStatus, transfer } from './appointments.service';
import Table from '../../components/SelectedTable';

const APPOINTMENTS_DAY = 'AppointmentsTranferDay';
const APPOINTMENTS_PROFESSIONAL = 'AppointmentsTranferProfessional';

const AppAppointmensTransfer: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [professionals, setProfessionals] = useState<Professional[]>([]);

  const [data, setData] = useState<any[]>([]);
  const selected = useRef<any[]>([]);

  const [newDate, setNewDate] = useState('');
  const [newAppointmentBegin, setNewAppointmentBegin] = useState('');
  const [newRoom, setNewRoom] = useState('');

  const init = async () => {
    validateToken();
    list().then((data) => {
      setProfessionals(data);
      if (getFormLocalStorage(APPOINTMENTS_DAY)) {
        setValue('day', getFormLocalStorage(APPOINTMENTS_DAY));
      }
      if (getFormLocalStorage(APPOINTMENTS_PROFESSIONAL)) {
        setValue(
          'professional',
          getFormLocalStorage(APPOINTMENTS_PROFESSIONAL)
        );
      }
    });
  };

  useEffect(() => {
    init();
  }, []);

  const {
    handleSubmit,
    register,
    setValue,
    // formState: { errors },
  } = useForm();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submit = async (dataForm: any) => {
    setLoading(true);
    const date = dataForm.day;
    const professional = dataForm.professional;

    const appoints = await findStatus({
      date,
      professional,
      status: ['ABERTO', 'AGENDADO'],
    });
    // console.log('appoints', appoints);
    setData(appoints);
    setLoading(false);
    setFormLocalStorage(APPOINTMENTS_DAY, date);
    setFormLocalStorage(APPOINTMENTS_PROFESSIONAL, professional);
  };

  const transferSelecteds = async () => {
    if (selected.current.length === 0) return;
    if (!newDate && !newRoom && !newAppointmentBegin) return;
    if (newDate && new Date(newDate) < new Date()) return;
    console.log(selected.current);
    const data = selected.current;
    for await (const el of data) {
      if (newDate) el.date = newDate;
      if (newAppointmentBegin) el.appointmentBegin = newAppointmentBegin;
      if (newRoom) el.room = newRoom;
      transfer(el);
    }
    self.location.reload();
  };

  return (
    <div
      className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 overflow-hidden`}
    >
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/appointments')}>Atendimentos</a>
          </li>
          <li>Tranferência de Atendimentos</li>
        </ul>
      </div>
      <div className="text-2xs text-gray-300">
        Apenas atendimentos: ABERTO OU AGENDADO
      </div>
      <div className={`flex my-3 p-1`}>
        <form onSubmit={handleSubmit(submit)}>
          <div className="form-control">
            <div className="relative flex">
              <input
                type="date"
                placeholder="Data"
                className="w-full rounded-r-none input input-gray-500 bg-gray-100 input-sm"
                {...register('day', { required: false })}
              />
              <select
                {...register('professional', { required: true })}
                className="select bg-gray-100 select-sm input-sm rounded-l-none pr-36"
              >
                <option value="">Selecione um Profissional</option>
                {professionals.map((el) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </select>
              <button
                type="submit"
                className="absolute top-0 right-0 rounded-l-none btn btn-sm btn-gray-500"
              >
                Buscar
              </button>
            </div>
          </div>
        </form>
      </div>
      {loading && <Loading />}
      {!loading && (
        <div className="">
          <Table
            columns={[
              {
                Header: 'Ordem',
                accessor: 'order',
              },
              {
                Header: 'Status',
                accessor: 'status',
              },
              {
                Header: 'H. Inícial',
                accessor: 'appointmentBegin',
              },
              {
                Header: 'Procedimento',
                accessor: 'procedure.name',
              },
              {
                Header: 'Paciente',
                accessor: 'patient.name',
              },
              {
                Header: 'Sala',
                accessor: 'room',
              },
            ]}
            data={data}
            callback={(data: any) => {
              selected.current = data.selected;
            }}
          />
          {data.length > 0 && (
            <div className="flex space-x-2 items-center">
              <span className="text-sm">Nova Data:</span>
              <input
                type="date"
                className="m-1 rounded input input-gray-500 bg-gray-100 input-sm"
                value={newDate}
                onChange={(e) => setNewDate(e.target.value)}
              />
              <span className="text-sm">Novo Horário:</span>
              <input
                type="time"
                className="m-1 rounded input input-gray-500 bg-gray-100 input-sm"
                value={newAppointmentBegin}
                onChange={(e) => setNewAppointmentBegin(e.target.value)}
              />
              <span className="text-sm">Nova Sala:</span>
              <input
                type="number"
                className="m-1 rounded input input-gray-500 bg-gray-100 input-sm"
                value={newRoom}
                onChange={(e) => setNewRoom(e.target.value)}
              />
              <button
                type="submit"
                className="rounded-xl btn btn-sm btn-gray-500"
                onClick={transferSelecteds}
              >
                Tranferir Selecionados
              </button>
            </div>
          )}
        </div>
      )}
      <div className="h-20"></div>
    </div>
  );
};

export default AppAppointmensTransfer;
