import { createContext, useContext, useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Initial } from './initial';
import { Finist } from './finist';
import { Patient } from '../../patients/patients.model';
import { Professional } from '../../professionals/professionals.model';
import {
  find,
  getById as getByIdPatient,
} from '../../patients/patients.service';
import {
  list as professionalsList,
  getById as getByIdProfessional,
} from '../../professionals/professionals.service';
import { IRecord } from '../record.model';
import { getRecord, setRecord as createRecord } from '../record.service';

export interface IStepMakeRecordContext {
  loading: boolean;
  cel: string | null;
  setCel: (cel: string | null) => void;
  patient: Patient | null;
  setPatient: (patientId: string) => void;
  patients: Patient[];
  professional: Professional | null;
  setProfessional: (professionalId: string) => void;
  professionals: Professional[];
  makeRecord: () => void;
  record: IRecord | null;
  clearRecord: () => void;
}

const StepMakeRecordContext = createContext<IStepMakeRecordContext>(
  {} as IStepMakeRecordContext
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProviderStepMakeRecord = ({ children }: any) => {
  const [loading, setLoading] = useState(false);
  const [cel, setCel] = useState<string | null>(null);
  const [patient, _setPatient] = useState<Patient | null>(null);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [professional, _setProfessional] = useState<Professional | null>(null);
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const [record, setRecord] = useState<IRecord | null>(null);

  useEffect(() => {
    setLoading(true);
    professionalsList().then((data) => {
      setProfessionals(data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!cel) return;
    setLoading(true);
    find({ cel }).then((data) => {
      if (data) setPatients(data);
      setLoading(false);
    });
  }, [cel]);

  const setPatient = (patientId: string) => {
    setLoading(true);
    getByIdPatient(patientId)
      .then((data) => {
        _setPatient(data);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(() => {
        _setPatient(null);
      });
  };

  const setProfessional = (professionalId: string): void => {
    setLoading(true);
    getByIdProfessional(professionalId)
      .then((data) => {
        _setProfessional(data);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(() => {
        _setProfessional(null);
      });
  };

  const makeRecord = async () => {
    setLoading(true);
    if (!patient || !professional) return;
    const rec = await getRecord(professional.id, patient.id);
    if (rec) {
      setRecord(rec);
      setLoading(false);
    } else {
      const newRec = await createRecord(professional.id, patient.id);
      setRecord(newRec);
      setLoading(false);
    }
  };

  const clearRecord = () => {
    setRecord(null);
  }

  return (
    <StepMakeRecordContext.Provider
      value={{
        loading,
        cel,
        setCel,
        patient,
        setPatient,
        patients,
        professional,
        setProfessional,
        professionals,
        makeRecord,
        record,
        clearRecord,
      }}
    >
      {children}
    </StepMakeRecordContext.Provider>
  );
};

const useStepMakeRecord = (): IStepMakeRecordContext => {
  const context = useContext(StepMakeRecordContext);

  return context;
};

const AppStepMakeRecord: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <ProviderStepMakeRecord>
      <Switch>
        <Route exact path={path}>
          <Initial />
        </Route>
        <Route path={`${path}/finist`}>
          <Finist />
        </Route>
      </Switch>
    </ProviderStepMakeRecord>
  );
};

export { AppStepMakeRecord, useStepMakeRecord };
