/* eslint-disable @typescript-eslint/no-explicit-any */
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react';
import { Disclosure, Menu, Popover, Transition } from '@headlessui/react';
import {
  BellIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  MailIcon,
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline';
import { Link, useLocation } from 'react-router-dom';
import { getAuthUser, logoff } from '../services/app.service';
import { classNames } from '../utils/tailwindcss';
import history from '../history';
import noImgUser from '../assets/svg/user.svg';
import { useSocket } from '../hooks/socket.context';
import WhatsappIconSVG from '../assets/svg/iconmonstr-whatsapp-1.svg';

type NavItensProps = {
  mobile: boolean;
};

const NavItems: React.FC<NavItensProps> = ({ mobile }: NavItensProps) => {
  const location = useLocation();

  return (
    <>
      {/* dashboard */}
      <button
        className={
          mobile
            ? classNames(
                location.pathname === '/'
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'block px-3 py-2 rounded-md text-base font-medium hover:bg-opacity-70'
              )
            : classNames(
                location.pathname === '/'
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'px-3 py-2 rounded-md text-sm font-medium hover:bg-opacity-70'
              )
        }
        aria-current={location.pathname === '/' ? 'page' : undefined}
        onClick={() => history.push('/')}
      >
        Dashboard
      </button>

      {/* cadastros */}
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={classNames(
              location.pathname.includes('/cad')
                ? 'inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-gray-900 rounded-md hover:bg-opacity-70 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                : 'inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md bg-opacity-20 hover:bg-opacity-70 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
            )}
          >
            Cadastros
            <ChevronDownIcon
              className="w-5 h-5 ml-2 -mr-1 text-white-200 hover:text-white-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 w-56 mt-2 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/cad-institutional'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/cad-institutional')}
                  >
                    Institucional
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/cad-users'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/cad-users')}
                  >
                    Usuários
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/cad-specialties'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/cad-specialties')}
                  >
                    Especialidades
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/cad-professionals'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/cad-professionals')}
                  >
                    Profissionais
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/cad-covenants'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/cad-covenants')}
                  >
                    Convênios
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/cad-procedures'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/cad-procedures')}
                  >
                    Procedimentos
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/cad-recommendations'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/cad-recommendations')}
                  >
                    Recomendações
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/cad-presetChats'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/cad-presetChats')}
                  >
                    Mensagens Pré-definidas
                  </button>
                )}
              </Menu.Item>
              {/* site */}
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button
                    className={classNames(
                      location.pathname.includes('/site')
                        ? 'inline-flex flex-row justify-between w-full px-4 py-2 text-sm font-medium rounded-md hover:bg-opacity-70 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                        : 'inline-flex flex-row justify-between w-full px-4 py-2 text-sm font-medium rounded-md bg-opacity-20 hover:bg-opacity-70 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                    )}
                  >
                    <span>Site</span>
                    <ChevronRightIcon
                      className="w-5 h-5 ml-2 -mr-1 hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-48 -top-2 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`
                    ${
                      active || location.pathname === '/site-banners'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            onClick={() => history.push('/site-banners')}
                          >
                            Banners
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      {/* pacientes */}
      <button
        className={
          mobile
            ? classNames(
                location.pathname === '/patients'
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'block px-3 py-2 rounded-md text-base font-medium hover:bg-opacity-70'
              )
            : classNames(
                location.pathname === '/patients'
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'px-3 py-2 rounded-md text-sm font-medium hover:bg-opacity-70'
              )
        }
        aria-current={location.pathname === '/patients' ? 'page' : undefined}
        onClick={() => history.push('/patients')}
      >
        Pacientes
      </button>

      {/* prontuarios */}
      <button
        className={
          mobile
            ? classNames(
                location.pathname === '/record'
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'block px-3 py-2 rounded-md text-base font-medium hover:bg-opacity-70'
              )
            : classNames(
                location.pathname === '/record'
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'px-3 py-2 rounded-md text-sm font-medium hover:bg-opacity-70'
              )
        }
        aria-current={location.pathname === '/record' ? 'page' : undefined}
        onClick={() => history.push('/record')}
      >
        Prontuários
      </button>

      {/* atendimentos */}
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={classNames(
              location.pathname.includes('/appointments')
                ? 'inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-gray-900 rounded-md hover:bg-opacity-70 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                : 'inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md bg-opacity-20 hover:bg-opacity-70 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
            )}
          >
            Atendimentos
            <ChevronDownIcon
              className="w-5 h-5 ml-2 -mr-1 text-white-200 hover:text-white-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 w-56 mt-2 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/appointments-make'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/appointments-make')}
                  >
                    Gerar
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/appointments-make-lote'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/appointments-make-lote')}
                  >
                    Gerar em Lote
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/appointments'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/appointments')}
                  >
                    Gerenciar
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/waiting-list'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/waiting-list')}
                  >
                    Lista de Espera
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active ||
                      location.pathname ===
                        '/appointments-schenduling-confirmation'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() =>
                      history.push('/appointments-schenduling-confirmation')
                    }
                  >
                    Agendar/Confirmar
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/appointments-transfer'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/appointments-transfer')}
                  >
                    Transferir
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/appointments-reorder'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/appointments-reorder')}
                  >
                    Reordenar
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/appointments-delete'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/appointments-delete')}
                  >
                    Exclusão
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/appointments-map'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/appointments-map')}
                  >
                    Mapa de Agendamentos
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/appointments-whatsapp-confirm'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-left w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/appointments-whatsapp-confirm')}
                  >
                    Confirmação de Agendamentos Whatsapp
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      {/* relatorios */}
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={classNames(
              location.pathname.includes('/relat')
                ? 'inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-gray-900 rounded-md hover:bg-opacity-70 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                : 'inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md bg-opacity-20 hover:bg-opacity-70 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
            )}
          >
            Relatórios
            <ChevronDownIcon
              className="w-5 h-5 ml-2 -mr-1 text-white-200 hover:text-white-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 w-56 mt-2 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/relat-appointments'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-left w-full px-2 py-2 text-sm`}
                    onClick={() => history.push('/relat-appointments')}
                  >
                    Atendimentos
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active ||
                      location.pathname === '/relat-appointments-professional'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-left w-full px-2 py-2 text-sm`}
                    onClick={() =>
                      history.push('/relat-appointments-professional')
                    }
                  >
                    Atendimentos por Profissional
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active ||
                      location.pathname ===
                        '/relat-appointments-professionals-group'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-left w-full px-2 py-2 text-sm text-left`}
                    onClick={() =>
                      history.push('/relat-appointments-professionals-group')
                    }
                  >
                    Atendimentos Grupo/Profissionais
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active ||
                      location.pathname ===
                        '/relat-appointments-professionals-calc'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-left w-full px-2 py-2 text-sm text-left`}
                    onClick={() =>
                      history.push('/relat-appointments-professionals-calc')
                    }
                  >
                    Atendimentos Grupo/Profissionais/Diário
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active ||
                      location.pathname === '/relat-professionals-procedures'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-left w-full px-2 py-2 text-sm text-left`}
                    onClick={() =>
                      history.push('/relat-professionals-procedures')
                    }
                  >
                    Procedimentos por Profissionais
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active ||
                      location.pathname === '/relat-patient-appointments'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-left w-full px-2 py-2 text-sm text-left`}
                    onClick={() => history.push('/relat-patient-appointments')}
                  >
                    Atendimentos por Paciente
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active ||
                      location.pathname === '/relat-professional-waitingList'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-left w-full px-2 py-2 text-sm text-left`}
                    onClick={() =>
                      history.push('/relat-professional-waitingList')
                    }
                  >
                    Lista de espera por Profissional
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/relat-protocols'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-left w-full px-2 py-2 text-sm text-left`}
                    onClick={() => history.push('/relat-protocols')}
                  >
                    Atendimentos pelo Whatsapp
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/relat-analise-whatsapp'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-left w-full px-2 py-2 text-sm text-left`}
                    onClick={() => history.push('/relat-analise-whatsapp')}
                  >
                    Análise de Atendimentos por Whatsapp
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active || location.pathname === '/relat-analise-whatsapp'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-left w-full px-2 py-2 text-sm text-left`}
                    onClick={() => history.push('/relat-analise-whatsapp')}
                  >
                    Análise de Atendimentos por Whatsapp
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`
                    ${
                      active ||
                      location.pathname === '/relat-avaliacao-presencial'
                        ? 'bg-gray-100 text-gray-500'
                        : 'text-gray-900'
                    } group flex rounded-md items-left w-full px-2 py-2 text-sm text-left`}
                    onClick={() => history.push('/relat-avaliacao-presencial')}
                  >
                    Relatório de avaliação presencial
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

const AppNavbar: React.FC = () => {
  const user = getAuthUser();
  const { socket } = useSocket();
  const [qtdeTalks, setQtdeTalks] = useState<number>(0);
  const [qtdeChats, setQtdeChats] = useState<number>(0);
  const [status, setStatus] = useState<boolean>(false);

  useEffect(() => {
    const payload = {
      userId: user?.id,
    };
    socket.emit('get-status');
    socket.emit('talks-by-user', payload);
    socket.emit('chats-reload');
    socket.on('talks', (data) => {
      if (!user?.id) return;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const talks: any[] = data.payload.talks;
      setQtdeTalks(
        talks.filter((t) => t.readed === false && t.userId === user.id).length
      );
    });
    socket.on('chats', (data) => {
      setQtdeChats(data.filter((c: any) => c.status === 'closed').length);
    });
    socket.on('status', (data) => {
      setStatus(data.connected);
    });
    return () => {
      socket.off('talks');
      socket.off('chats');
      socket.off('status');
    };
  }, [socket]);

  return (
    <Disclosure as="nav" className="bg-gray-700 w-full fixed z-50">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <span className="font-bold text-2xl text-white">
                    {process.env.REACT_APP_TITLE || 'ADMIN'}
                  </span>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    <NavItems mobile={false} />
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* whatsapp */}
                <div className="max-w-xs px-4">
                  <button
                    onClick={() => history.push('/whatsapp')}
                    className={`${
                      status
                        ? 'bg-green-500 text-white'
                        : 'bg-gray-400 text-black'
                    } p-1 rounded-full  hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white indicator`}
                  >
                    <span className="sr-only">Whatsapp menu</span>
                    {status && (
                      <div className="indicator-item badge bg-red-500">
                        {qtdeChats}
                      </div>
                    )}
                    <img
                      className="h-6 w-6 stroke-current fill-current"
                      src={WhatsappIconSVG}
                      alt=""
                    />
                  </button>
                </div>
                {/* talks */}
                <div className="max-w-xs px-4">
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={`${
                            open
                              ? 'bg-gray-400 text-gray-800'
                              : 'bg-gray-800 text-gray-400'
                          } p-1 rounded-full  hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white indicator`}
                        >
                          <span className="sr-only">Talks menu</span>
                          <div className="indicator-item badge bg-red-500">
                            {qtdeTalks}
                          </div>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 md:max-w-xl">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className="relative bg-white p-7 flex flex-col space-y-3">
                                <button
                                  type="button"
                                  onClick={() => history.push('/talks')}
                                  className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                >
                                  <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-gray-600 sm:h-12 sm:w-12">
                                    <MailIcon />
                                  </div>
                                  <div className="ml-4 text-left">
                                    <p className="text-sm text-gray-900 font-bold">
                                      Recado(s) não lido(s):
                                    </p>
                                    <p className="text-sm text-gray-500">
                                      {qtdeTalks} recado(s)
                                    </p>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={`${process.env.REACT_APP_API_URL}/public/users/${user?.id}.png`}
                        alt=""
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src = noImgUser;
                        }}
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                      <div className="px-3 font-bold text-sm">{user?.name}</div>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/profile"
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Meu Perfil
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'w-full text-left text-red-300 block px-4 py-2 text-sm'
                            )}
                            onClick={logoff}
                          >
                            Sair
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <NavItems mobile={true} />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AppNavbar;
