/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import history from '../../history';
import { User } from './user.model';
import { validateToken } from '../../services/app.service';
import { getById, save } from './users.service';
import { SubmitButton } from '../../components/SubmitButton';

const AppCadUsersForm: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const [submiting, setSubmiting] = useState<boolean>(false);
  const [submited, setSubmited] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<[]>([]);

  const location = useLocation<string>();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const watchShowPassword = watch('id', false);

  const init = async () => {
    validateToken();
    const userId = location.state;
    console.log('userId', userId);
    if (userId) {
      const data = await getById(userId);
      const entries = Object.entries(data);
      entries.forEach((entry) => setValue(entry[0], entry[1]));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const onSubmit = (data: User) => {
    setSubmiting(true);
    if (data.isActive) data.isActive = true;
    else data.isActive = false;
    save(data)
      .then((ret) => {
        if (ret.data && ret.data.error) {
          setError(true);
          setErrorMsg(ret.data.message);
          setSubmited(false);
          setSubmiting(false);
          return;
        }
        if (ret.affected === 0) {
          setError(true);
          return;
        }
        setError(false);
        setSubmiting(false);
        setSubmited(true);
      })
      .catch((e) => {
        console.log('error', e);
        setError(true);
        setSubmited(false);
        setSubmiting(false);
      });
  };

  if (loading) {
    return (
      <div className="h-screen flex flex-col justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 my-20 z-0`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.goBack()}>Cadastro de Usuários</a>
          </li>
          <li className="text-primary">
            {watchShowPassword ? 'Editar Usuário' : 'Inserir Usuário'}
          </li>
        </ul>
      </div>
      <div className={`w-full`}>
        <form onSubmit={handleSubmit(onSubmit)} className="mb-3 w-full">
          <input type="hidden" {...register('id')} />
          <div className="form-control">
            <label className="label">
              <span className="label-text">Nome</span>
            </label>
            <input
              type="text"
              placeholder="Nome Completo"
              className="input input-bordered"
              {...register('name', { required: true })}
            />
            <span className="text-xs text-red-500">
              {errors.name && 'Campo requerido'}
            </span>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Usuário</span>
            </label>
            <input
              type="text"
              placeholder="Nome para o login"
              className="input input-bordered"
              {...register('user', { required: true })}
            />
            <span className="text-xs text-red-500">
              {errors.user && 'Campo requerido'}
            </span>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              type="text"
              placeholder="Email principal"
              className="input input-bordered"
              {...register('email', { required: true })}
            />
            <span className="text-xs text-red-500">
              {errors.email && 'Campo requerido'}
            </span>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">CEL</span>
            </label>
            <input
              type="text"
              placeholder="Celular apenas números"
              className="input input-bordered"
              {...register('cel', { required: true })}
            />
            <span className="text-xs text-red-500">
              {errors.cel && 'Campo requerido'}
            </span>
          </div>
          {!watchShowPassword && (
            <div className="form-control">
              <label className="label">
                <span className="label-text">SENHA</span>
              </label>
              <input
                type="password"
                placeholder="Digite sua senha"
                className="input input-bordered"
                {...register('password', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.password && 'Campo requerido'}
              </span>
            </div>
          )}
          <span className="label-text">Autorização</span>
          <div className="p-6 card bordered flex flex-row justify-between">
            <div className="form-control">
              <label className="cursor-pointer label">
                <input
                  type="radio"
                  {...register('auth', { required: true })}
                  className="radio radio-primary"
                  value="user"
                />
                <span className="label-text ml-3">Usuário</span>
              </label>
            </div>
            <div className="form-control">
              <label className="cursor-pointer label">
                <input
                  type="radio"
                  {...register('auth', { required: true })}
                  className="radio radio-primary"
                  value="admin"
                />
                <span className="label-text ml-3">Admin</span>
              </label>
            </div>
          </div>
          <div className="form-control">
            <label className="cursor-pointer label">
              <span className="label-text">Ativo</span>
              <input
                type="checkbox"
                {...register('isActive')}
                className="toggle toggle-primary"
                defaultChecked={true}
              />
            </label>
          </div>
          <div className="flex justify-between mt-3">
            <button
              className="btn bg-gray-500"
              onClick={() => history.goBack()}
            >
              Voltar
            </button>
            <SubmitButton caption="Salvar" submiting={submiting} />
          </div>
        </form>
        {submited && (
          <div className="alert alert-info">
            <div className="flex-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="w-6 h-6 mx-2 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <label>Registro salvo com sucesso!</label>
            </div>
          </div>
        )}
        {error && (
          <div className="alert alert-error flex flex-col items-start">
            <div className="flex-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="w-6 h-6 mx-2 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                ></path>
              </svg>
              <label>Error ao salvar registro</label>
            </div>
            <ul className="p-3">
              {errorMsg && errorMsg.map((err, idx) => <li key={idx}>{err}</li>)}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppCadUsersForm;
