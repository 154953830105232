import api from '../../axios';
import { IRecord } from "./record.model";

export const searchRecord = async (params: {
  professionalId: string;
  patientName: string;
}): Promise<IRecord[]> => {
  const url = '/records/find';

  const ret = await api.post<IRecord[]>(url, params);
  return ret.data;
};

export const getRecord = async (
  professionalId: string,
  patientId: string
): Promise<IRecord> => {
  const url = '/records/' + professionalId + '/' + patientId;
  const ret = await api.get<IRecord>(url);
  return ret.data;
};

export const setRecord = async (
  professionalId: string,
  patientId: string
): Promise<IRecord> => {
  const url = '/records';
  const ret = await api.post<IRecord>(url, {
    professional: professionalId,
    patient: patientId,
  });
  return ret.data;
};
