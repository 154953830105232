import { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { InitialWaitingListAdd } from './initialWaitingListAdd';
import { AnalizeWaitingListAdd } from './analizeWaitingListAdd';
import { FinistWaitingListAdd } from './finistWaitingListAdd';
import { WaitingListAddContext } from '../../../hooks/waitingListAdd.hook';

const WaitingListAdd: React.FC = () => {
  const { path } = useRouteMatch();
  const [data, setData] = useState(() => ({
    patient: null,
    professional: null,
    cel: '',
  }));

  return (
    <WaitingListAddContext.Provider value={{ ...data }}>
      <Switch>
        <Route exact path={path}>
          <InitialWaitingListAdd setValues={setData} />
        </Route>
        <Route path={`${path}/analize`}>
          <AnalizeWaitingListAdd setValues={setData}/>
        </Route>
        <Route path={`${path}/finist`}>
          <FinistWaitingListAdd setValues={setData}/>
        </Route>
      </Switch>
    </WaitingListAddContext.Provider>
  );
};

export default WaitingListAdd;
