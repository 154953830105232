/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import {
  BanIcon,
  ChevronUpIcon,
  EmojiHappyIcon,
  FolderOpenIcon,
  HeartIcon,
} from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { validateToken } from '../../services/app.service';
import { Appointment } from '../appointments/appointments.model';
import { find as searchAppointments } from '../appointments/appointments.service';
import { WaitingList } from '../waitingList/waitingList.model';
import { findWhereIn } from '../waitingList/waitingList.service';
import TableWaitingLists from '../waitingList/TableWaitingList';
import Loading from '../../components/Loading';
import { Professional } from '../professionals/professionals.model';
import { list as listProfessionals } from '../professionals/professionals.service';
import { Disclosure } from '@headlessui/react';

type TAppointmentsStatus = {
  ABERTO: number;
  AGENDADO: number;
  ATENDIDO: number;
  CANCELADO: number;
};

type TGroup = {
  inner: string[];
  group: any[];
};

const AppDashboard: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [dataWaitingList, setDataWaitingList] = useState<WaitingList[]>([]);

  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const [professionalFilter, setProfessionalFilter] = useState<string>('');

  const init = async () => {
    validateToken();

    // professional list
    await listProfessionals().then(setProfessionals);
    const dt = new Date();
    // eslint-disable-nextLine @typescript-eslint/no-inferrable-types
    const date = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
    const appointments = await searchAppointments({ date });
    const status: TAppointmentsStatus = {
      ABERTO: appointments.filter((el) => el.status === 'ABERTO').length,
      AGENDADO: appointments.filter((el) => el.status === 'AGENDADO').length,
      ATENDIDO: appointments.filter((el) => el.status === 'ATENDIDO').length,
      CANCELADO: appointments.filter((el) => el.status === 'CANCELADO').length,
    };
    const group: TGroup = await appointments.reverse().reduce(
      (acc: any, cur: Appointment) => {
        const prof = cur.professional.id;
        if (!acc.inner.includes(prof)) {
          acc.inner.push(prof);
          acc.group.push({ prof: cur.professional.name, count: cur.order });
        }
        return acc;
      },
      { inner: [], group: [] }
    );
    setAppointmentsStatus(status);
    setProfessionalGroup(group);

    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const [appointmentsStatus, setAppointmentsStatus] =
    useState<TAppointmentsStatus>();

  const [professionalGroup, setProfessionalGroup] = useState<any>();

  useEffect(() => {
    // lista de espera
    if (professionalGroup?.inner) {
      setLoading(true);
      findWhereIn(professionalGroup.inner)
        .then((wlist) => {
          if (professionalFilter) {
            setDataWaitingList(
              wlist.filter((el) => el.professionalId === professionalFilter)
            );
          } else {
            setDataWaitingList(wlist);
          }
        })
        .finally(() => setLoading(false));
    }
    // console.log(wlist);
  }, [professionalFilter, professionalGroup]);

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className={`maxW-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      {/* <div className="text-sm breadcrumbs">
        <ul>
          <li>Dashboard</li>
        </ul>
      </div> */}
      <div className="p-1">
        <h1 className="font-bold text-xl">Atendimentos de hoje</h1>
        <div className="w-full shadow stats">
          <div className="stat">
            <div className="stat-figure text-gray-500">
              <FolderOpenIcon className="w-10" />
            </div>
            <div className="stat-title">ABERTOS</div>
            <div className="stat-value text-gray-500">
              {appointmentsStatus?.ABERTO}
            </div>
          </div>
          <div className="stat">
            <div className="stat-figure text-primary">
              <HeartIcon className="w-10" />
            </div>
            <div className="stat-title">AGENDADOS</div>
            <div className="stat-value text-primary">
              {appointmentsStatus?.AGENDADO}
            </div>
          </div>
          <div className="stat">
            <div className="stat-figure text-green-300">
              <EmojiHappyIcon className="w-10" />
            </div>
            <div className="stat-title">ATENDIDOS</div>
            <div className="stat-value text-green-300">
              {appointmentsStatus?.ATENDIDO}
            </div>
          </div>
          <div className="stat">
            <div className="stat-figure text-red-300">
              <BanIcon className="w-10" />
            </div>
            <div className="stat-title">CANCELADOS</div>
            <div className="stat-value text-red-300">
              {appointmentsStatus?.CANCELADO}
            </div>
          </div>
        </div>
        <Disclosure>
          {({ open }) => (
            <div className='my-2'>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <span>Profissionais em Atendimento Hoje</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                <div className="flex flex-wrap bg-gray-100 rounded my-1">
                  {professionalGroup?.group?.map(
                    (
                      g: {
                        count:
                          | boolean
                          | React.ReactChild
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined;
                        prof:
                          | boolean
                          | React.ReactChild
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined;
                      },
                      i: React.Key | null | undefined
                    ) => (
                      <div key={i} className="m-6 indicator">
                        <div className="indicator-item badge badge-secondary">
                          {g.count}
                        </div>
                        <div className="grid p-1 m-1 rounded bg-base-300 place-items-center">
                          {g.prof}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <h1 className="font-bold text-xl flex justify-between">
          <span>Lista de espera dos profissionais de hoje:</span>
          {/* <span className="flex">
            <span>Filtro: </span>
            <select
              value={professionalFilter}
              onChange={(e) => setProfessionalFilter(e.target.value)}
              className="select bg-gray-100 select-sm input-sm pr-36"
            >
              <option value="">Todos os Profissionais</option>
              {professionals.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
            </select>
          </span> */}
        </h1>
        <TableWaitingLists data={dataWaitingList} loading={loading} />
      </div>
    </div>
  );
};

export default AppDashboard;
