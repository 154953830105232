/* eslint-disable @typescript-eslint/no-explicit-any */
export const arrayGroupBy = (array: any[], key: string): any[] => {
  const keys = key.split('.');
  const count = keys.length;
  try {
    switch (count) {
      case 1: {
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]]] = acc[cur[keys[0]]] || []).push(cur);
          return acc;
        }, []);
      }
      case 2:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]]] = acc[cur[keys[0]][keys[1]]] || []).push(
            cur
          );
          return acc;
        }, []);
      case 3:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]][keys[2]]] =
            acc[cur[keys[0]][keys[1]][keys[2]]] || []).push(cur);
          return acc;
        }, []);
      case 4:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]][keys[2]][keys[3]]] =
            acc[cur[keys[0]][keys[1]][keys[2]][keys[3]]] || []).push(cur);
          return acc;
        }, []);
      case 5:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]]] =
            acc[cur[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]]] || []).push(
              cur
            );
          return acc;
        }, []);
      default:
        return [];
    }
  } catch {
    return [];
  }
};

export function truncateString(str: string, num: number): string {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const replaceImgWithError = (e: any, imgHref?: string) => {
  e.target.onerror = null;
  e.target.src = imgHref || 'https://via.placeholder.com/100?text=Not Found';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const truncateStringWithEllipsis = (str: string, num: number) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

// converte data para o formato yyyy-mm-dd hh:mm:ss completando com zeros a esquerda
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const convertDate_dd_mm_yyyy_hh_mm_ss = (date: any) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = ('0' + (d.getMonth() + 1)).slice(-2);
  const day = ('0' + d.getDate()).slice(-2);
  const hours = ('0' + d.getHours()).slice(-2);
  const minutes = ('0' + d.getMinutes()).slice(-2);
  const seconds = ('0' + d.getSeconds()).slice(-2);
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
