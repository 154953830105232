import React from 'react';
import { useState } from 'react';
import { login } from '../services/app.service';
import { ReactSVG } from 'react-svg';
import { logoSVG } from '../utils/svgsMap';

const Initial: React.FC = () => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisabled(true);
    login(user, password)
      .then((data) => console.log('Data Login => ', data))
      .catch((e) => {
        console.log('Data Error => ', e);
        setError(e.message);
      })
      .finally(() => setDisabled(false));
  };

  // console.log(error);

  return (
    <div className="h-full bg-transparency-header bg-no-repeat">
      <div className="flex justify-center">
        <ReactSVG className="w-80 h-40" src={logoSVG} />
      </div>

      <h1 className="mt-10 text-center font-extrabold text-4xl mb-4">
        Acesso ao Sistema
      </h1>
      <div className="w-full max-w-xs mx-auto my-auto">
        <form
          className="px-8 pt-6 pb-8 mb-4"
          onSubmit={(e) => {
            submit(e);
          }}
        >
          <div className="mb-4">
            <label
              className={`
              flex flex-col
              text-primary
              text-sm
              mt-5
            `}
            >
              Usuário
              <input
                type="text"
                value={user}
                onChange={(e) => setUser(e.target.value)}
                placeholder="Digite seu usuário"
                className={`
                  p-2
                  rounded
                  bg-gray-200
                `}
              />
            </label>
          </div>

          <div className="mb-4">
            <label
              className={`
              flex flex-col
              text-primary
              text-sm
              mt-5
            `}
            >
              Senha
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Digite sua senha"
                className={`
                  p-2
                  rounded
                  bg-gray-200
                `}
              />
            </label>
          </div>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              color="DEFAULT"
              className={`
                w-full
                ${disabled ? 'bg-gray-400' : 'bg-primary'}
                py-1 px-3
                my-3 mx-auto
                rounded-full
                text-white
              `}
              disabled={disabled}
            >
              {disabled ? 'Aguarde...' : 'Entrar'}
            </button>
          </div>
          {error && <p className="text-red-500 text-xs italic">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default Initial;
