import { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { InitialWaitingListSchenduling } from './initialWaitingListSchenduling';
import { AnalizeWaitingListSchenduling } from './analizeWaitingListSchenduling';
import { FinistWaitingListSchenduling } from './finistWaitingListSchenduling';
import { WaitingListSchendulingContext } from '../../../hooks/waitingListSchenduling.hook';

const WaitingListSchenduling: React.FC = () => {
  const { path } = useRouteMatch();
  const [data, setData] = useState(() => ({
    patient: null,
    professional: null,
    appointment: null,
    waitingListId: '',
  }));

  return (
    <WaitingListSchendulingContext.Provider value={{ ...data }}>
      <Switch>
        <Route exact path={path}>
          <InitialWaitingListSchenduling setValues={setData} />
        </Route>
        <Route path={`${path}/analize`}>
          <AnalizeWaitingListSchenduling setValues={setData}/>
        </Route>
        <Route path={`${path}/finist`}>
          <FinistWaitingListSchenduling />
        </Route>
      </Switch>
    </WaitingListSchendulingContext.Provider>
  );
};

export default WaitingListSchenduling;
