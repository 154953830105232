/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import history from '../../history';
import { Patient } from './patients.model';
import { validateToken } from '../../services/app.service';
import { getById, save } from './patients.service';
import MaskedInput from 'react-text-mask';
import getMask from '../../utils/masks';
import { SubmitButton } from '../../components/SubmitButton';

const AppCadPatientsForm: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const [submiting, setSubmiting] = useState<boolean>(false);
  const [submited, setSubmited] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<[]>([]);
  const [documentType, setDocumentType] = useState<'CPF' | 'RG' | 'RN'>('CPF');

  const location = useLocation<string>();
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const init = async () => {
    validateToken();
    const patientId = location.state;
    // console.log('patientId', patientId);
    if (patientId) {
      const data = await getById(patientId);
      const entries = Object.entries(data);
      entries.forEach((entry) => setValue(entry[0], entry[1]));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const onSubmit = (data: Patient) => {
    setSubmiting(true);
    save(data)
      .then((ret) => {
        if (ret.data && ret.data.error) {
          setError(true);
          setErrorMsg(ret.data.message);
          setSubmited(false);
          setSubmiting(false);
          return;
        }
        if (ret.affected === 0) {
          setError(true);
          return;
        }
        setError(false);
        setSubmiting(false);
        setSubmited(true);
        // history.goBack();
      })
      .catch((e) => {
        console.log('error', e);
        setError(true);
        setSubmited(false);
        setSubmiting(false);
      });
  };

  if (loading) {
    return (
      <div className="h-screen flex flex-col justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className={`max-w-3xl mx-auto px-2 sm:px-6 lg:px-8 my-20 z-0`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.goBack()}>Cadastro de Pacientes</a>
          </li>
          <li className="text-primary">
            {location.state ? 'Editar Paciente' : 'Inserir Paciente'}
          </li>
        </ul>
      </div>
      <div className={`w-full`}>
        <form onSubmit={handleSubmit(onSubmit)} className="mb-3 w-full">
          <input type="hidden" {...register('id')} />
          {/* name */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Nome</span>
            </label>
            <input
              type="text"
              placeholder="Nome/Descrição"
              className="input input-bordered"
              {...register('name', { required: true })}
            />
            <span className="text-xs text-red-500">
              {errors.name && 'Campo requerido'}
            </span>
          </div>

          {/* document */}
          <div className="form-control">
            <div className="flex justify-between">
              <label className="cursor-pointer label">
                <input
                  type="radio"
                  name="opt"
                  checked={documentType === 'CPF'}
                  className="radio mr-1"
                  value="CPF"
                  onChange={() => setDocumentType('CPF')}
                />
                <span className="label-text">CPF</span>
              </label>
              <label className="cursor-pointer label">
                <input
                  type="radio"
                  name="opt"
                  checked={documentType === 'RG'}
                  className="radio mr-1"
                  value="RG"
                  onChange={() => setDocumentType('RG')}
                />
                <span className="label-text">RG</span>
              </label>
              <label className="cursor-pointer label">
                <input
                  type="radio"
                  name="opt"
                  checked={documentType === 'RN'}
                  className="radio mr-1"
                  value="RN"
                  onChange={() => setDocumentType('RN')}
                />
                <span className="label-text">RN</span>
              </label>
            </div>
            <Controller
              name="document"
              control={control}
              render={({ field }) => (
                <MaskedInput
                  mask={getMask(documentType.toLowerCase())}
                  type="text"
                  placeholder={documentType}
                  className="input input-bordered"
                  {...field}
                />
              )}
            />

            <span className="text-xs text-red-500">
              {errors.document && 'Campo requerido'}
            </span>
          </div>

          {/* cel */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Celular</span>
            </label>
            <Controller
              name="cel"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <MaskedInput
                  mask={getMask('celular')}
                  type="text"
                  placeholder="Celular"
                  className="input input-bordered"
                  {...field}
                />
              )}
            />
            <span className="text-xs text-red-500">
              {errors.cel && 'Campo requerido'}
            </span>
          </div>

          {/* cel2 */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Celular 2</span>
            </label>
            <Controller
              name="cel2"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <MaskedInput
                  mask={getMask('celular')}
                  type="text"
                  placeholder="Celular"
                  className="input input-bordered"
                  {...field}
                />
              )}
            />
            <span className="text-xs text-red-500">
              {errors.cel2 && 'Campo requerido'}
            </span>
          </div>

          {/* address */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Endereço</span>
            </label>
            <input
              type="text"
              placeholder="Endereço"
              className="input input-bordered"
              {...register('address', { required: false })}
            />
            <span className="text-xs text-red-500">
              {errors.address && 'Campo requerido'}
            </span>
          </div>

          {/* city */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Cidade</span>
            </label>
            <input
              type="text"
              placeholder="Cidade"
              className="input input-bordered"
              {...register('city', { required: false })}
            />
            <span className="text-xs text-red-500">
              {errors.city && 'Campo requerido'}
            </span>
          </div>

          {/* email */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              type="text"
              placeholder="Email"
              className="input input-bordered"
              {...register('email', { required: false })}
            />
            <span className="text-xs text-red-500">
              {errors.email && 'Campo requerido'}
            </span>
          </div>

          {/* dn */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Data de Nascimento</span>
            </label>
            <input
              type="date"
              placeholder="Data de Nascimento"
              className="input input-bordered"
              {...register('dn', { required: true })}
            />
            <span className="text-xs text-red-500">
              {errors.dn && 'Campo requerido'}
            </span>
          </div>

          {/* sexo */}
          <div className="flex flex-col py-1">
            <span className="label-text">Sexo</span>
            <div className="p-6 card bordered bg-gray-100 flex flex-row justify-between">
              <div className="form-control">
                <label className="cursor-pointer label">
                  <input
                    type="radio"
                    {...register('sexo', { required: true })}
                    className="radio radio-primary"
                    value="m"
                  />
                  <span className="label-text ml-3">Masculino</span>
                </label>
              </div>
              <div className="form-control">
                <label className="cursor-pointer label">
                  <input
                    type="radio"
                    {...register('sexo', { required: true })}
                    className="radio radio-primary"
                    value="f"
                  />
                  <span className="label-text ml-3">Feminino</span>
                </label>
              </div>
            </div>
            <span className="text-xs text-red-500">
              {errors.sexo && 'Campo requerido'}
            </span>
          </div>

          <div className="flex justify-between mt-3">
            <button
              className="btn bg-gray-500"
              onClick={() => history.push('/patients')}
            >
              Voltar
            </button>
            <SubmitButton caption="Salvar" submiting={submiting} />
          </div>
        </form>
        {submited && (
          <div className="alert alert-info">
            <div className="flex-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="w-6 h-6 mx-2 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <label>Registro salvo com sucesso!</label>
            </div>
          </div>
        )}
        {error && (
          <div className="alert alert-error flex flex-col items-start">
            <div className="flex-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="w-6 h-6 mx-2 stroke-current"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                ></path>
              </svg>
              <label>Error ao salvar registro</label>
            </div>
            <ul className="p-3">
              {errorMsg && errorMsg.map((err, idx) => <li key={idx}>{err}</li>)}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppCadPatientsForm;
