/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

interface ButtonConfirmBasicProps {
  type: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
  className: string;
  loading?: boolean;
  onClick?: () => void;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ButtonConfirmBasic = (props: ButtonConfirmBasicProps) => {
  const [confirmed, setConfirmed] = useState(false);

  const btnDefault = (
    <button
      // eslint-disable-next-line react/button-has-type
      type={props.type}
      className={props.className}
      onClick={() => setConfirmed(true)}
    >
      {props.children}
    </button>
  );

  const btnConfirm = (
    <div className={``}>
      <button
        type="button"
        className={`
          bg-red-600
          px-3
          rounded-tl-full
          rounded-bl-full
          text-white
          text-sm
        `}
        onClick={() => setConfirmed(false)}
      >
        Cancelar
      </button>
      <button
        // eslint-disable-next-line react/button-has-type
        type={props.type}
        className={`
          bg-green-400
          px-3
          rounded-tr-full
          rounded-br-full
          text-white
          text-sm
        `}
        onClick={props.onClick}
      >
        Confirmar
      </button>
    </div>
  );

  if (props.loading) {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={props.type}
        disabled={true}
        className={`
          bg-gray-200
          py-1 px-3
          my-3 mx-auto
          rounded-tr-full
          rounded-br-full
          text-gray-700
        `}
        onClick={props.onClick}
      >
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </button>
    );
  }

  return confirmed === false ? btnDefault : btnConfirm;
};

export default ButtonConfirmBasic;
