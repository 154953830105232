/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import history from '../../history';
import Loading from '../../components/Loading';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import moment from 'moment';
import { relatAppoinmentsProfessinalsGroup } from './appointments.service';
import { arrayGroupBy } from '../../utils/functions';
import { Appointment } from './appointments.model';
import { MONTS, YEARS } from '../../app.settings';
import { useForm } from 'react-hook-form';
// const localizer = momentLocalizer(moment);

interface IEvent {
  id: number;
  calendarId: string;
  title: string;
  category?: string;
  start: Date;
  end: Date;
}

const AppAppointmentsMap: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const calendars = [{ id: 'cal1', name: 'Personal' }];
  const [events, setEvents] = useState<IEvent[]>([]);
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const submit = (data: any) => {
    const dateBase = moment({
      year: parseInt(data.year),
      month: parseInt(data.month),
      day: 1,
    });
    // console.log(
    //   '🚀 ~ file: AppAppointmentsMap.tsx ~ line 48 ~ submit ~ dateBase',
    //   dateBase.toDate()
    // );
    const _start = dateBase.startOf('month').toDate();
    const _end = dateBase.endOf('month').toDate();
    // console.log({ _start, _end });
    setStart(_start);
    setEnd(_end);
  };
  useEffect(() => {
    setStart(undefined);
    setEnd(undefined);
  }, []);

  useEffect(() => {
    if (start && end) {
      setLoading(true);
      relatAppoinmentsProfessinalsGroup({
        dateIni: start,
        dateEnd: end,
        status: ['ABERTO', 'AGENDADO', 'ATENDIDO'],
      })
        .then((list) => {
          const data: IEvent[] = [];
          const map = list.map((ap: Appointment) => ({
            ...ap,
            day: moment(ap.date).format('YYYY-MM-DD'),
          }));
          const group = arrayGroupBy(map, 'day');
          const formatMap = Object.entries(group).map(
            ([day, professionais]) => ({
              day,
              professionais,
            })
          );

          formatMap.forEach(({ day, professionais }) => {
            professionais.forEach(
              (p: { professional_name: any; count: any }, idx: any) => {
                const event: IEvent = {
                  id: idx,
                  calendarId: 'cal1',
                  category: 'allday',
                  start: moment(day).toDate(),
                  end: moment(day).toDate(),
                  title: `${p.professional_name} ${p.count} atd(s)`,
                };

                data.push(event);
              }
            );
          });

          setEvents(data);
        })
        .finally(() => setLoading(false));
    }
  }, [start, end]);

  return (
    <div
      className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 overflow-hidden`}
    >
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/appointments')}>Atendimentos</a>
          </li>
          <li>Mapa de Atendimentos</li>
        </ul>
      </div>
      <div className="text-2xs text-gray-300">
        Apenas atendimentos: ABERTO OU AGENDADO
      </div>
      <form
        onSubmit={handleSubmit(submit)}
        className="flex space-x-4 justify-start items-center"
      >
        <div className="flex space-x-2">
          <label className="">
            <span className="label-text">Mês</span>
          </label>
          <select
            className="select select-xs select-bordered pr-6"
            {...register('month', { required: true })}
          >
            {MONTS.map((month, idx) => (
              <option key={idx} value={idx}>
                {month}
              </option>
            ))}
          </select>
          <span className="text-xs text-red-500">
            {errors.month && 'Campo requerido'}
          </span>
        </div>
        <div className="flex space-x-2">
          <label className="">
            <span className="label-text">Ano</span>
          </label>
          <select
            className="select select-xs select-bordered pr-8"
            {...register('year', { required: true })}
          >
            {YEARS.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <span className="text-xs text-red-500">
            {errors.year && 'Campo requerido'}
          </span>
        </div>
        <button className="btn btn-xs bg-gray-500" type="submit">
          Pesquisar
        </button>
      </form>

      {loading && <Loading />}
      {!loading && start && end && (
        <Calendar
          template={{
            milestone(event) {
              return `<span style="color:#fff;background-color: ${event.backgroundColor};">${event.title}</span>`;
            },
          }}
          view={'month'}
          calendars={calendars}
          events={events}
          gridSelection={false}
          useDetailPopup={true}
        />
      )}
      <div className="h-20"></div>
    </div>
  );
};

export default AppAppointmentsMap;
