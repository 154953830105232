/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import api from '../../axios';
import { Appointment } from './appointments.model';
import { method } from 'lodash';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function find(args: any): Promise<Appointment[]> {
  const url = '/appointments/find';
  try {
    const ret = await api.post<Appointment[]>(url, { ...args });
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function findStatus(args: any): Promise<Appointment[]> {
  const url = '/appointments/findStatus';
  try {
    const ret = await api.post<Appointment[]>(url, { ...args });
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function findById(id: string): Promise<Appointment> {
  const url = '/appointments/' + id;
  try {
    const ret = await api.get<Appointment>(url);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function findAfterNow(
  professional: string
): Promise<Appointment[]> {
  const url = '/appointments/find-after-now';
  try {
    const ret = await api.post<Appointment[]>(url, { professional });
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function findAfterNowSpecialty(
  specialty: string
): Promise<Appointment[]> {
  const url = '/appointments/find-after-now-specialty';
  try {
    const ret = await api.post<Appointment[]>(url, { specialty });
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function relatAppoinments(data: any): Promise<Appointment[]> {
  const url = '/appointments/relat-appointments';
  try {
    const ret = await api.post<Appointment[]>(url, data);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function relatAppoinmentsProfessinalsGroup(
  data: any
): Promise<any[]> {
  const url = '/appointments/relat-appointments-professionals-group';
  try {
    const ret = await api.post<any[]>(url, data);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function relatAppoinmentsProfessinalsCalcGroup(
  data: any
): Promise<any[]> {
  const { date } = data;
  const url = '/appointments/relat-appointments-professionals-calc';
  try {
    const ret = await api.post<any[]>(url, { date });
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function findAfterNowAberto(
  professional: string
): Promise<Appointment[]> {
  const url = '/appointments/find-after-now-aberto';
  try {
    const ret = await api.post<Appointment[]>(url, { professional });
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function search(args: any): Promise<Appointment[]> {
  const url = '/appointments/search';
  try {
    const ret = await api.post<Appointment[]>(url, { ...args });
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function transfer(newAppointment: Appointment): Promise<any> {
  const url = '/appointments/transfer';
  try {
    const ret = await api.post<Appointment[]>(url, newAppointment);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function findByDays(
  days: string[],
  professional: string
): Promise<Appointment[]> {
  const url = '/appointments/find-days-in';
  try {
    const ret = await api.post<Appointment[]>(url, { days, professional });
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function getById(id: string): Promise<Appointment> {
  const url = '/appointments/' + id;
  try {
    const ret = await api.get<Appointment>(url);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function create(data: any): Promise<any> {
  const url = '/appointments';
  return api
    .post<Appointment>(url, data)
    .then((value) => value)
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        return error.response;
      } else if (error.request) {
        return error.request;
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function save(data: Appointment): Promise<any> {
  const url = '/appointments';
  if (data.id) {
    return api
      .put<Appointment>(url, data)
      .then((value) => value)
      .catch((e: Error) => e.message);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...post } = data;
    return api
      .post<Appointment>(url, post)
      .then((value) => value)
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          return error.response;
        } else if (error.request) {
          return error.request;
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function update(data: any): Promise<any> {
  const url = '/appointments';
  return api
    .put<Appointment>(url, data)
    .then((value) => value)
    .catch((e: Error) => e.message);
}

export function blank(id: string): Promise<any> {
  const url = '/appointments/blank/' + id;
  return api
    .get<Appointment>(url)
    .then((value) => value)
    .catch((e: Error) => e.message);
}

export function del(id: string): Promise<any> {
  const url = '/appointments/' + id;
  return api
    .delete<Appointment>(url)
    .then((value) => value)
    .catch((e: Error) => e.message);
}

const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return <File>theBlob;
};

export function upload(
  id: string,
  image: Blob,
  onUploadProgress: (progressEvent: any) => void
): Promise<any> {
  const url = '/appointments/upload/' + id;
  const formData = new FormData();
  const imgUp = blobToFile(image, 'upload.jpg');
  formData.append('image', imgUp);
  return api.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: onUploadProgress,
  });
}

export async function incluirMessageTextWhatsapp(
  body: any
): Promise<any> {
  const url = '/appointments/send-message-text';

  try {
    const ret = await api.post(url, body);
    return ret.data;
  } catch (e) {
    console.log(e);
  }
}

export async function excluirMessageWhatsapp(
  codigoExterno: string
): Promise<any> {
  const url = '/appointments/delete-message-text';

  try {
    const ret = await api.post(url, { codigoExterno });
    return ret.data;
  } catch (e) {
    console.log(e);
  }
}

export async function situacaoMessageWhatsapp(
  codigoExterno: string
): Promise<any> {
  const url = '/appointments/status-message-text';

  try {
    const ret = await api.post(url, { codigoExterno });
    return ret.data;
  } catch (e) {
    console.log(e);
  }
}
