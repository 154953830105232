import React, { useEffect, useState } from 'react';
import { useMakeAppointment } from '../../../hooks/appointmentsMaking.hook';
import history from '../../../history';
import { getById } from '../../professionals/professionals.service';
import { Professional } from '../../professionals/professionals.model';
import { create } from '../appointments.service';
import {
  getFormatedDate,
  getFormatedDateWeek,
} from '../../../utils/datetimeHandler';

type FinistAppointmentMakeLoteProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValues: (values: any) => void;
};

export const FinistAppointmentMakeLote: React.FC<FinistAppointmentMakeLoteProps> =
  ({ setValues }: FinistAppointmentMakeLoteProps) => {
    const makeAppointment = useMakeAppointment();
    const [professional, setProfessional] = useState<Professional>();

    const init = async () => {
      const prof = await getById(makeAppointment.professional);
      console.log('prof ===>', prof);
      setProfessional(prof);
    };

    useEffect(() => {
      init();
    }, []);

    const toMake = async (): Promise<void> => {
      for await (const date of makeAppointment.loteDate) {
        const qtde = makeAppointment.qtde;
        for (let index = 0; index < qtde; index++) {
          if (professional)
            create({
              date,
              appointmentBegin: makeAppointment.appointmentBegin,
              room: makeAppointment.room,
              order: makeAppointment.orderInit + index,
              professional: makeAppointment.professional,
              value: 0,
              commission: 0,
            });
        }
      }
      history.push('/appointments');
    };

    const delDay = (idx: number) => {
      const days = makeAppointment.loteDate;
      days.splice(idx, 1);
      setValues({ ...makeAppointment, loteDate: days });
    };

    return (
      <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <a onClick={() => history.push('/appointments')}>Atendimentos</a>
            </li>
            <li>Gerar Atendimentos</li>
          </ul>
        </div>
        <ul className="w-full steps my-6">
          <li className="step step-primary">Dados</li>
          <li className="step step-primary">Análise</li>
          <li className="step step-primary">Finalizar</li>
        </ul>
        <div className="flex flex-col">
          <div className="bg-gray-200 p-2">
            <strong>Dia(s):</strong>{' '}
            <span className="flex flex-wrap space-x-1">
              {makeAppointment.loteDate.map((dt, idx) => (
                <div className="badge badge-info" key={idx}>
                  <button type="button" onClick={() => delDay(idx)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      className="inline-block w-4 h-4 mr-2 stroke-current"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </button>
                  {getFormatedDate(dt)} {' - '}
                  {getFormatedDateWeek(dt)}
                </div>
              ))}
            </span>
          </div>
          <div className="bg-gray-100 p-2">
            <strong>Hora de Início:</strong> {makeAppointment.appointmentBegin}
          </div>
          <div className="bg-gray-200 p-2">
            <strong>Sala:</strong> {makeAppointment.room}
          </div>
          <div className="bg-gray-100 p-2">
            <strong>Quantidade de Atendimentos:</strong> {makeAppointment.qtde}
          </div>
          <div className="bg-gray-200 p-2">
            <strong>Ordenação Inicial:</strong> {makeAppointment.orderInit}
          </div>
          <div className="bg-gray-200 p-2">
            <strong>Profissional:</strong> {professional?.name}
          </div>
        </div>
        <div className="flex justify-between my-4">
          <button
            type="button"
            className="bt btn-sm btn-active text-white rounded"
            onClick={() => history.push('/appointments-make-lote/analize')}
          >
            Voltar
          </button>
          <button
            type="button"
            className="bt btn-sm btn-primary rounded"
            onClick={toMake}
          >
            Gerar
          </button>
        </div>
      </div>
    );
  };
