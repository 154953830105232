/* eslint-disable react/require-default-props */
import { ArrowLeftIcon, CheckIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';

interface ButtonIconConfirmProps {
  show?: boolean;
  expanded?: boolean;
  type: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
  color: 'POSITIVE' | 'NEGATIVE' | 'DEFAULT';
  disabled?: boolean;
  bordered?: boolean;
  title?: string;
  onClick?: () => void;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ButtonIconConfirm = (props: ButtonIconConfirmProps) => {
  const [confirmed, setConfirmed] = useState(false);

  const btnDefault = (
    <button
      // eslint-disable-next-line react/button-has-type
      type={props.type}
      disabled={props.disabled}
      className={`
        ${props.expanded ? 'w-full' : ''}
        ${props.color === 'POSITIVE' && props.bordered !== false ? 'bg-green-400' : ''}
        ${props.color === 'NEGATIVE' && props.bordered !== false ? 'bg-red-600' : ''}
        ${props.color === 'DEFAULT' && props.bordered !== false ? 'bg-white' : ''}
        ${
          props.bordered === false
            ? 'bg-transparent'
            : 'py-1 px-1 my-0 mx-1 rounded-full text-white'
        }
      `}
      onClick={() => setConfirmed(true)}
      title={props.title}
    >
      {props.children}
    </button>
  );

  const btnConfirm = (
    <div
      className={`
        ${props.expanded ? 'w-full' : 'w-20'}
      `}
    >
      <button
        type="button"
        className={`
          bg-red-600
          py-1 px-3
          my-3 mx-auto
          rounded-tl-full
          rounded-bl-full
          text-white
          ${props.expanded ? 'w-1/2' : ''}
        `}
        onClick={() => setConfirmed(false)}
        title="Cancelar"
      >
        <ArrowLeftIcon className="h-4" />
      </button>
      <button
        // eslint-disable-next-line react/button-has-type
        type={props.type}
        className={`
          bg-green-400
          py-1 px-3
          my-3 mx-auto
          rounded-tr-full
          rounded-br-full
          text-white
          ${props.expanded ? 'w-1/2' : ''}
        `}
        title="Confirmar"
        onClick={props.onClick}
      >
        <CheckIcon className="h-4" />
      </button>
    </div>
  );

  if (props.show) {
    return confirmed === false ? btnDefault : btnConfirm;
  }
  return null;
};

export default ButtonIconConfirm;
