/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAvalicaoPresencial, Protocol } from '../app.model';
import {
  LOCAL_STORAGE_JWT,
  LOCAL_STORAGE_PREFIX,
  LOCAL_STORAGE_USER,
} from '../app.settings';
import api from '../axios';
import history from '../history';
import { Institutional } from '../pages/institutional/institutional.model';
import { User } from '../pages/users/user.model';

type AuthUser = {
  user: {
    id: string;
    cel: string;
    name: string;
    user: string;
    auth: string;
  };
  token: string;
};

export async function login(user: string, password: string): Promise<void> {
  const url = '/auth/loginAdmin';
  try {
    const ret = await api.post<AuthUser>(url, {
      user,
      password,
    });

    console.log('ret -->', ret);

    const userData = {
      id: ret.data.user.id,
      user: ret.data.user.user,
      name: ret.data.user.name,
      cel: ret.data.user.cel,
    };
    // console.log('data', ret.data);
    localStorage.setItem(LOCAL_STORAGE_JWT, ret.data.token);
    localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(userData));
    window.location.reload();
  } catch (e) {
    throw new Error('Usuário ou senha inválidos');
  }
}

export function logoff(): void {
  localStorage.removeItem(LOCAL_STORAGE_JWT);
  localStorage.removeItem(LOCAL_STORAGE_USER);
  history.push('/');
  window.location.reload();
}

export function getAuthUser(): User | null {
  const dataLocalStorage = localStorage.getItem(LOCAL_STORAGE_USER);
  if (dataLocalStorage) {
    const data = JSON.parse(dataLocalStorage);
    return data as User;
  } else return null;
}

export async function getInstitutional(): Promise<Institutional> {
  const url = '/institutional';
  try {
    const ret = await api.get<Institutional>(url);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export function setInstitutional(data: Institutional): Promise<any> {
  const url = '/institutional';
  return api
    .put<Institutional>(url, data)
    .then((value) => value)
    .catch((e: Error) => e.message);
}

export function validateToken(): void {
  const url = '/auth/validateToken';
  api.get(url);
}

const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return <File>theBlob;
};

export function upload(
  url: string,
  image: Blob,
  onUploadProgress: (progressEvent: any) => void
): Promise<any> {
  const formData = new FormData();
  const imgUp = blobToFile(image, 'upload.jpg');
  formData.append('image', imgUp);
  return api.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: onUploadProgress,
  });
}

export const createProtocol = async (
  cel: string,
  user: string,
  startedAt: number
): Promise<Protocol> => {
  const url = '/protocols';
  const ret = await api.post<Protocol>(url, { cel, user, startedAt });
  return ret.data;
};

export async function getByIdProtocol(id: string): Promise<Protocol> {
  const url = '/protocols/' + id;
  try {
    const ret = await api.get<Protocol>(url);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export const updateProtocol = async (data: Protocol): Promise<Protocol> => {
  const url = '/protocols/';
  const ret = await api.post<Protocol>(url, { ...data });
  return ret.data;
};

export const searchProtocol = async (
  data: Record<string, any>
): Promise<Protocol[]> => {
  const url = '/protocols/search';
  const ret = await api.post<Protocol[]>(url, { ...data });
  return ret.data;
};

export function setFormLocalStorage(
  formIdentification: string,
  value: string
): void {
  localStorage.setItem(LOCAL_STORAGE_PREFIX + formIdentification, value);
}

export async function findAvaliacaoPresencial(
  dataIni: string,
  dataFim: string,
): Promise<IAvalicaoPresencial[]> {
  const url = '/ratings/find';
  const ret = await api.post<IAvalicaoPresencial[]>(url, { dataIni, dataFim });
  return ret.data;
}

export function getFormLocalStorage(formIdentification: string): string | null {
  return localStorage.getItem(LOCAL_STORAGE_PREFIX + formIdentification);
}
