/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import {
  API_URL,
  LOCAL_STORAGE_JWT,
  LOCAL_STORAGE_USER,
} from './app.settings';

const DEBUG = false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const serialize = (obj: any) => {
  const str = [];
  // eslint-disable-next-line prefer-const
  for (let p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }

  return str.join('&');
};

const _axios = axios.create({
  baseURL: API_URL,
});

_axios.interceptors.request.use(
  (config) => {
    /** In dev, intercepts request and logs it into console for dev */
    if (DEBUG) {
      console.info('✉️ axios-request: ', config);
    }
    const token = localStorage.getItem(LOCAL_STORAGE_JWT);
    const newHeards = config.headers;
    if (token) newHeards.Authorization = `Bearer ${token}`;
    return { ...config, headers: newHeards };
  },
  (error) => {
    if (DEBUG) {
      console.error('✉️ axios-request: ', error);
    }
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: any) => {
    if (DEBUG) {
      console.info('✉️ axios-error: ', error);
    }
    if (String(error).includes('401') ) {
      console.info('✉️ axios-error-401: ', error);
      localStorage.removeItem(LOCAL_STORAGE_JWT);
      localStorage.removeItem(LOCAL_STORAGE_USER);
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default _axios;
