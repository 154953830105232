/* eslint-disable react/jsx-filename-extension */
import {
  PencilAltIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Loading from '../../components/Loading';
import history from '../../history';
import { Procedure } from './procedures.model';
import {
  getFormLocalStorage,
  setFormLocalStorage,
  validateToken,
} from '../../services/app.service';
import { del, find, listPagineted } from './procedures.service';
import { PaginateLinks, PaginateMetas } from '../../app.model';
import { list as listProfessionals } from '../professionals/professionals.service';
import { Professional } from '../professionals/professionals.model';
import { useForm } from 'react-hook-form';
import ButtonIconConfirm from '../../components/ButtonIconConfirm';

const PROCEDURES_PROFESSIONALS = 'ProceduresProfessional';

const AppCadProcedures: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [showPaginate, setShowPaginate] = useState(true);
  const [paginateData, setPaginateData] = useState<Procedure[]>([]);
  const [paginateMeta, setPaginateMeta] = useState<PaginateMetas>();
  const [paginateLinks, setPaginateLinks] = useState<PaginateLinks>();
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const { handleSubmit, register, setValue } = useForm();

  const location = useLocation();
  // function useQuery() {
  //   return new URLSearchParams(useLocation().search);
  // }

  console.log('location search => ', location.search);

  const init = async () => {
    validateToken();
    const paginateData = await listPagineted(location.search);
    setShowPaginate(true);
    setPaginateData(paginateData.items);
    setPaginateMeta(paginateData.meta);
    setPaginateLinks(paginateData.links);
    await listProfessionals().then(setProfessionals);
    if (getFormLocalStorage(PROCEDURES_PROFESSIONALS)) {
      setValue('professional', getFormLocalStorage(PROCEDURES_PROFESSIONALS));
    }
    setLoading(false);
  };

  const loadAll = async () => {
    setValue('professional', '');
    await init();
  };

  useEffect(() => {
    init();
  }, [location.search]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filter = async (data: any) => {
    setLoading(true);
    setShowPaginate(false);
    find({ professionalId: data.professional }).then(setPaginateData);
    setFormLocalStorage(PROCEDURES_PROFESSIONALS, data.professional);
    setLoading(false);
  };

  const Metas = () => {
    if (!paginateMeta) {
      return null;
    }
    const mt = paginateMeta;
    return (
      <div className={`w-full grid grid-cols-4 text-xs`}>
        <span>Pagina Atual: {mt.currentPage}</span>
        <span>Total de Pags: {mt.totalPages}</span>
        <span>Total Itens: {mt.totalItems}</span>
        <span>Itens por Pag: {mt.itemsPerPage}</span>
      </div>
    );
  };
  const Links = () => {
    if (!paginateLinks || paginateMeta?.totalPages === 1) {
      return null;
    }
    const lk = paginateLinks;
    return (
      <div className="btn-group w-full">
        {lk.first && (
          <Link to={lk.first} className="btn btn-xs">
            Primeiro
          </Link>
        )}
        {lk.previous && (
          <Link to={lk.previous} className="btn btn-xs">
            Anterior
          </Link>
        )}
        {lk.next && (
          <Link to={lk.next} className="btn btn-xs">
            Próximo
          </Link>
        )}
        {lk.last && (
          <Link to={lk.last} className="btn btn-xs">
            Último
          </Link>
        )}
      </div>
    );
  };

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 z-0`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li className="text-primary">Cadastro de Procedimentos</li>
        </ul>
      </div>
      <div>
        <form onSubmit={handleSubmit(filter)}>
          <div className="form-control flex flex-row justify-start">
            <select
              {...register('professional', { required: true })}
              className="select bg-gray-100 select-sm input-sm rounded-r-none pr-36"
            >
              <option value="">Selecione um profissional</option>
              {professionals.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
            </select>
            <button
              type="submit"
              className="rounded-l-none btn btn-sm btn-gray-500"
            >
              Buscar
            </button>
            <button
              type="button"
              className="mx-1 btn btn-sm btn-gray-500"
              onClick={loadAll}
            >
              Todos
            </button>
          </div>
        </form>
      </div>
      <div className="overflow-x-auto">
        <table className="table w-full z-0">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Profissional</th>
              <th>Recomendações</th>
              <th>Tempo Estimado</th>
              <th className="flex flex-col items-end">
                <Link to={'/cad-procedures-form'}>
                  <span className="flex items hover:opacity-50">
                    <PlusCircleIcon className="w-5 mr-2" /> Inserir
                  </span>
                </Link>
              </th>
            </tr>
          </thead>
          {loading && (
            <tbody>
              <tr>
                <td colSpan={6}>
                  <Loading />
                </td>
              </tr>
            </tbody>
          )}
          {!loading && (
            <tbody>
              {paginateData.map((el) => (
                <tr key={el.id}>
                  <td>{el.name}</td>
                  <td>{el.professional.name}</td>
                  <td>{el.recommendations ? el.recommendations.name : 'N/D'}</td>
                  <td>{Number(el.estimatedTime).toFixed(0)}</td>
                  <td className="flex flex-row justify-end">
                    <button
                      className="hover:opacity-70"
                      onClick={() =>
                        history.push('/cad-procedures-form', {
                          id: el.id,
                          return: location.pathname + location.search,
                        })
                      }
                    >
                      <PencilAltIcon className="h-6" />
                    </button>
                    <ButtonIconConfirm
                      show={true}
                      disabled={false}
                      type="button"
                      color="NEGATIVE"
                      onClick={() => del(el.id).then(init)}
                    >
                      <TrashIcon className="h-4" />
                    </ButtonIconConfirm>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {showPaginate && (
          <div
            className={`
            flex justify-between
            bg-gray-100
            p-1
            rounded
          `}
          >
            <Links />
            <Metas />
          </div>
        )}
      </div>
    </div>
  );
};

export default AppCadProcedures;
