import React, { useEffect, useState } from 'react';
import { useMakeAppointment } from '../../../hooks/appointmentsMaking.hook';
import history from '../../../history';
import { getById } from '../../professionals/professionals.service';
import { Professional } from '../../professionals/professionals.model';
import { create } from '../appointments.service';
import { getFormatedDate } from '../../../utils/datetimeHandler';

export const FinistAppointmentMake: React.FC = () => {
  const makeAppointment = useMakeAppointment();
  const [professional, setProfessional] = useState<Professional>();

  const init = async () => {
    const prof = await getById(makeAppointment.professional);
    console.log('prof ===>', prof);
    setProfessional(prof);
  };

  useEffect(() => {
    init();
  }, []);

  const toMake = (): void => {
    const qtde = makeAppointment.qtde;
    for (let index = 0; index < qtde; index++) {
      if (professional)
        create({
          date: makeAppointment.date,
          appointmentBegin: makeAppointment.appointmentBegin,
          room: makeAppointment.room,
          order: makeAppointment.orderInit + index,
          professional: makeAppointment.professional,
          value: 0,
          commission: 0,
        });
      history.push('/appointments');
    }
  };

  return (
    <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/appointments')}>Atendimentos</a>
          </li>
          <li>Gerar Atendimentos</li>
        </ul>
      </div>
      <ul className="w-full steps my-6">
        <li className="step step-primary">Dados</li>
        <li className="step step-primary">Análise</li>
        <li className="step step-primary">Finalizar</li>
      </ul>
      <div className="flex flex-col">
        <div className="bg-gray-200 p-2">
          <strong>Data:</strong> {getFormatedDate(String(makeAppointment.date))}
        </div>
        <div className="bg-gray-100 p-2">
          <strong>Hora de Início:</strong> {makeAppointment.appointmentBegin}
        </div>
        <div className="bg-gray-200 p-2">
          <strong>Sala:</strong> {makeAppointment.room}
        </div>
        <div className="bg-gray-100 p-2">
          <strong>Quantidade de Atendimentos:</strong> {makeAppointment.qtde}
        </div>
        <div className="bg-gray-200 p-2">
          <strong>Ordenação Inicial:</strong> {makeAppointment.orderInit}
        </div>
        <div className="bg-gray-200 p-2">
          <strong>Profissional:</strong> {professional?.name}
        </div>
      </div>
      <div className="flex justify-between my-4">
        <button
          type="button"
          className="bt btn-sm btn-active text-white rounded"
          onClick={() => history.push('/appointments-make/analize')}
        >
          Voltar
        </button>
        <button
          type="button"
          className="bt btn-sm btn-primary rounded"
          onClick={toMake}
        >
          Gerar
        </button>
      </div>
    </div>
  );
};
