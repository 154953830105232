import React, { useEffect, useState } from 'react';
import Loading from '../../../components/Loading';
import { SendButton } from '../../../components/SendButton';
import history from '../../../history';
import { useWaitingListAdd } from '../../../hooks/waitingListAdd.hook';
import { Patient } from '../../patients/patients.model';
import { getById } from '../../patients/patients.service';
import { Professional } from '../../professionals/professionals.model';
import { list } from '../../professionals/professionals.service';
import { WaitingList } from '../waitingList.model';
import { save } from '../waitingList.service';

type FinistWaitingListAddProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValues: (values: any) => void;
};

export const FinistWaitingListAdd: React.FC<FinistWaitingListAddProps> = ({
  setValues,
}: FinistWaitingListAddProps) => {
  const hookData = useWaitingListAdd();
  const [submiting, setSubmiting] = useState<boolean>(false);
  const [patient, setPatient] = useState<Patient>();
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const [obs, setObs] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const init = async () => {
    setLoading(true);
    if (hookData.patient)
      await getById(String(hookData.patient)).then(setPatient);
    await list().then(setProfessionals);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, [hookData]);

  const submit = () => {
    if (!hookData.professional || !hookData.patient) return;
    setSubmiting(true);

    const dataSend: WaitingList = {
      patientId: hookData.patient,
      professionalId: hookData.professional,
      obs,
    };
    save(dataSend).then(() => {
      setSubmiting(false);
      history.push('/waiting-list');
    });
  };

  const setProfessional = (professional: string) => {
    const data = {
      ...hookData,
      professional,
    };
    setValues(data);
  };

  return (
    <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/waiting-list')}>Lista de Espera</a>
          </li>
          <li>Concluir adição</li>
        </ul>
      </div>
      <ul className="w-full steps my-6">
        <li className="step step-primary">Dados</li>
        <li className="step step-primary">Análise</li>
        <li className="step step-primary">Finalizar</li>
      </ul>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-col">
            <div className="bg-gray-100 p-2 flex justify-between">
              <strong>Paciente:</strong> <span>{patient?.name}</span>
            </div>
            <div className="bg-gray-200 p-2 flex justify-between">
              <strong>Celular:</strong> <span>{patient?.cel}</span>
            </div>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Profissional</span>
            </label>
            <select
              className="select select-bordered w-full max-w-xs"
              onChange={(e) => setProfessional(e.target.value)}
              value={hookData.professional || ''}
            >
              <option value="">Selecione um profissional</option>
              {professionals &&
                professionals.map((el) => (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
            </select>
            <span className="text-xs text-red-500">
              {!hookData.professional && 'Campo requerido'}
            </span>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">OBS.</span>
            </label>
            <textarea
              className="input input-bordered w-full"
              onChange={(e) => setObs(e.target.value)}
              value={obs}
            ></textarea>
          </div>
        </>
      )}
      <div className="flex justify-between my-4">
        <button
          type="button"
          className="bt btn-sm btn-active text-white rounded"
          onClick={() => history.push('/waiting-list-add/analize')}
        >
          Voltar
        </button>
        <SendButton
          click={submit}
          caption="Adicionar a Lista de Espera"
          submiting={submiting}
          size="btn-sm"
        />
      </div>
    </div>
  );
};
