import { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AppointmentsMakeContext } from '../../../hooks/appointmentsMaking.hook';
import { InitialAppointmentMake } from './initialAppointmentMake';
import { AnalizeAppointmentMake } from './analizeAppointmentMake';
import { FinistAppointmentMake } from './finistAppointmentMake';

const AppAppointmentsMake: React.FC = () => {
  const { path } = useRouteMatch();
  const [data, setData] = useState(() => ({
    date: '',
    appointmentBegin: '',
    room: 1,
    qtde: 1,
    professional: '',
    orderInit: 1,
    loteDate: [],
  }));

  return (
    <AppointmentsMakeContext.Provider value={{ ...data }}>
      <Switch>
        <Route exact path={path}>
          <InitialAppointmentMake setValues={setData} />
        </Route>
        <Route path={`${path}/analize`}>
          <AnalizeAppointmentMake setValues={setData} />
        </Route>
        <Route path={`${path}/finist`}>
          <FinistAppointmentMake />
        </Route>
      </Switch>
    </AppointmentsMakeContext.Provider>
  );
};

export default AppAppointmentsMake;
