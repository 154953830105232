/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Paginate } from '../../app.model';
import api from '../../axios';
import { Procedure, ProcedureToConvenant } from './procedures.model';

export async function list(): Promise<Procedure[]> {
  const url = '/procedures';
  try {
    const ret = await api.get<Procedure[]>(url);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function find(args: any): Promise<Procedure[]> {
  const url = '/procedures/find';
  try {
    const ret = await api.post<Procedure[]>(url, { ...args });
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function del(id: string): Promise<any>{
  const url = '/procedures/' + id;
  try {
    const ret = await api.delete(url);
    return ret;
  }catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function listPagineted(query?: string): Promise<Paginate<Procedure>> {
  const url = query ? `/procedures/paginated/${query}` : `/procedures/paginated`;
  try {
    const ret = await api.get<Paginate<Procedure>>(url);
    console.log('procedures paginated', ret);
    if (!ret.data)
      return {
        items: [],
        links: {
          first: '',
          previous: '',
          next: '',
          last: '',
        },
        meta: {
          totalItems: 0,
          itemCount: 0,
          itemsPerPage: 0,
          totalPages: 0,
          currentPage: 0,
        },
      };
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function getById(id: string): Promise<Procedure> {
  const url = '/procedures/' + id;
  try {
    const ret = await api.get<Procedure>(url);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function findById(id: string): Promise<Procedure> {
  const url = '/procedures/' + id;
  try {
    const ret = await api.get<Procedure>(url);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function save(data: Procedure): Promise<any> {
  const url = '/procedures';
  if (data.id) {
    return api
      .put<Procedure>(url, data)
      .then((value) => value)
      .catch((e: Error) => e.message);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...post } = data;
    return api
      .post<Procedure>(url, post)
      .then((value) => value)
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          return error.response;
        } else if (error.request) {
          return error.request;
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      });
  }
}
const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return <File>theBlob;
};

export function upload(
  id: string,
  image: Blob,
  onUploadProgress: (progressEvent: any) => void
): Promise<any> {
  const url = '/procedures/upload/' + id;
  const formData = new FormData();
  const imgUp = blobToFile(image, 'upload.jpg');
  formData.append('image', imgUp);
  return api.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: onUploadProgress,
  });
}

export async function addConvenant(
  convenant: string,
  id: string,
  value: number,
  commission: number
): Promise<any> {
  const url = '/procedures/add-convenant';
  try {
    const ret = await api.post<any>(url, { convenant, id, value, commission });
    console.log(ret.data);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export function deleteConvenant(convenant: string): Promise<any> {
  const url = '/procedures/convenant-del/' + convenant;
  return api
    .delete(url)
    .then((resp) => resp)
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        return error.response;
      } else if (error.request) {
        return error.request;
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    });
}

export async function findConvenantsByProcedures(procedure: string): Promise<ProcedureToConvenant[]> {
  const url = '/procedures/convenant-get-by-procedure/' + procedure;
  try {
    const ret = await api.get<any>(url);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}
