/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useMakeAppointment } from '../../../hooks/appointmentsMaking.hook';
import history from '../../../history';
import { useForm } from 'react-hook-form';
import { list } from '../../professionals/professionals.service';
import { Professional } from '../../professionals/professionals.model';

type InitialAppointmentMakeProps = {
  setValues: (values: any) => void;
};

export const InitialAppointmentMake: React.FC<InitialAppointmentMakeProps> = ({
  setValues,
}: InitialAppointmentMakeProps) => {
  const makeAppointment = useMakeAppointment();

  const [professionals, setProfessionals] = useState<Professional[]>([]);

  useEffect(() => {
    list().then((data) => {
      setProfessionals(data);
      const dataForm = makeAppointment;
      const entries = Object.entries(dataForm);
      entries.forEach((entry) => {
        const field = entry[0];
        const value = entry[1];
        setValue(field, typeof value === 'number' ? Number(value) : value);
      });
    });
  }, []);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
    setValues(data);
    history.push('/appointments-make/analize');
  };

  return (
    <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/appointments')}>Atendimentos</a>
          </li>
          <li>Gerar Atendimentos</li>
        </ul>
      </div>
      <ul className="w-full steps my-6">
        <li className="step step-primary">Dados</li>
        <li className="step step">Análise</li>
        <li className="step step">Finalizar</li>
      </ul>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-3 w-full">
        <div className="flex justify-start">
          {/* date */}
          <div className="form-control mx-2">
            <label className="label">
              <span className="label-text">Data</span>
            </label>
            <input
              type="date"
              placeholder="Dia do Atendimento"
              className="input input-bordered"
              {...register('date', { required: true })}
            />
            <span className="text-xs text-red-500">
              {errors.date && 'Campo requerido'}
            </span>
          </div>

          {/* date */}
          <div className="form-control mx-2">
            <label className="label">
              <span className="label-text">Inicio</span>
            </label>
            <input
              type="time"
              placeholder="Hora de início"
              className="input input-bordered"
              {...register('appointmentBegin', { required: true })}
            />
            <span className="text-xs text-red-500">
              {errors.appointmentBegin && 'Campo requerido'}
            </span>
          </div>
        </div>

        {/* room */}
        <div className="form-control mx-2">
          <label className="label">
            <span className="label-text">Sala/Consultório</span>
          </label>
          <input
            type="number"
            min={1}
            placeholder="Num. Sala/Consultório"
            className="input input-bordered"
            {...register('room', { required: true })}
          />
          <span className="text-xs text-red-500">
            {errors.room && 'Campo requerido'}
          </span>
        </div>

        {/* qtde */}
        <div className="form-control mx-2">
          <label className="label">
            <span className="label-text">Quantidade de Atendimentos</span>
          </label>
          <input
            type="number"
            min={1}
            placeholder="Qtde de atendimentos no dia"
            className="input input-bordered"
            {...register('qtde', { required: true })}
          />
          <span className="text-xs text-red-500">
            {errors.qtde && 'Campo requerido'}
          </span>
        </div>

        {/* professionals */}
        <div className="flex justify-start">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Profissional</span>
            </label>
            <select
              {...register('professional', { required: true })}
              className="select select-bordered w-full max-w-xs"
            >
              <option value={''}>Selecione um profissional</option>
              {professionals.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
            </select>
            <span className="text-xs text-red-500">
              {errors.procedure && 'Campo requerido'}
            </span>
          </div>
        </div>
        <div className="flex justify-end my-4">
          <button type="submit" className="bt btn-sm btn-primary rounded">
            Próximo
          </button>
        </div>
      </form>
    </div>
  );
};

/* : '',
room: 0,
qtde: 0,
procedure: '', */
