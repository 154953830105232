/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { validateToken } from '../../services/app.service';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Loading from '../../components/Loading';

import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Professional } from '../professionals/professionals.model';
import {
  findWhereIn,
  list as listProfessionals,
} from '../professionals/professionals.service';
import { Procedure } from '../procedures/procedures.model';
import { parseCurrency } from '../../utils/app.utils';

// @ts-ignore: Unreachable code error
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AppRelatProfessionalsProcedures: React.FC = () => {
  const relatTitle = 'Relatório Procedimentos por Profissional';
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Professional[]>([]);
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const { handleSubmit, control, getValues } = useForm();

  const init = async () => {
    listProfessionals().then((data) => setProfessionals(data));
    validateToken();
  };

  useEffect(() => {
    init();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const findProfessionalsWhereIn = async (data: any) => {
    setLoading(true);
    const args = data.professionals.reduce((acc: string[], cur: any) => {
      acc.push(cur.value);
      return acc;
    }, []);

    const profs = await findWhereIn(args);
    console.log(profs);
    setData(profs);
    setLoading(false);
  };

  const generatePdf = () => {
    // @ts-ignore: Unreachable code error
    pdfMake.fonts = {
      Roboto: {
        normal:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
    };

    const dd: TDocumentDefinitions = {
      defaultStyle: {
        font: 'Roboto',
      },

      pageSize: 'A4',

      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            text: relatTitle,
            alignment: currentPage % 2 ? 'left' : 'right',
            style: 'header',
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 30,
                y1: 0,
                x2: pageSize.width - 30,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
        ];
      },

      content: data.map((dt) => ({
        stack: [
          {
            text: `Profissional: ${dt.name} (${dt.specialty.name})`,
            style: {
              fontSize: 18,
              bold: true,
            },
          },
          {
            ol: dt.procedures
              ? dt.procedures?.map((p: Procedure) => ({
                  stack: [
                    {
                      text: `Procedimento: ${p.name}`,
                      style: {
                        fontSize: 13,
                        bold: true,
                      },
                    },
                    {
                      table: {
                        widths: ['*', 200],
                        body: p.procedureToCovenants.map((pc) => {
                          return [pc.convenant.name, parseCurrency(pc.value)];
                        }),
                      },
                    },
                  ],
                }))
              : ['Sem Procedimentos'],
            style: {
              margin: [60, 0, 30, 0],
            },
          },
          `\n`,
        ],
      })),

      footer: function (currentPage, pageCount, pageSize) {
        return [
          {
            canvas: [
              {
                type: 'line',
                x1: 30,
                y1: 0,
                x2: pageSize.width - 30,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
          {
            stack: [
              {
                text: 'Pag. '.concat(
                  currentPage.toString(),
                  ' de ',
                  pageCount.toString(),
                  ` - ${new Date().getDate()}/${
                    new Date().getMonth() + 1
                  }/${new Date().getFullYear()}`
                ),
                margin: [30, 0, 30, 0],
                fontSize: 10,
              },

              {
                text: `Filtro: ${getValues('professionals')
                  .reduce((acc: string[], cur: any) => {
                    acc.push(cur.label);
                    return acc;
                  }, [])
                  .toString()}`,
                margin: [30, 0, 30, 0],
                fontSize: 10,
              },
            ],
          },
        ];
      },

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [30, 30, 30, 0],
        },
      },
    };
    const doc = pdfMake.createPdf(dd);

    // doc.download();
    doc.open();
    // doc.getBase64((data) => {
    //   self.location.href = 'data:application/pdf;base64,' + data;
    // });
  };

  // console.log('status =>', getValues('status'));

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div style={{ width: '90%', position: 'absolute' }}>
        <div className="text-sm breadcrumbs relative">
          <ul>
            <li>{relatTitle}</li>
          </ul>
        </div>
        <div className="my-5 w-full relative z-10">
          <form onSubmit={handleSubmit(findProfessionalsWhereIn)}>
            <div className=" w-full form-control flex flex-row justify-start">
              <div className="w-full flex">
                <Controller
                  render={({ field }) => (
                    <Select
                      className="w-full mr-3"
                      {...field}
                      isMulti
                      isClearable
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      openMenuOnFocus={false}
                      isSearchable={true}
                      autoFocus={false}
                      openMenuOnClick={false}
                      hideSelectedOptions={true}
                      placeholder={`Selecione um profissional ou mais`}
                      options={professionals.map((el) => ({
                        label: el.name,
                        value: el.id,
                      }))}
                    />
                  )}
                  name="professionals"
                  control={control}
                  defaultValue={[]}
                  rules={{ required: true }}
                />
              </div>

              <button type="submit" className="my-2 btn btn-sm btn-gray-500">
                Buscar
              </button>
            </div>
          </form>
        </div>
        <div className="divider opacity-25"></div>
        {loading && (
          <div className="pt-20 text-center">
            <Loading />
          </div>
        )}

        {!loading &&
          data.map((dt, dtIdx) => (
            <div key={dtIdx} className="my-4">
              <h1 className="py-3 text-center bg-gray-600 text-white">
                {dt.name} ({dt.specialty.name})
              </h1>
              {dt.procedures?.map((procedure: Procedure, procIdx: number) => {
                return (
                  <div key={procIdx} className="bg-gray-300 p-1 px-2">
                    <div className="text-center font-extrabold">
                      {procedure.name}
                    </div>
                    <div className="">
                      {procedure.procedureToCovenants.map(
                        (procedureToCovenant, pcIdx) => {
                          return (
                            <div
                              key={pcIdx}
                              className="flex justify-between bg-gray-100 p-1 border-b-2 border-dotted"
                            >
                              <span>{procedureToCovenant.convenant.name}</span>
                              <span>
                                {parseCurrency(procedureToCovenant.value)}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                );
              })}
              {/* <table className="table-compact w-full z-0">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="text-left">Profissional</th>
                    <th className="text-left">Especialidade</th>
                    <th className="text-center">Qtde Atendimentos</th>
                  </tr>
                </thead>

                <tbody>
                  {dt.group.map((g: any, gIdx: number) => (
                    <tr key={gIdx}>
                      <td className="text-left">{g.professional_name}</td>
                      <td className="text-left">{g.specialty_name}</td>
                      <td className="text-center">{g.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table> */}
            </div>
          ))}

        <button
          type="button"
          className="my-2 btn btn-sm bg-blue-300 border-blue-300"
          onClick={generatePdf}
        >
          Imprimir
        </button>
      </div>
    </div>
  );
};

export default AppRelatProfessionalsProcedures;
