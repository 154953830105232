/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useSchedulingAppointment } from '../../../hooks/appointmentsScheduling.hook';
import history from '../../../history';
import Loading from '../../../components/Loading';
import { find, getById, save } from '../../patients/patients.service';
import { Patient } from '../../patients/patients.model';
import { CheckIcon, PencilAltIcon } from '@heroicons/react/outline';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import getMask from '../../../utils/masks';

import ModalPatient from 'react-modal';
import { XIcon } from '@heroicons/react/outline';
import AppCadPatientsFormExternal from '../../patients/AppCadPatientsFormExternal';

type AnalizeAppointmentSchedulingProps = {
  setValues: (values: any) => void;
};

export const AnalizeAppointmentScheduling: React.FC<AnalizeAppointmentSchedulingProps> =
  ({ setValues }: AnalizeAppointmentSchedulingProps) => {
    const hookData = useSchedulingAppointment();
    const [loading, setLoading] = useState(true);
    const [patients, setPatients] = useState<Patient[]>();

    const [documentType, setDocumentType] = useState<'CPF' | 'RG' | 'RN'>(
      'CPF'
    );
    const [submiting, setSubmiting] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<[]>([]);
    const {
      handleSubmit,
      register,
      control,
      setValue,
      formState: { errors },
    } = useForm();

    useEffect(() => {
      setValue('cel', hookData.cel);
      find({ cel: hookData.cel }).then((data) => {
        if (data) setPatients(data);
        setLoading(false);
      });
    }, []);

    const selectPatient = (patient: string) => {
      setValues({
        ...hookData,
        patient,
      });
      history.push('/appointments-scheduling/finist');
    };

    const onSubmit = (data: any) => {
      setSubmiting(true);
      const dataCad = data;
      if (!dataCad.document) dataCad.document = null;
      // console.log('dataCad patient cad', dataCad);
      save(dataCad)
        .then((ret) => {
          if (ret.data && ret.data.error) {
            setError(true);
            setErrorMsg(ret.data.message);
            setSubmiting(false);
            return;
          }
          if (ret.affected === 0) {
            setError(true);
            return;
          }
          setError(false);
          setSubmiting(false);
          selectPatient(ret.data.id);
        })
        .catch((e) => {
          console.log('error', e);
          setError(true);
          setSubmiting(false);
        });
    };

    // modal patient
    const [modalPatientIsOpen, setModalPatientIsOpen] = useState(false);
    const [patient, setPatient] = useState<Patient>();

    const loadPatient = async (patientId: string) => {
      const data = await getById(patientId);
      setPatient(data);
      setModalPatientIsOpen(true);
    };

    return (
      <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <a onClick={() => history.push('/appointments')}>Atendimentos</a>
            </li>
            <li>Agendar Atendimento</li>
          </ul>
        </div>
        <ul className="w-full steps my-6 z-0 relative">
          <li className="step step-primary">Dados</li>
          <li className="step step-primary">Análise</li>
          <li className="step step">Finalizar</li>
        </ul>
        {loading && <Loading />}
        {!loading && (
          <div>
            {patients && patients.length > 0 && (
              <table className="table w-full z-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nome</th>
                    <th>Cel</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {patients?.map((el, index) => (
                    <tr key={el.id}>
                      <th>{index + 1}</th>
                      <td>{el.name}</td>
                      <td>{el.cel}</td>
                      <td className="flex flex-row justify-end">
                        <button
                          className="hover:opacity-70 mr-3"
                          onClick={() => {
                            loadPatient(el.id);
                          }}
                        >
                          <PencilAltIcon className="h-6" />
                        </button>
                        <button
                          className="hover:opacity-70"
                          onClick={() => selectPatient(el.id)}
                        >
                          <CheckIcon className="h-6" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {patients && patients.length === 0 && (
              <div className={`w-full`}>
                <form onSubmit={handleSubmit(onSubmit)} className="mb-3 w-full">
                  <input type="hidden" {...register('id')} />
                  {/* name */}
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Nome</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Nome/Descrição"
                      className="input input-bordered"
                      {...register('name', { required: true })}
                    />
                    <span className="text-xs text-red-500">
                      {errors.name && 'Campo requerido'}
                    </span>
                  </div>

                  {/* document */}
                  <div className="form-control">
                    <div className="flex justify-between">
                      <label className="cursor-pointer label">
                        <input
                          type="radio"
                          name="opt"
                          checked={documentType === 'CPF'}
                          className="radio mr-1"
                          value="CPF"
                          onChange={() => setDocumentType('CPF')}
                        />
                        <span className="label-text">CPF</span>
                      </label>
                      <label className="cursor-pointer label">
                        <input
                          type="radio"
                          name="opt"
                          checked={documentType === 'RG'}
                          className="radio mr-1"
                          value="RG"
                          onChange={() => setDocumentType('RG')}
                        />
                        <span className="label-text">RG</span>
                      </label>
                      <label className="cursor-pointer label">
                        <input
                          type="radio"
                          name="opt"
                          checked={documentType === 'RN'}
                          className="radio mr-1"
                          value="RN"
                          onChange={() => setDocumentType('RN')}
                        />
                        <span className="label-text">RN</span>
                      </label>
                    </div>
                    <Controller
                      name="document"
                      control={control}
                      render={({ field }) => (
                        <MaskedInput
                          mask={getMask(documentType.toLowerCase())}
                          type="text"
                          placeholder={documentType}
                          className="input input-bordered"
                          {...field}
                        />
                      )}
                    />

                    <span className="text-xs text-red-500">
                      {errors.document && 'Campo requerido'}
                    </span>
                  </div>

                  {/* cel */}
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Celular</span>
                    </label>
                    <Controller
                      name="cel"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MaskedInput
                          mask={getMask('celular')}
                          type="text"
                          placeholder="Celular"
                          className="input input-bordered"
                          {...field}
                        />
                      )}
                    />
                    <span className="text-xs text-red-500">
                      {errors.cel && 'Campo requerido'}
                    </span>
                  </div>

                  {/* cel2 */}
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Celular 2</span>
                    </label>
                    <Controller
                      name="cel2"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <MaskedInput
                          mask={getMask('celular')}
                          type="text"
                          placeholder="Celular"
                          className="input input-bordered"
                          {...field}
                        />
                      )}
                    />
                    <span className="text-xs text-red-500">
                      {errors.cel2 && 'Campo requerido'}
                    </span>
                  </div>

                  {/* address */}
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Endereço</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Endereço"
                      className="input input-bordered"
                      {...register('address', { required: false })}
                    />
                    <span className="text-xs text-red-500">
                      {errors.address && 'Campo requerido'}
                    </span>
                  </div>

                  {/* city */}
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Cidade</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Cidade"
                      className="input input-bordered"
                      {...register('city', { required: false })}
                    />
                    <span className="text-xs text-red-500">
                      {errors.city && 'Campo requerido'}
                    </span>
                  </div>

                  {/* dn */}
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Data de Nascimento</span>
                    </label>
                    <input
                      type="date"
                      placeholder="Data de Nascimento"
                      className="input input-bordered"
                      {...register('dn', { required: true })}
                    />
                    <span className="text-xs text-red-500">
                      {errors.dn && 'Campo requerido'}
                    </span>
                  </div>

                  {/* sexo */}
                  <div className="flex flex-col py-1">
                    <span className="label-text">Sexo</span>
                    <div className="p-6 card bordered bg-gray-100 flex flex-row justify-between">
                      <div className="form-control">
                        <label className="cursor-pointer label">
                          <input
                            type="radio"
                            {...register('sexo', { required: true })}
                            className="radio radio-primary"
                            value="m"
                          />
                          <span className="label-text ml-3">Masculino</span>
                        </label>
                      </div>
                      <div className="form-control">
                        <label className="cursor-pointer label">
                          <input
                            type="radio"
                            {...register('sexo', { required: true })}
                            className="radio radio-primary"
                            value="f"
                          />
                          <span className="label-text ml-3">Feminino</span>
                        </label>
                      </div>
                    </div>
                    <span className="text-xs text-red-500">
                      {errors.sexo && 'Campo requerido'}
                    </span>
                  </div>

                  <div className="flex justify-end mt-3">
                    <button
                      type="submit"
                      className="bt btn-sm btn-primary rounded"
                      disabled={submiting}
                    >
                      Próximo
                    </button>
                  </div>
                </form>
                {error && (
                  <div className="alert alert-error flex flex-col items-start">
                    <div className="flex-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        className="w-6 h-6 mx-2 stroke-current"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                        ></path>
                      </svg>
                      <label>Error ao salvar registro</label>
                    </div>
                    <ul className="p-3">
                      {errorMsg &&
                        errorMsg.map((err, idx) => <li key={idx}>{err}</li>)}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div className="flex justify-between my-4">
          <button
            type="button"
            className="bt btn-sm btn-active text-white rounded"
            onClick={() => history.push('/appointments-scheduling')}
          >
            Voltar
          </button>
        </div>
        <ModalPatient
          isOpen={modalPatientIsOpen}
          onRequestClose={close}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              background: '#f5f5f5',
            },
          }}
          contentLabel="Alterar Paciente"
          ariaHideApp={false}
          appElement={document.getElementById('root') as HTMLElement}
        >
          <h1 className="flex flex-row justify-between items-center mb-5">
            <span>Alterar Paciente</span>
            <button onClick={() => setModalPatientIsOpen(false)}>
              <XIcon className="w-5 h-5 hover:bg-gray-300" />
            </button>
          </h1>
          <div
            style={{ height: '60vh', overflow: 'hidden', overflowY: 'auto' }}
          >
            {patient ? (
              <AppCadPatientsFormExternal patient={patient} />
            ) : (
              <div className="alert alertWarning">
                <div className="flex-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="w-6 h-6 mx-2 stroke-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    ></path>
                  </svg>
                  <label>Paciente não localizado</label>
                </div>
              </div>
            )}
          </div>
        </ModalPatient>
      </div>
    );
  };
