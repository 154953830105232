import { createContext, useContext } from 'react';
import { Appointment } from '../pages/appointments/appointments.model';
import { Patient } from '../pages/patients/patients.model';
import { Professional } from '../pages/professionals/professionals.model';

export interface IWaitingListSchendulingContext {
  patient: Patient | null;
  professional: Professional | null;
  appointment: Appointment | null;
  waitingListId: string;
}

const WaitingListSchendulingContext =
  createContext<IWaitingListSchendulingContext>(
    {} as IWaitingListSchendulingContext
  );

const useWaitingListSchenduling = (): IWaitingListSchendulingContext => {
  const context = useContext(WaitingListSchendulingContext);

  return context;
};

export { WaitingListSchendulingContext, useWaitingListSchenduling };
