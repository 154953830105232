import { createContext, useContext } from 'react';

export interface IAppointmentsMakeContext {
  date: string;
  appointmentBegin: string;
  room: number;
  qtde: number;
  professional: string;
  orderInit: number;
  loteDate: string[];
}

const AppointmentsMakeContext = createContext<IAppointmentsMakeContext>(
  {} as IAppointmentsMakeContext
);

const useMakeAppointment = (): IAppointmentsMakeContext => {
  const context = useContext(AppointmentsMakeContext);

  return context;
};

export { AppointmentsMakeContext, useMakeAppointment };
