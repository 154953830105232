export const LOCAL_STORAGE_JWT = '@citolab-admin:jwt';
export const LOCAL_STORAGE_USER = '@citolab-admin:user';
export const LOCAL_STORAGE_PREFIX = '@citolab-admin:';
export const API_URL = process.env.REACT_APP_API_URL || '';

export const WEEKS_DAY = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];
export const MONTS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];
export const YEARS = [
  2021,
  2022,
  2023,
  2024,
  2025,
  2026,
  2027,
  2028,
  2029,
  2030
];
