/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useSchedulingAppointment } from '../../../hooks/appointmentsScheduling.hook';
import history from '../../../history';
import { Controller, useForm, useWatch } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import getMask from '../../../utils/masks';
import { find as findAppointments } from '../appointments.service';
import { useLocation } from 'react-router';
import { addTime, getFormatedDate } from '../../../utils/datetimeHandler';
import Loading from '../../../components/Loading';
import { Procedure } from '../../procedures/procedures.model';
import {
  find as findProcedures,
  getById,
} from '../../procedures/procedures.service';
import SearchPatient from '../../../components/SearchPatient';

type InitialAppointmentSchedulingProps = {
  setValues: (values: any) => void;
};

export const InitialAppointmentScheduling: React.FC<InitialAppointmentSchedulingProps> =
  ({ setValues }: InitialAppointmentSchedulingProps) => {
    const hookData = useSchedulingAppointment();
    const [loading, setLoading] = useState(false);
    const [procedures, setProcedures] = useState<Procedure[]>([]);

    const location = useLocation<any>();

    useEffect(() => {
      setLoading(true);
      setValue('cel', hookData.cel);
      // console.log('hookData:', hookData);
      findAppointments({ id: hookData.id || location.state.id }).then(
        (data) => {
          if (data && data.length > 0)
            setValues({
              ...hookData,
              appointment: data[0],
            });
          findProcedures({ professional: data[0]['professional'] }).then(
            setProcedures
          );
          setLoading(false);
        }
      );
    }, [location.state]);

    const {
      handleSubmit,
      control,
      setValue,
      register,
      formState: { errors },
    } = useForm();

    const procedureId = useWatch({ name: 'procedure', control });
    const [prev, setPrev] = useState('');
    const getPrev = async () => {
      if (!procedureId) {
        setPrev('');
        return;
      }
      const proc = await getById(procedureId);
      if (hookData.appointment && proc) {
        setPrev(
          addTime(
            hookData.appointment?.appointmentBegin,
            proc.estimatedTime.toString(),
            hookData.appointment.order - 1
          )
        );
      }
    };
    useEffect(() => {
      getPrev();
    }, [procedureId]);

    const onSubmit = (data: any) => {
      // console.log(data);
      const { cel, procedure } = data;
      if (location.state && location.state.id)
        setValues({
          ...hookData,
          cel,
          procedure,
          id: location.state.id,
        });
      else
        setValues({
          ...hookData,
          cel,
          procedure,
        });
      history.push('/appointments-scheduling/analize');
    };

    return (
      <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 z-0`}>
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <a onClick={() => history.push('/appointments')}>Atendimentos</a>
            </li>
            <li>Agendar Atendimento</li>
          </ul>
        </div>
        <ul className="w-full steps my-6">
          <li className="step step-primary">Dados</li>
          <li className="step step">Análise</li>
          <li className="step step">Finalizar</li>
        </ul>
        {loading ? (
          <Loading />
        ) : (
          <div className="flex flex-col z-50">
            <div className="flex justify-between bg-gray-200 p-1">
              <strong>Dia:</strong>
              <span>{getFormatedDate(String(hookData.appointment?.date))}</span>
            </div>
            <div className="flex justify-between bg-gray-100 p-1">
              <strong>Ordem:</strong>
              <span>{hookData.appointment?.order}</span>
            </div>
            {/* <div className="flex justify-between bg-gray-200 p-1">
              <strong>Previsão:</strong>
              <span>
                {hookData.appointment &&
                  addTime(
                    hookData.appointment.appointmentBegin,
                    hookData.appointment.procedure.estimatedTime.toString(),
                    hookData.appointment.order - 1
                  )}
              </span>
            </div> */}
            <div className="flex justify-between bg-gray-100 p-1">
              <strong>Profissional:</strong>
              <span>{hookData.appointment?.professional.name}</span>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="mb-3 w-full">
          <div className="flex justify-between">
            {/* cel */}
            <div className="form-control">
              <label className="label">
                <span className="label-text">Celular</span>
              </label>
              <div className="flex space-x-1 mr-3">
                <Controller
                  name="cel"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <MaskedInput
                      mask={getMask('celular')}
                      type="text"
                      placeholder="Celular"
                      className="input input-bordered"
                      {...field}
                    />
                  )}
                />
                <SearchPatient
                  size="btn-md"
                  setSelected={(patientId) => setValue('cel', patientId)}
                />
              </div>
              <span className="text-xs text-red-500">
                {errors.cel && 'Campo requerido'}
              </span>
            </div>
            {/* procedures */}
            <div className="flex justify-start">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Procedimentos</span>
                </label>
                <select
                  {...register('procedure', { required: true })}
                  className="select select-bordered w-full max-w-xs"
                >
                  <option value={''}>Selecione um procedimento</option>
                  {procedures.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.name} - ({el.professional.name})
                    </option>
                  ))}
                </select>
                <span className="text-xs text-red-500">
                  {errors.procedure && 'Campo requerido'}
                </span>
              </div>
            </div>
          </div>
          {prev && (
            <div className="text-sm mt-2">Previsão de atendimento: {prev}</div>
          )}
          <div className="flex justify-end my-4">
            <button type="submit" className="bt btn-sm btn-primary rounded">
              Próximo
            </button>
          </div>
        </form>
      </div>
    );
  };

/* : '',
room: 0,
qtde: 0,
procedure: '', */
