import React from 'react';
import SlidingPanel from 'react-sliding-side-panel';
import 'react-sliding-side-panel/lib/index.css';
// import useMedia from 'use-media';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  closePanel: () => void;
  position: 'left' | 'right';
  size: number;
}

const AppSlidingPanel: React.FC<Props> = (props: Props) => {
  // const isSm = useMedia({maxWidth: '467px'});
  return (
    <SlidingPanel
      type={'right'}
      isOpen={props.isOpen}
      size={props.size}
      panelContainerClassName="bg-white"
    >
      <div>
        <div className="p-2 bg-gray-800 text-white">
          <button onClick={props.closePanel}>Fechar</button>
        </div>
        <div
          style={{ height: '95vh' }}
          className="overflow-hidden overflow-y-auto"
        >
          {props.children}
        </div>
      </div>
    </SlidingPanel>
  );
};

export default AppSlidingPanel;
