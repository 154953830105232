import React from 'react';
import { ReactSVG } from 'react-svg';
// import useMedia from 'use-media';
import { logoSVG } from '../utils/svgsMap';


const Loading: React.FC = () => {

  // const isSm = useMedia({maxWidth: '467px'});
  return (
    <div
      className={`
      w-full
      flex flex-col items-center py-16
      animate-pulse
      h-52
    `}>
      <h1>Aguarde ...</h1>
      <ReactSVG className="w-40 h-40" src={logoSVG} />
    </div>
  );
}

export default Loading;
