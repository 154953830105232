import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage';
import { upload } from '../../services/app.service';

type CropedImageBannerProps = {
  pathUrl: string;
};
const CropedImageBanner: React.FC<CropedImageBannerProps> = ({ pathUrl }: CropedImageBannerProps) => {
  //crop
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(0.5);
  const [image, setImage] = useState<string>();
  const [progress, setProgress] = useState<number>();
  const [cropedImage, setCropedImageBanner] = useState<Blob>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onImageChange = (event: any) => {
    // console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      setImage(self.URL.createObjectURL(event.target.files[0]));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCropComplete = async (_: any, croppedAreaPixels: any) => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
    setCropedImageBanner(croppedImage);
  };

  const onUploadProgress = (progressEvent: {
    loaded: number;
    total: number;
  }) => {
    setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
  };

  const onUpload = async () => {
    if (cropedImage) {
      if (pathUrl) {
        const up = await upload(pathUrl, cropedImage, onUploadProgress);
        if (up.status === 201) setImage(undefined);
        self.location.reload();
      }
    }
  };

  // console.log('progress ===>', progress);
  return (
    <>
      {!image && (
        <div className="form-control">
          <label className="label">
            <span className="label-text">Banner Tam (1050 x 350)px</span>
          </label>
          <input
            type="file"
            placeholder="Escolha um imagem"
            className="input"
            onChange={onImageChange}
          />
        </div>
      )}
      {image && (
        <div>
          <div className="h-64 w-full relative">
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={3/1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              // onMediaLoaded={(mediaSize) => {
              //   // Adapt zoom based on media size to fit max height
              //   setZoom(450 / mediaSize.naturalHeight)
              // }}
            />
          </div>
          <div className="z-50">
            <input
              type="range"
              max="3"
              value={zoom}
              step={0.1}
              className="range range-primary"
              aria-labelledby="Zoom"
              onChange={(e) => setZoom(Number(e.target.value))}
            />
            <progress
              className="progress progress-primary"
              value={progress}
              max="100"
            ></progress>
            <div className="btn-group">
              <button
                className="btn btn-sm"
                onClick={() => {
                  setImage(undefined);
                }}
              >
                Cancelar
              </button>
              <button className="btn btn-sm btn-primary" onClick={onUpload}>
                Upload
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CropedImageBanner;
