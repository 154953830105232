import { createContext, useContext } from 'react';
import { Appointment } from '../pages/appointments/appointments.model';
import { Patient } from '../pages/patients/patients.model';
import { Procedure } from '../pages/procedures/procedures.model';

export interface IAppointmentsSchedulingContext {
  cel: string | null;
  patient: Patient | null;
  procedure: Procedure | null;
  appointment: Appointment | null;
  ret: string | null;
  id: string | null;
}

const AppointmentsSchedulingContext = createContext<IAppointmentsSchedulingContext>(
  {} as IAppointmentsSchedulingContext
);

const useSchedulingAppointment = (): IAppointmentsSchedulingContext => {
  const context = useContext(AppointmentsSchedulingContext);

  return context;
};

export { AppointmentsSchedulingContext, useSchedulingAppointment };
