import { useState } from 'react';
import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { AppointmentsSchedulingContext } from '../../../hooks/appointmentsScheduling.hook';
import { InitialAppointmentScheduling } from './initialAppointmentScheduling';
import { AnalizeAppointmentScheduling } from './analizeAppointmentScheduling';
import { FinistAppointmentScheduling } from './finistAppointmentScheduling';

type LocationType = {
  id: string;
  ret: string;
  cel?: string;
};

const AppAppointmentsScheduling: React.FC = () => {
  const { path } = useRouteMatch();
  const location = useLocation<LocationType>();
  const [data, setData] = useState(() => ({
    cel:  location.state?.cel ||'',
    patient: null,
    procedure: null,
    appointment: null,
    ret: location.state?.ret,
    id: null,
  }));

  return (
    <AppointmentsSchedulingContext.Provider value={{ ...data }}>
      <Switch>
        <Route exact path={path}>
          <InitialAppointmentScheduling setValues={setData} />
        </Route>
        <Route path={`${path}/analize`}>
          <AnalizeAppointmentScheduling setValues={setData} />
        </Route>
        <Route path={`${path}/finist`}>
          <FinistAppointmentScheduling />
        </Route>
      </Switch>
    </AppointmentsSchedulingContext.Provider>
  );
};

export default AppAppointmentsScheduling;
