import { useState } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { AppointmentsConfirmationContext } from '../../../hooks/appointmentsConfirmation.hook';
import { InitialAppointmentConfirmation } from './initialAppointmentConfirmation';
import { AnalizeAppointmentConfirmation } from './analizeAppointmentConfirmation';
import { FinistAppointmentConfirmation } from './finistAppointmentConfirmation';

type LocationType = {
  id: string;
  ret: string;
}

const AppAppointmentsConfirmation: React.FC = () => {
  const { path } = useRouteMatch();
  const location = useLocation<LocationType>();
  const [data, setData] = useState(() => ({
    typePgto: null,
    appointment: null,
    convenant: null,
    ret: location.state?.ret,
  }));


  return (
    <AppointmentsConfirmationContext.Provider value={{ ...data }}>
      <Switch>
        <Route exact path={path}>
          <InitialAppointmentConfirmation setValues={setData} />
        </Route>
        <Route path={`${path}/analize`}>
          <AnalizeAppointmentConfirmation setValues={setData}/>
        </Route>
        <Route path={`${path}/finist`}>
          <FinistAppointmentConfirmation />
        </Route>
      </Switch>
    </AppointmentsConfirmationContext.Provider>
  );
};

export default AppAppointmentsConfirmation;
