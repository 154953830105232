/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { useState } from 'react';
import Loading from '../../components/Loading';
import history from '../../history';
import { validateToken } from '../../services/app.service';
import { Patient } from '../patients/patients.model';
import { Professional } from '../professionals/professionals.model';
import { useLocation } from 'react-router';
import { getById as getByIdProfessional } from '../professionals/professionals.service';
import { getById as getByIdPatient } from '../patients/patients.service';
import { del } from './waitingList.service';
import { SendButton } from '../../components/SendButton';

type LocationStateProps = {
  patient: string;
  professional: string;
  waitingListId: string;
};

const AppWaitingListsRemove: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState<Patient>();
  const [professional, setProfessional] = useState<Professional>();
  const location = useLocation<LocationStateProps>();
  const [submiting, setSubmiting] = useState<boolean>(false);

  const init = async () => {
    validateToken();
    setLoading(true);
    const data = location.state;
    const professional = await getByIdProfessional(data.professional);
    const patient = await getByIdPatient(data.patient);
    setPatient(patient);
    setProfessional(professional);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const submit = () => {
    setSubmiting(true);
    del(location.state?.waitingListId).then(() => {
      setSubmiting(false);
      history.push('/waiting-list');
    });
  };

  return (
    <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 z-0`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li className="text-primary">Lista de Espera - Remover</li>
        </ul>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col">
          <div className="bg-gray-100 p-2 flex justify-between">
            <strong>Paciente:</strong> <span>{patient?.name}</span>
          </div>
          <div className="bg-gray-200 p-2 flex justify-between">
            <strong>Celular:</strong> <span>{patient?.cel}</span>
          </div>
          <div className="bg-gray-100 p-2 flex justify-between">
            <strong>Profissional:</strong> <span>{professional?.name}</span>
          </div>
        </div>
      )}
      <div className="flex justify-end my-5">
        <SendButton
          click={submit}
          caption="Remover da Lista de Espera"
          submiting={submiting}
          size="btn-sm"
        />
      </div>
    </div>
  );
};

export default AppWaitingListsRemove;
