/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { validateToken } from '../../services/app.service';
import { getById } from './appointments.service';
import { Appointment } from './appointments.model';
import history from '../../history';
import { useLocation } from 'react-router';
import Loading from '../../components/Loading';
import { useForm } from 'react-hook-form';
import { update } from './appointments.service';
import { SubmitButton } from '../../components/SubmitButton';
import { addTime, getFormatedDate } from '../../utils/datetimeHandler';

const AppAppointmentsCancelations: React.FC = () => {
  const location = useLocation<string>();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Appointment>();

  const init = async () => {
    validateToken();
    const appointment = location.state;
    getById(appointment).then((data) => {
      setData(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    init();
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submit = (dataForm: any) => {
    setLoading(true);
    const cancellationData = {
      ...data,
      cancellation: dataForm.cancellation,
      status: 'CANCELADO',
    };
    update(cancellationData)
      .then(() => {
        setLoading(false);
        history.push('/appointments');
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  return (
    <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <a onClick={() => history.push('/appointments')}>Atendimentos</a>
          </li>
          <li>Cancelamento de Atendimento</li>
        </ul>
      </div>

      {loading && <Loading />}
      {!loading && (
        <form onSubmit={handleSubmit(submit)} className="mb-3 w-full">
          <div className="p-10 card bg-base-200">
            <div className="flex flex-col">
              <div className="flex justify-between bg-gray-200 p-1">
                <strong>Dia:</strong>
                <span>{getFormatedDate(String(data && data.date))}</span>
              </div>
              <div className="flex justify-between bg-gray-100 p-1">
                <strong>Ordem:</strong>
                <span>{data && data.order}</span>
              </div>
              <div className="flex justify-between bg-gray-200 p-1">
                <strong>Previsão:</strong>
                <span>
                  {data &&
                    addTime(
                      data && data.appointmentBegin,
                      data && data.procedure.estimatedTime.toString(),
                      data && data.order - 1
                    )}
                </span>
              </div>
              <div className="flex justify-between bg-gray-100 p-1">
                <strong>Profissional:</strong>
                <span>{data && data.professional.name}</span>
              </div>
              <div className="flex justify-between bg-gray-200 p-1">
                <strong>Procedimento:</strong>
                <span>{data && data.procedure.name}</span>
              </div>
              <div className="flex justify-between bg-gray-100 p-1">
                <strong>Paciente:</strong>
                <span>{data && data.patient?.name}</span>
              </div>
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">Motivo</span>
              </label>
              <textarea
                {...register('cancellation', { required: true })}
                className="textarea h-24"
                placeholder="Motivo do cancelamento"
              ></textarea>
              <span className="text-xs text-red-500">
                {errors.cancellation && 'Campo requerido'}
              </span>
            </div>
            <div className="my-5"></div>
            <SubmitButton caption="Efetuar Cancelamento" size="btn-sm" />
          </div>
        </form>
      )}
    </div>
  );
};

export default AppAppointmentsCancelations;
