/* eslint-disable react/no-children-prop */
import {
  BanIcon,
  CalendarIcon,
  ChatIcon,
  PlusCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFormatedDateTime } from '../../utils/datetimeHandler';
import { WaitingList } from './waitingList.model';
import ModalObs, { Styles } from 'react-modal';
import history from '../../history';
import { list as listProfessionals } from '../professionals/professionals.service';
import { Professional } from '../professionals/professionals.model';

const customStyles: Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#f5f5f5',
  },
};

interface TableWaitingListsProps {
  data: WaitingList[];
  loading: boolean;
  showInsert?: boolean;
}

const TableWaitingLists: React.FC<TableWaitingListsProps> = (
  props: TableWaitingListsProps
) => {
  const [open, setOpen] = useState(false);
  const obs = useRef<string>('');
  const openModalObs = () => setOpen(true);
  const closeModalObs = () => setOpen(false);
  const [data, setData] = useState<WaitingList[]>([]);
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const [professionalFilter, setProfessionalFilter] = useState<string>('');

  const btnRecomendations = (_obs: string) =>
    React.createElement('button', {
      className: 'hover:opacity-70',
      onClick: () => {
        obs.current = _obs;
        openModalObs();
      },
      children: [React.createElement(ChatIcon, { className: 'h-6' })],
      title: 'Observações',
    });

  useEffect(() => {
    listProfessionals().then(setProfessionals);
  }, []);

  useEffect(() => {
    setData(
      props.data.filter((item) => {
        if (professionalFilter) {
          return item.professional?.id === professionalFilter;
        } else {
          return true;
        }
      })
    );
  }, [props.data, professionalFilter]);

  return (
    <>
      <span className="flex space-x-2 my-2">
        <span>Filtro: </span>
        <select
          value={professionalFilter}
          onChange={(e) => setProfessionalFilter(e.target.value)}
          className="select bg-gray-100 select-sm input-sm pr-36"
        >
          <option value="">Todos os Profissionais</option>
          {professionals.map((el) => (
            <option key={el.id} value={el.id}>
              {el.name}
            </option>
          ))}
        </select>
      </span>
      <table className="table table-compact table-zebra w-full z-0">
        <thead>
          <tr className="">
            <th>#</th>
            <th>Inclusão</th>
            <th>Paciente</th>
            <th>Celular</th>
            <th>Profissional</th>
            <th className="w-24">Obs</th>
            <th>
              {props.showInsert ? (
                <Link to={'/waiting-list-add'}>
                  <span className="flex items hover:opacity-50">
                    <PlusCircleIcon className="w-5 mr-2" /> Inserir
                  </span>
                </Link>
              ) : (
                '-'
              )}
            </th>
          </tr>
        </thead>

        <tbody className="">
          {data.map((el: WaitingList, index: number) => (
            <tr key={el.id} className="">
              <td>{index + 1}</td>
              <td>{getFormatedDateTime(el.createdAt?.toString())}</td>{' '}
              <td>{el.patient?.name}</td>
              <td>{el.patient?.cel}</td>
              <td>{el.professional?.name}</td>
              <td className="w-32 line-clamp-2">{el.obs || '-'}</td>{' '}
              <td>
                <div className="flex flex-row justify-end space-x-1">
                  {btnRecomendations(el?.obs || 'Sem observações')}
                  <button
                    className="hover:opacity-70"
                    onClick={() =>
                      history.push('/waiting-list-scheduling', {
                        patient: el.patientId,
                        professional: el.professionalId,
                        waitingListId: el.id,
                      })
                    }
                    title="Agendar"
                  >
                    <CalendarIcon className="h-6" />
                  </button>
                  <button
                    className="hover:opacity-70 text-red-500"
                    onClick={() =>
                      history.push('/waiting-list-remove', {
                        patient: el.patientId,
                        professional: el.professionalId,
                        waitingListId: el.id,
                      })
                    }
                    title="Excluir"
                  >
                    <BanIcon className="h-6" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ModalObs
        isOpen={open}
        onRequestClose={closeModalObs}
        style={customStyles}
        contentLabel="Observações"
        ariaHideApp={false}
        appElement={document.getElementById('root') as HTMLElement}
      >
        <h1 className="flex justify-between items-center w-60 mb-5">
          <span>Observações</span>
          <button onClick={() => closeModalObs()}>
            <XIcon className="w-5 h-5 hover:bg-gray-300" />
          </button>
        </h1>
        <textarea readOnly={true} className="w-full overflow-hidden h-64 p-1">
          {obs.current}
        </textarea>
      </ModalObs>
    </>
  );
};

export default TableWaitingLists;
