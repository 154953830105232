/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  IAppointmentsMakeContext,
  useMakeAppointment,
} from '../../../hooks/appointmentsMaking.hook';
import history from '../../../history';
import { Appointment } from '../appointments.model';
import { findByDays } from '../appointments.service';
import { getFormatedDate } from '../../../utils/datetimeHandler';
import Loading from '../../../components/Loading';

type AnalizeAppointmentMakeLoteProps = {
  setValues: (values: any) => void;
};

export const AnalizeAppointmentMakeLote: React.FC<AnalizeAppointmentMakeLoteProps> =
  ({ setValues }: AnalizeAppointmentMakeLoteProps) => {
    const makeAppointment = useMakeAppointment();
    const [loading, setLoading] = useState(true);

    const [previousAppointments, setPreviousAppointments] = useState<
      Appointment[]
    >([]);

    useEffect(() => {
      const { loteDate: days, professional } = makeAppointment;

      findByDays(days, professional).then((data) => {
        setPreviousAppointments(data);
        const mkAppointment: IAppointmentsMakeContext = {
          ...makeAppointment,
          orderInit: data.length + 1,
        };
        setValues(mkAppointment);
        setLoading(false);
      });
    }, []);

    return (
      <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <a onClick={() => history.push('/appointments')}>Atendimentos</a>
            </li>
            <li>Gerar Atendimentos</li>
          </ul>
        </div>
        <ul className="w-full steps my-6">
          <li className="step step-primary">Dados</li>
          <li className="step step-primary">Análise</li>
          <li className="step step">Finalizar</li>
        </ul>
        {loading && <Loading />}
        {!loading && (
          <div className={``}>
            {previousAppointments.length === 0 && (
              <div className="alert my-4">
                <div className="flex-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#2196f3"
                    className="w-6 h-6 mx-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <label>
                    Não exitem atendimentos agendados neste dia para este
                    procedimento!
                  </label>
                </div>
              </div>
            )}
            {previousAppointments.length > 0 && (
              <>
                <div className="alert alert-warning">
                  <div className="flex-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      className="w-6 h-6 mx-2 stroke-current"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      ></path>
                    </svg>
                    <label>
                      {previousAppointments.length === 1 && (
                        <span>
                          Existe <strong>{previousAppointments.length} </strong>{' '}
                          atendimento para esses dias!
                        </span>
                      )}
                      {previousAppointments.length > 1 && (
                        <span>
                          Existem{' '}
                          <strong>{previousAppointments.length} </strong>{' '}
                          atendimentos para esses dias!
                        </span>
                      )}
                    </label>
                  </div>
                </div>
                <table className="table w-full z-0">
                  <thead>
                    <tr>
                      <th>Ord.</th>
                      <th>Dia</th>
                      <th>STATUS</th>
                      <th>Sala</th>
                      <th>Início</th>
                    </tr>
                  </thead>

                  <tbody>
                    {previousAppointments.map((el) => (
                      <tr key={el.id}>
                        <th>{el.order}</th>
                        <td>{getFormatedDate(String(el.date))}</td>
                        <td>{el.status}</td>
                        <td>{el.room}</td>
                        <td>{el.appointmentBegin}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        )}
        <div className="flex justify-between my-4">
          <button
            type="button"
            className="bt btn-sm btn-active text-white rounded"
            onClick={() => history.push('/appointments-make-lote')}
          >
            Voltar
          </button>
          <button
            type="button"
            className="bt btn-sm btn-primary rounded"
            onClick={() => history.push('/appointments-make-lote/finist')}
          >
            Próximo
          </button>
        </div>
      </div>
    );
  };

/* : '',
room: 0,
qtde: 0,
procedure: '', */
