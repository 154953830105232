/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { classNames } from '../../utils/tailwindcss';

interface Props {
  messages: any[];
  showDate?: boolean;
}

// eslint-disable-next-line react/display-name
const ListRelatMessages: React.FC<Props> = (props: Props) => {
  console.log(props.messages);
  return (
    <div
      style={{ height: '100%' }}
      className="overflow-hidden overflow-y-auto overscroll-contain shadow-sm p-2 m-1 right-1 ring-gray-800 rounded-md"
    >
      {props.messages &&
        props.messages.length > 0 &&
        props.messages.map((message: any, index: number) => {
          if (!message?.type) return <></>;
          switch (message.type) {
            case 'Text': {
              return (
                <div key={index} className={`flex flex-col`}>
                  <div
                    className={classNames(
                      'flex w-full',
                      message?.fromMe ? 'justify-end' : 'justify-start'
                    )}
                  >
                    {props.showDate && (
                      <div className="bg-red-500">{message?.createAt}</div>
                    )}
                    <ReactTextareaAutosize
                      value={
                        message?.text?.message.replace(/\*/g, '') ||
                        message?.message.replace(/\*/g, '')
                      }
                      style={{ resize: 'none' }}
                      readOnly={true}
                      className={classNames(
                        'grow-0 my-2 max-w-xs px-2 py-2 overflow-hidden w-2/3 shadow-lg border-2 border-gray-300 border-solid  rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl',
                        message?.fromMe
                          ? 'text-blue-900 bg-blue-100'
                          : 'text-gray-900 bg-gray-100'
                      )}
                    />
                  </div>
                </div>
              );
            }

            default: {
              return <></>;
            }
          }
        })}
    </div>
  );
};

export default ListRelatMessages;
