/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef, useState } from 'react';
import {
  getFormLocalStorage,
  validateToken,
} from '../../services/app.service';
import history from '../../history';
import Loading from '../../components/Loading';
import { useForm } from 'react-hook-form';
import { list } from '../professionals/professionals.service';
import { Professional } from '../professionals/professionals.model';
import { findStatus, incluirMessageTextWhatsapp } from './appointments.service';
import Table from '../../components/SelectedTable';
import { addTime } from '../../utils/datetimeHandler';
import { convertDate_dd_mm_yyyy_hh_mm_ss } from '../../utils/functions';
import ModalConfigSendMessage from './ModalConfigSendMessage';
import { CogIcon } from '@heroicons/react/outline';
import { Appointment } from './appointments.model';
import ModalInformation from '../../components/ModalInformation';

const APPOINTMENTS_DAY = 'AppointmentsWhatsappConfirmConfirmDay';
const APPOINTMENTS_PROFESSIONAL = 'AppointmentsWhatsappConfirmConfirmProfessional';

const AppAppointmensWhatsappConfirmConfirm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [professionals, setProfessionals] = useState<Professional[]>([]);

  const [data, setData] = useState<any[]>([]);
  const selected = useRef<any[]>([]);

  const [openConfigDialog, setOpenConfigDialog] = useState(false);

  const [modalEnviados, setModalEnviados] = useState(false);
  const enviados = useRef<any[]>([]);
  const nao_enviados = useRef<any[]>([]);

  const init = async () => {
    validateToken();
    list().then((data) => {
      setProfessionals(data);
      if (getFormLocalStorage(APPOINTMENTS_DAY)) {
        setValue('day', getFormLocalStorage(APPOINTMENTS_DAY));
      }
      if (getFormLocalStorage(APPOINTMENTS_PROFESSIONAL)) {
        setValue(
          'professional',
          getFormLocalStorage(APPOINTMENTS_PROFESSIONAL)
        );
      }
    });
  };

  useEffect(() => {
    init();
  }, []);

  const {
    handleSubmit,
    register,
    setValue,
    // formState: { errors },
  } = useForm();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submit = async (dataForm: any) => {
    setLoading(true);
    const date = dataForm.day;
    const professional = dataForm.professional;

    const appoints = await findStatus({
      date,
      professional,
      status: ['AGENDADO'],
    });
    // console.log('appoints', appoints);
    setData(appoints);
    setLoading(false);

    localStorage.setItem(APPOINTMENTS_DAY, date);
    localStorage.setItem(APPOINTMENTS_PROFESSIONAL, professional);
  };

  const sendSelecteds = async () => {
    if (!selected.current?.length) return;
    const data = selected.current;
    const now = new Date().getTime() + 60000;
    let count = 0;
    for await (const el of data) {
      const appointment = el as Appointment
      // console.log("🚀 ~ forawait ~ count:", count)
      // numero aleatorio para enviar em momentos diferentes e não ser bloqueado
      // numero entre 30 e 60 segundos
      const random = now + Math.floor(Math.random() * (60000 - 30000) + 30000) * count;
      const dataHoraEnviar = new Date(random);
      // expira com 2 horas
      const dataHoraExpira = new Date(dataHoraEnviar.getTime() + 7200000);
      const previsao = appointment.procedure
        ? addTime(
            appointment.appointmentBegin,
            appointment.procedure.estimatedTime.toString(),
            appointment.order - 1
          )
        : '-';
        const dia = new Date(String(appointment.date).replace('-', '/')).toLocaleDateString('pt-BR', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
      const message = `Laboratório Citolab.\nOlá ${appointment.patient?.name}, seu atendimento com ${appointment.professional.name} foi agendado para: ${dia} às ${previsao}. Digite "Confirmar" ou "Cancelar".`;
      const number = String(appointment?.patient?.cel).replace(/\D/g, '');

      const it = {
        codigoExterno: appointment.id!.toString().slice(-10),
        tipo: 'Texto',
        classificacao: 'Confirmação de Atendimento',
        destinatario: {
          nome: appointment.patient!.name,
          ddi: 55,
          telefone: number,
        },
        mensagemWhatsapp: message,
        dataHoraEnviar: convertDate_dd_mm_yyyy_hh_mm_ss(dataHoraEnviar),
        dataHoraExpira: convertDate_dd_mm_yyyy_hh_mm_ss(dataHoraExpira),
      }

      await incluirMessageTextWhatsapp(
        it
      ).then(() => {
        enviados.current.push(appointment);

      }).catch((error) => {
        nao_enviados.current.push(appointment);
      });

      count++;
    }

    setModalEnviados(true);
  };

  return (
    <div
      className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20 overflow-hidden`}
    >
      <div className="text-sm breadcrumbs flex justify-between items-center">
        <div>
          <ul>
            <li>
              <a onClick={() => history.push('/appointments')}>Atendimentos</a>
            </li>
            <li>Whatsapp Confirmação de Atendimentos</li>
          </ul>
          <button className="text-2xs text-gray-300 flex gap-2 items-center" onClick={() => setOpenConfigDialog(true)}>
             <CogIcon className='w-4 h-4'  />
             <span>Testar</span>
          </button>
        </div>
        <div className={`flex my-3 p-1`}>
          <form onSubmit={handleSubmit(submit)}>
            <div className="form-control">
              <div className="relative flex">
                <input
                  type="date"
                  placeholder="Data"
                  className="w-full rounded-r-none input input-gray-500 bg-gray-100 input-sm"
                  {...register('day', { required: false })}
                />
                <select
                  {...register('professional', { required: true })}
                  className="select bg-gray-100 select-sm input-sm rounded-l-none pr-36"
                >
                  <option value="">Selecione um Profissional</option>
                  {professionals.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  ))}
                </select>
                <button
                  type="submit"
                  className="absolute top-0 right-0 rounded-l-none btn btn-sm btn-gray-500"
                >
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loading />}
      {!loading && (
        <div className="">
          <Table
            columns={[
              {
                Header: 'Ordem',
                accessor: 'order',
              },
              {
                Header: 'Status',
                accessor: 'status',
              },
              {
                Header: 'Previsão',
                accessor: 'procedure',
                Cell: ({ row }: any) => {
                 const data = row.original;
                 return data.procedure
                    ? addTime(
                        data.appointmentBegin,
                        data.procedure.estimatedTime.toString(),
                        data.order - 1
                      )
                    : '-'
                },
              },
              {
                Header: 'Procedimento',
                accessor: 'procedure.name',
              },
              {
                Header: 'Paciente',
                accessor: 'patient.name',
              },
              {
                Header: 'Sala',
                accessor: 'room',
              },
            ]}
            data={data}
            callback={(data: any) => {
              selected.current = data.selected;
            }}
          />
          {data.length > 0 ?
              (<button
                type="button"
                className="rounded-xl btn btn-sm btn-gray-500"
                // onClick={sendSelecteds}
                onClick={sendSelecteds}
              >
                [Whatsapp] Enviar Selecionados
              </button>)
            : null
          }
        </div>
      )}
      <div className="h-20"></div>
      <ModalConfigSendMessage
        isOpen={openConfigDialog}
        setIsOpen={setOpenConfigDialog}
      />

      <ModalInformation
        isOpen={modalEnviados}
        setIsOpen={setModalEnviados}
        title="Envio de Mensagens"
        message={`${enviados.current.length} - Mensagens enviadas com sucesso!`}
        onClose={() => {
          enviados.current = [];
          nao_enviados.current = [];
          selected.current = [];
          init();
        }}
      />

    </div>
  );
};

export default AppAppointmensWhatsappConfirmConfirm;
