/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
// import { validateToken } from '../../services/app.service';
import { relatAppoinmentsProfessinalsCalcGroup } from '../appointments/appointments.service';
import { useForm } from 'react-hook-form';
import Loading from '../../components/Loading';
import {
  getFormatedDate,
  // getFormatedDateWeek,
} from '../../utils/datetimeHandler';
import { arrayGroupBy } from '../../utils/functions';

import { groupBy, mapValues } from 'lodash';

import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { getTypePgto } from '../../utils/app.utils';
// @ts-ignore: Unreachable code error
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// import ReactExport from 'react-export-excel';
// import { list } from '../specialties/specialties.service';
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const AppRelatAppointmensProfessionalsCalcGroup: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  // const [dataExcel, setDataExcel] = useState<any[]>([]);
  // const [data2Excel, setData2Excel] = useState<any[]>([]);
  const { handleSubmit, register, getValues } = useForm();
  const [mode, setMode] = useState<'analytic' | 'synthetic'>('synthetic');
  const [sumCommission, setSumCommission] = useState(0);
  const [sumProcedure, setSumProcedure] = useState(0);

  // const init = async () => {
  //   validateToken();
  //   const specialties = await list();
  //   setData2Excel(specialties);
  // };

  // useEffect(() => {
  //   init();
  // }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchAppointmentsProfessionalsGroup = (data: any) => {
    setLoading(true);

    relatAppoinmentsProfessinalsCalcGroup(data).then((ret) => {
      // setDataExcel(ret);

      // console.log('days', days);

      const grouped = mapValues(groupBy(ret, 'professional_name'));
      // console.log(
      //   '🚀 ~ file: AppRelatAppointmensProfessionalsCalcGroup.tsx:60 ~ relatAppoinmentsProfessinalsCalcGroup ~ grouped',
      //   grouped
      // );

      // const arrayGroup = arrayGroupBy(ret, 'professional_name');
      const entries = Object.entries(grouped).map(([key, value]) => ({
        professional: key,
        appointments: value.map((app: any) => ({
          ...app,
          typePgto: getTypePgto(app.typePgto),
        })),
        sum: {
          procedure: value.reduce((acc: number, cur: any) => {
            acc += cur.procedureToCovenants_value;
            return acc;
          }, 0),
          commission: value.reduce((acc: number, cur: any) => {
            acc += cur.procedureToCovenants_commission;
            return acc;
          }, 0),
        },
      }));

      // console.log('entries', entries);
      setData(entries);
      setLoading(false);
    });
  };

  useEffect(() => {
    const reduce = data.reduce(
      (acc, cur) => {
        if (cur.sum) {
          acc.procedure += cur.sum.procedure;
          acc.commission += cur.sum.commission;
        }
        return acc;
      },
      {
        procedure: 0,
        commission: 0,
      }
    );

    setSumProcedure(reduce.procedure);
    setSumCommission(reduce.commission);
  }, [data]);

  const generatePdf = () => {
    // @ts-ignore: Unreachable code error
    pdfMake.fonts = {
      Roboto: {
        normal:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics:
          'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
    };

    const tableBodyAnalytic = (appointments: any[]): any[] => {
      let sumProcedure = 0;
      let sumCommission = 0;
      const reduce = appointments.reduce(
        (acc, cur) => {
          sumProcedure += cur.procedureToCovenants_value;
          sumCommission += cur.procedureToCovenants_commission;
          acc.push([
            cur.procedure_name || '', //Procedimento
            Number(cur.procedureToCovenants_value)
              .toFixed(2)
              .replace('.', ',') || 0, //valor
            Number(cur.procedureToCovenants_commission)
              .toFixed(2)
              .replace('.', ',') || 0, //comissao
            cur.typePgto || '', // tipo de pagamento
            cur.status || '', // status
          ]);

          return acc;
        },
        [['Procedimento', 'Valor', 'Comissão', 'Pagamento', 'Status']]
      );

      reduce.push([
        'Total',
        Number(sumProcedure).toFixed(2).replace('.', ',') || 0, //valor
        Number(sumCommission).toFixed(2).replace('.', ',') || 0, //comissao
        '',
        '',
      ]);

      return reduce;
    };

    const tableBodySynthetic = (dt: any[]): any[] => {
      let sumProcedure = 0;
      let sumCommission = 0;
      const reduce = dt.reduce(
        (acc, cur) => {
          sumProcedure += cur.sum.procedure;
          sumCommission += cur.sum.commission;
          acc.push([
            cur.professional || '', //profissional
            Number(cur.sum.procedure || 0)
              .toFixed(2)
              .replace('.', ','), //valor
            Number(cur.sum.commission || 0)
              .toFixed(2)
              .replace('.', ','), //comissao
          ]);

          return acc;
        },
        [['Profissional', 'Procedimentos', 'Comissão']]
      );

      reduce.push([
        'Total',
        Number(sumProcedure).toFixed(2).replace('.', ',') || 0, //valor
        Number(sumCommission).toFixed(2).replace('.', ',') || 0, //comissao
      ]);

      return reduce;
    };

    const dd: TDocumentDefinitions = {
      defaultStyle: {
        font: 'Roboto',
      },

      pageSize: 'A4',

      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            text: 'Relatório de Atendimentos Diário Agrupado por Profissional',
            alignment: currentPage % 2 ? 'left' : 'right',
            style: 'header',
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 30,
                y1: 0,
                x2: pageSize.width - 30,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
        ];
      },
      content:
        mode === 'analytic'
          ? data.map((dt) => ({
              stack: [
                {
                  text: `${String(dt.professional)}`,
                  style: {
                    fontSize: 14,
                    bold: true,
                  },
                },
                {
                  table: {
                    widths: ['*', 50, 50, 100, 'auto'],
                    body: tableBodyAnalytic(dt.appointments),
                  },
                  style: {
                    fontSize: 9,
                  },
                },
                `\n`,
              ],
            }))
          : {
              stack: [
                {
                  table: {
                    widths: ['*', 50, 50],
                    body: tableBodySynthetic(data),
                  },
                  style: {
                    fontSize: 9,
                  },
                },
              ],
            },

      footer: function (currentPage, pageCount, pageSize) {
        return [
          {
            canvas: [
              {
                type: 'line',
                x1: 30,
                y1: 0,
                x2: pageSize.width - 30,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
          {
            stack: [
              {
                text: 'Pag. '.concat(
                  currentPage.toString(),
                  ' de ',
                  pageCount.toString(),
                  ` - ${new Date().getDate()}/${
                    new Date().getMonth() + 1
                  }/${new Date().getFullYear()}`
                ),
                margin: [30, 0, 30, 0],
                fontSize: 10,
              },
              {
                text: `Data da Consulta: ${getFormatedDate(getValues('date'))}`,
                margin: [30, 0, 30, 0],
                fontSize: 10,
              },
            ],
          },
        ];
      },

      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [30, 30, 30, 0],
        },
      },
    };
    const doc = pdfMake.createPdf(dd);

    // doc.download();
    doc.open();
    // doc.getBase64((data) => {
    //   self.location.href = 'data:application/pdf;base64,' + data;
    // });
  };

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div style={{ width: '90%', position: 'absolute' }}>
        <div className="text-sm breadcrumbs relative">
          <ul>
            <li>Relatório de Atendimentos de Profissional Diário</li>
          </ul>
        </div>
        <div className="my-5 w-full relative z-10 flex justify-between">
          <form onSubmit={handleSubmit(searchAppointmentsProfessionalsGroup)}>
            <div className=" w-full form-control items-center flex flex-row justify-start">
              <div className="mr-3">
                <div className="flex justify-between">
                  <label className="w-20 bg-gray-300 text-white py-1 pl-3">
                    Dia
                  </label>
                  <input
                    type="date"
                    placeholder="Data"
                    className="w-42 rounded-none input input-gray-500 bg-gray-100 input-sm"
                    {...register('date', { required: true })}
                  />
                </div>
              </div>

              <button type="submit" className="my-2 btn btn-sm btn-gray-500">
                Buscar
              </button>
            </div>
          </form>
          <div className="flex space-x-2">
            <label className="label cursor-pointer space-x-2">
              <input
                type="radio"
                value={'synthetic'}
                className="radio radio-error"
                onChange={() => setMode('synthetic')}
                checked={mode === 'synthetic'}
              />
              <span className="label-text">Sintético</span>
            </label>
            <label className="label cursor-pointer space-x-2">
              <input
                type="radio"
                value={'analytic'}
                className="radio radio-error"
                onChange={() => setMode('analytic')}
                checked={mode === 'analytic'}
              />
              <span className="label-text">Analítico</span>
            </label>
          </div>
        </div>
        <div className="divider opacity-25"></div>
        {loading && (
          <div className="pt-20 text-center">
            <Loading />
          </div>
        )}

        {data.length > 0 && (
          <div className="flex space-x-3 justify-end">
            <button
              type="button"
              className="my-2 btn btn-sm bg-blue-300 border-blue-300"
              onClick={generatePdf}
            >
              Imprimir
            </button>
            {/* <ExcelFile
              element={
                <button
                  type="button"
                  className="my-2 btn btn-sm bg-blue-300 border-blue-300"
                >
                  Excel Download
                </button>
              }
            >
              <ExcelSheet
                data={dataExcel.reduce((acc: any[], cur: any) => {
                  if (cur.professional_isActive) {
                    acc.push(cur);
                  }
                  return acc;
                }, [])}
                name="Profissionais"
              >
                <ExcelColumn
                  label="DIA"
                  value={(col: any) => getFormatedDate(String(col.date))}
                />
                <ExcelColumn label="PROFISSIONAL" value="professional_name" />
                <ExcelColumn label="ESPECIALIDADE" value="specialty_name" />
              </ExcelSheet>
              <ExcelSheet data={data2Excel} name="ESPECIALIDADES">
                <ExcelColumn label="NOME" value={'name'} />
                <ExcelColumn label="DEFINIÇÃO" value="definition" />
              </ExcelSheet>
            </ExcelFile> */}
          </div>
        )}

        {!loading &&
          mode == 'analytic' &&
          data.map((dt, dtIdx) => (
            <div key={dtIdx} className="my-4">
              <h1 className="py-3 text-center bg-gray-600 text-white">
                Profissional: {dt.professional}
              </h1>
              <table className="table-compact w-full z-0">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="text-left">Procedimento</th>
                    <th className="text-right">Valor</th>
                    <th className="text-right">Comissão</th>
                    <th className="text-left">Tipo de Pagamento</th>
                    <th className="text-center">Status</th>
                  </tr>
                </thead>

                <tbody>
                  {dt.appointments?.map((appointment: any, gIdx: number) => (
                    <tr className="border-dotted border-b-2" key={gIdx}>
                      <td className="text-left">
                        {appointment.procedure_name}
                      </td>
                      <td className="text-right">
                        {Number(appointment.procedureToCovenants_value)
                          .toFixed(2)
                          .replace('.', ',')}
                      </td>
                      <td className="text-right">
                        {Number(appointment.procedureToCovenants_commission)
                          .toFixed(2)
                          .replace('.', ',')}
                      </td>
                      <td className="text-left">{appointment.typePgto}</td>
                      <td className="text-center">{appointment.status}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="font-bold bg-gray-100">
                  <td></td>
                  <td className="text-right">
                    {Number(dt?.sum?.procedure).toFixed(2)}
                  </td>
                  <td className="text-right">
                    {Number(dt?.sum?.commission).toFixed(2)}
                  </td>
                  <td></td>
                  <td></td>
                </tfoot>
              </table>
            </div>
          ))}

        {!loading && mode == 'synthetic' && (
          <table className="table-compact w-full z-0">
            <thead>
              <tr className="bg-gray-100">
                <th className="text-left">Profisional</th>
                <th className="text-right">Procedimentos</th>
                <th className="text-right">Comissão</th>
              </tr>
            </thead>
            <tbody>
              {data.map((dt, dtIdx) => (
                <tr className="border-dotted border-b-2" key={dtIdx}>
                  <td className="text-left">{dt.professional}</td>
                  <td className="text-right">
                    {Number(dt?.sum?.procedure).toFixed(2).replace('.', ',')}
                  </td>
                  <td className="text-right">
                    {Number(dt?.sum?.commission).toFixed(2).replace('.', ',')}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot className="font-bold bg-gray-100">
              <td></td>
              <td className="text-right">
                {Number(sumProcedure).toFixed(2).replace('.', ',')}
              </td>
              <td className="text-right">
                {Number(sumCommission).toFixed(2).replace('.', ',')}
              </td>
            </tfoot>
          </table>
        )}
      </div>
    </div>
  );
};

export default AppRelatAppointmensProfessionalsCalcGroup;
