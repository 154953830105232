/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '../../axios';
import { PresetChats } from './presetChats.model';

export async function list(): Promise<PresetChats[]> {
  const url = '/preset_chats';
  try {
    const ret = await api.get<PresetChats[]>(url);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

export async function getById(id: string): Promise<PresetChats> {
  const url = '/preset_chats/' + id;
  try {
    const ret = await api.get<PresetChats>(url);
    return ret.data;
  } catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function save(data: PresetChats): Promise<any> {
  const url = '/preset_chats';
  if (data.id) {
    return api
      .put<PresetChats>(url, data)
      .then((value) => value)
      .catch((e: Error) => e.message);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...post } = data;
    return api
      .post<PresetChats>(url, post)
      .then((value) => value)
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          return error.response;
        } else if (error.request) {
          return error.request;
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      });
  }
}
const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return <File>theBlob;
};

export function upload(id: string, image: Blob, onUploadProgress: (progressEvent: any) => void): Promise<any> {
  const url = '/preset_chats/upload/' + id;
  const formData = new FormData();
  const imgUp = blobToFile(image, 'upload.jpg');
  formData.append('image', imgUp);
  return api.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: onUploadProgress
  });
}

export async function del(id: string): Promise<any>{
  const url = '/preset_chats/' + id;
  try {
    const ret = await api.delete(url);
    return ret;
  }catch (e) {
    console.log(e);
    throw new Error('Não foi possivel obter dados');
  }
}
