/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import {
  // getAuthUser,
  searchProtocol,
  validateToken,
} from '../../services/app.service';
import { useForm } from 'react-hook-form';
import { nivelSatisfaction, phoneMask } from '../../utils/app.utils';
import Loading from '../../components/Loading';
import { secondsToMinutes } from '../../utils/datetimeHandler';

import * as pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment-timezone';
import { arrayGroupBy } from '../../utils/functions';

// @ts-ignore: Unreachable code error
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AppRelatProtocolsUser: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [period, setPeriod] = useState(0);
  // const [summary, setSummary] = useState<Record<string, number>>();
  // const [showSummary, setShowSummary] = useState(0);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  // const user = getAuthUser();
  const init = async () => {
    validateToken();
  };

  useEffect(() => {
    init();
  }, []);

  const submit = async (form: any) => {
    setLoading(true);
    const args = {
      interval: `${form.dtIni},${form.dtEnd}`,
    };
    const data = await searchProtocol(args);
    const filtered = data.filter((p) => p.finishedAt);

    const map = filtered.map((f) => {
      const started = Number(f.startedAt);
      const finished = Number(f.finishedAt);
      const serviceTime = finished - started;
      return {
        id: f.user.id,
        name: f.user.name,
        started,
        finished,
        serviceTime,
      };
    });

    const countPeriod = map.length;
    const meanPeriod = map.reduce((acc, cur) => {
      acc = acc + cur.serviceTime;
      return acc;
    }, 0);

    setPeriod(Math.ceil(meanPeriod / countPeriod));

    const group = arrayGroupBy(map, 'name');
    const entries = Object.entries(group);

    const calc = entries.map(([key, values]) => {
      const count: number = values.length;
      const sumServiceTime = values.reduce((acc: any, cur: any) => {
        acc = acc + Number(cur.serviceTime);
        return acc;
      }, 0);

      const meanServiceTime = Math.ceil(sumServiceTime / count);
      return {
        name: key,
        qtde: count,
        meanServiceTime,
      };
    });

    setData(calc);
    setLoading(false);
  };

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div style={{ width: '90%', position: 'absolute' }}>
        <div className="text-sm breadcrumbs relative">
          <ul>
            <li>Análise de Atendimentos por Whatsapp</li>
          </ul>
        </div>
        <div className="my-5 w-full relative z-10">
          <form onSubmit={handleSubmit(submit)}>
            <div className="w-full form-control flex flex-row space-x-1 justify-start">
              <div className="flex flex-col space-y-1">
                <div className="flex justify-between">
                  <label className="w-20 bg-gray-300 text-white py-1 pl-3">
                    Início
                  </label>
                  <input
                    type="date"
                    placeholder="Data"
                    className="w-42 rounded-none input input-gray-500 bg-gray-100 input-sm"
                    {...register('dtIni', { required: true })}
                  />
                </div>
                {errors.dtIni?.type === 'required' && (
                  <div className="text-red-500 text-2xs">
                    Campos Obrigatórios
                  </div>
                )}
                <div className="flex justify-between">
                  <label className="w-20 bg-gray-300 text-white py-1 pl-3">
                    Final
                  </label>
                  <input
                    type="date"
                    placeholder="Data"
                    className="w-42 rounded-none input input-gray-500 bg-gray-100 input-sm"
                    {...register('dtEnd', { required: true })}
                  />
                </div>
                {errors.dtEnd?.type === 'required' && (
                  <div className="text-red-500 text-2xs">
                    Campos Obrigatórios
                  </div>
                )}
              </div>
              <button type="submit" className="my-2 btn btn-sm btn-gray-500">
                Buscar
              </button>
            </div>
          </form>
        </div>
        <div className="divider opacity-25"></div>
        <div className="flex space-x-4 my-1 justify-center">
          <div className="flex space-x-3">
            <strong>Média de Atendimento Geral:</strong>
            <span>{moment(period).minute()} min</span>
          </div>
        </div>
        {/* <Table data={data} loading={loading} /> */}
      </div>
    </div>
  );
};

const Table = ({ data, loading }: { data: any[]; loading: boolean }) => {
  return (
    <table className="table-compact w-full z-0">
      <thead>
        <tr className="bg-gray-100">
          <th className="text-left">Atendente</th>
          {/* <th className="text-left">Quantidade</th> */}
          <th className="text-left">Média de Tempo de Atendimento</th>
        </tr>
      </thead>

      {!loading && (
        <tbody>
          {data.map((el, index) => (
            <tr key={index} className="cursor-pointer hover:bg-gray-100">
              <th className="text-left">{el.name}</th>

              {/* <td className="text-left">{el.qtde}</td> */}
              <td className="text-left">
                {moment(el.meanServiceTime).minute()} min
              </td>
            </tr>
          ))}
        </tbody>
      )}
    </table>
  );
};

export default AppRelatProtocolsUser;
