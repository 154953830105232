/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import history from '../../../history';
import { validateToken } from '../../../services/app.service';
import { useWaitingListSchenduling } from '../../../hooks/waitingListSchenduling.hook';
import { useLocation } from 'react-router';
import { getById as getByIdProfessional } from '../../professionals/professionals.service';
import { getById as getByIdPatient } from '../../patients/patients.service';
import Loading from '../../../components/Loading';

type InitialWaitingListSchendulingProps = {
  setValues: (values: any) => void;
};

type LocationStateProps = {
  patient: string;
  professional: string;
  waitingListId: string;
};

export const InitialWaitingListSchenduling: React.FC<InitialWaitingListSchendulingProps> =
  ({ setValues }: InitialWaitingListSchendulingProps) => {
    const hookData = useWaitingListSchenduling();
    const [loading, setLoading] = useState(false);
    const location = useLocation<LocationStateProps>();

    const init = async () => {
      validateToken();
      setLoading(true);
      const data = location.state;
      console.log(data);
      const professional = await getByIdProfessional(data.professional);
      const patient = await getByIdPatient(data.patient);
      const waitingListId = data.waitingListId;
      setValues({
        professional,
        patient,
        waitingListId,
      });
      setLoading(false);
    };

    useEffect(() => {
      if (!hookData.professional && !hookData.patient) init();
    }, []);

    return (
      <div className={`max-w-xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <a onClick={() => history.push('/waiting-list')}>
                Lista de Espera
              </a>
            </li>
            <li>Agendamento de Atendimento</li>
          </ul>
        </div>
        <ul className="w-full steps my-6">
          <li className="step step-primary">Dados</li>
          <li className="step step">Análise</li>
          <li className="step step">Finalizar</li>
        </ul>
        {loading ? (
          <Loading />
        ) : (
          <div className="flex flex-col">
            <div className="bg-gray-100 p-2 flex justify-between">
              <strong>Paciente:</strong> <span>{hookData.patient?.name}</span>
            </div>
            <div className="bg-gray-200 p-2 flex justify-between">
              <strong>Celular:</strong> <span>{hookData.patient?.cel}</span>
            </div>
            <div className="bg-gray-100 p-2 flex justify-between">
              <strong>Profissional:</strong>{' '}
              <span>{hookData.professional?.name}</span>
            </div>
          </div>
        )}
        <div className="flex justify-end my-3">
          <button
            type="button"
            className="bt btn-sm btn-primary rounded"
            onClick={() => history.push('/waiting-list-scheduling/analize')}
          >
            Próximo
          </button>
        </div>
      </div>
    );
  };
