/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import {
  findAvaliacaoPresencial,
  // getAuthUser,
  searchProtocol,
  validateToken,
} from '../../services/app.service';
import { useForm } from 'react-hook-form';

import Loading from '../../components/Loading';

import * as pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment-timezone';
import { set } from 'lodash';
import { IAvalicaoPresencial } from '../../app.model';

// @ts-ignore: Unreachable code error
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AppRelatAvalicaoPresencial: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  // const user = getAuthUser();
  const init = async () => {
    validateToken();
  };

  useEffect(() => {
    init();
  }, []);

  const [media, setMedia] = useState(0);
  const [data, setData] = useState<IAvalicaoPresencial[]>([]);

  const submit = async (form: any) => {
    setLoading(true);
    const args = {
      dataIni: form.dtIni,
      dataFim: form.dtEnd,
    };
    const data = await findAvaliacaoPresencial(args.dataIni, args.dataFim);
    setData(data);
    // media geral da avaliação
    const mean = data.reduce((acc: number, curr: IAvalicaoPresencial) => {
      return acc + curr.rating;
    }, 0);

    const meanServiceTime = mean / data.length;

    setMedia(Math.round(meanServiceTime));
    setLoading(false);
  };

  function getEmoji() {
    if (media === 1) {
      return '😡';
    }
    if (media === 2) {
      return '😠';
    }
    if (media === 3) {
      return '😐';
    }
    if (media === 4) {
      return '🤨';
    }
    if (media === 5) {
      return '😁';
    }
    // retorne um emoji de duvida
    return '🤔';
  }

  const getDescricao = () => {
    if (media === 1) {
      return 'Péssimo';
    }
    if (media === 2) {
      return 'Ruim';
    }
    if (media === 3) {
      return 'Regular';
    }
    if (media === 4) {
      return 'Bom';
    }
    if (media === 5) {
      return 'Ótimo';
    }
    // retorne um emoji de duvida
    return 'Sem avaliação';
  };

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-20`}>
      <div style={{ width: '90%', position: 'absolute' }}>
        <div className="text-sm breadcrumbs space-y-3">
          <ul>
            <li>Relatório de Avaliação Presencial</li>
          </ul>
        </div>
        <div className="flex justify-between items-center mb-2">
          <div className="w-full my-1">
            <form onSubmit={handleSubmit(submit)}>
              <div className="w-full form-control flex flex-row space-x-1 justify-start items-center">
                <div className="flex flex-col space-y-1">
                  <div className="flex justify-between">
                    <label className="w-20 bg-gray-300 text-white py-1 pl-3">
                      Início
                    </label>
                    <input
                      type="date"
                      placeholder="Data"
                      className="w-42 rounded-none input input-gray-500 bg-gray-100 input-sm"
                      {...register('dtIni', { required: true })}
                    />
                  </div>
                  {errors.dtIni?.type === 'required' && (
                    <div className="text-red-500 text-2xs">
                      Campos Obrigatórios
                    </div>
                  )}
                  <div className="flex justify-between">
                    <label className="w-20 bg-gray-300 text-white py-1 pl-3">
                      Final
                    </label>
                    <input
                      type="date"
                      placeholder="Data"
                      className="w-42 rounded-none input input-gray-500 bg-gray-100 input-sm"
                      {...register('dtEnd', { required: true })}
                    />
                  </div>
                  {errors.dtEnd?.type === 'required' && (
                    <div className="text-red-500 text-2xs">
                      Campos Obrigatórios
                    </div>
                  )}
                </div>
                <button type="submit" className="my-2 btn btn-sm btn-gray-500">
                  Buscar
                </button>
              </div>
            </form>
          </div>
          <div className="space-y-1">
            <div>Média/Período</div>
            <div className="flex justify-between items-center gap-3 bg-gray-200 rounded-md px-1 py-2">
              <div className="text-4xl font-bold">{media ?? 0}</div>
              <div className="text-4xl font-bold">{getEmoji()}</div>
              <div className="text-xl font-bold">{getDescricao()}</div>
            </div>
          </div>
        </div>
        {/* <div className="divider opacity-25"></div> */}
        <Table data={data} loading={loading} />
      </div>
    </div>
  );
};

const Table = ({
  data,
  loading,
}: {
  data: IAvalicaoPresencial[];
  loading: boolean;
}) => {
  return (
    <table className="table-compact w-full z-0">
      <thead>
        <tr className="bg-gray-100">
          <th className="text-left">Data</th>
          <th className="text-center">Nota</th>
          <th className="text-center">Resolvido</th>
          <th className="text-left">Motivo</th>
        </tr>
      </thead>

      {!loading && (
        <tbody>
          {data.map((el, index) => (
            <tr key={index} className="cursor-pointer hover:bg-gray-100">
              <th className="text-left">
                {new Date(el.createdAt).toLocaleDateString('pt-BR')}
              </th>
              <td className="text-center">{el.rating}</td>
              <td className="text-center">
                {el.hasBeenResolved ? 'Sim' : 'Não'}
              </td>
              <td className="text-left">{el.obs || 'Não respondeu!'}</td>
            </tr>
          ))}
        </tbody>
      )}
    </table>
  );
};

export default AppRelatAvalicaoPresencial;
